import React, {useState} from "react";
import {Input} from 'antd';
import './searchInput.css'
import {useNavigate} from "react-router-dom";
import {SEARCH_ROUTE} from "../../routes/route-path";

export const SearchInput: React.FC = () => {

    const navigate = useNavigate();
    const [value, setValue] = useState<string>();

    const search = () => {
        window.location.replace(SEARCH_ROUTE + "?text=" + value);
    }

    return (
        <div className="custom-new-search-input">
            <Input onPressEnter={search} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue(e.target.value);
            }} bordered={false} size="large" placeholder="جستجو در دوره های آموزشی ..."
                   prefix={
                       <svg style={{cursor:"pointer"}} onClick={search} width="16" height="16" fill="none" viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                           <path
                               d="M10 2.75a7.25 7.25 0 0 1 5.63 11.819l4.9 4.9a.75.75 0 0 1-.976 1.134l-.084-.073-4.901-4.9A7.25 7.25 0 1 1 10 2.75Zm0 1.5a5.75 5.75 0 1 0 0 11.5 5.75 5.75 0 0 0 0-11.5Z"
                               fill="#262626"/>
                       </svg>
                   }/>
        </div>
    );

}