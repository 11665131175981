import React from "react";
import {Space} from 'antd';
import {CATEGORY_COURSES_ROUTE} from "../../../routes/route-path";
import {Category} from "../../../services/CategoryService";

interface Props {
    categories : Category[]
}

const color = "#262626";

export const HorizontalCategoryNavigation = ({categories} : Props) => {

    return(
        <Space size={80} style={{height:"56px"}}>
            {categories && categories.map(category => {
                return (
                    <a href={CATEGORY_COURSES_ROUTE.replace(":id", category.id + "")} style={{fontSize:"16px", lineHeight:"24px", color:color}}>{category.title}</a>
                );
            })}
        </Space>
    );

}