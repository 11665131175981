import React, {useState} from "react";
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import {THIRD_COLOR} from "../../constans/colors";
import {
    ABOUT_US_ROUTE,
    CONTACT_US_ROUTE,
    COOPERATION_FORM_ROUTE,
    FAQ_ROUTE,
    TEACHERS_ROUTE
} from "../../routes/route-path";

const items: MenuProps['items'] = [
    {
        label: (
            <a href={ABOUT_US_ROUTE}>درباره ما</a>
        ),
        key: '1',
    },
    {
        label: (
            <a href={CONTACT_US_ROUTE}>تماس با ما</a>
        ),
        key: '2',
    },
    {
        label: (
            <a href={COOPERATION_FORM_ROUTE}>تدریس در دانشگاهینو</a>
        ),
        key: '3',
    },
    {
        label: (
            <a href={TEACHERS_ROUTE}>تیم آموزشی دانشگاهینو</a>
        ),
        key: '4',
    },
    {
        label: (
            <a href={FAQ_ROUTE}>سوالات متداول</a>
        ),
        key: '5',
    }
]

export const MostUsedLinks: React.FC =() => {

    const [current, setCurrent] = useState('mail');

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
    };

    return(
        <Menu style={{borderInlineEnd:"none", fontSize:'14px', borderBottom:'none', color:THIRD_COLOR}} onClick={onClick} selectedKeys={[current]} mode="vertical" items={items} />
    );

}