import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {FileType} from "../constans/enums";
import {User} from "./UserService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface SavedFile {
    id ?: number;
    createdDate: Date;
    fileId: string;
    fileName: string;
    type: FileType;
    user: User;
    description: string;
}

export interface ChunkUpload {
    file : any;
    chunkIndex : number;
    totalChunks : number;
    fileName : string;
    fileId : string;
}

export interface ChunkUploadResponse {
    filePath : string;
    completed : boolean;
}


class FileService {

    private static instance: FileService;

    private constructor() {
    }

    public static getInstance(): FileService {
        if (!FileService.instance) {
            FileService.instance = new FileService();
        }
        return FileService.instance;
    }

    async getSavedFilesForAdmin(params: any): Promise<Page<SavedFile>> {
        return await genericApiCall<Page<SavedFile>>(async () => {
            const response = await axios.get<Page<SavedFile>>(baseUrl + "/api/v1/files", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getSavedTeacherFilesPageBySelf(params: any): Promise<Page<SavedFile>> {
        return await genericApiCall<Page<SavedFile>>(async () => {
            const response = await axios.get<Page<SavedFile>>(baseUrl + "/api/v1/files/teacher-files/self", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async delete(url : string): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/files" + url, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async uploadTeacherFiles(input:any): Promise<string> {
        return await genericApiCall<string>(async () => {
            const response = await axios.post<string>(baseUrl + "/api/v1/files/upload/teacher-files", input,  {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async chunkUpload(input: ChunkUpload): Promise<ChunkUploadResponse> {
        return await genericApiCall<ChunkUploadResponse>(async () => {
            const response = await axios.post<ChunkUploadResponse>(baseUrl + "/api/v1/files/upload-chunk", input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default FileService.getInstance();