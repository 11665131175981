import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Modal, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import {numberWithCommas, Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import TeacherPaymentService, {TeacherPayment} from "../../../../services/TeacherPaymentService";
import {TeacherPaymentUpdate} from "./TeacherPaymentUpdate";
import {ExclamationCircleOutlined} from "@ant-design/icons";

export const TeacherPaymentContainer: React.FC = () => {

    const [data, setData] = useState<Page<TeacherPayment>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [selectedTeacherPayment, setSelectedTeacherPayment] = useState<TeacherPayment>();
    const [deleteModal, deleteModalContextHolder] = Modal.useModal();


    useEffect(() => {
        loadData();
    }, [params, showUpdateModal]);

    const loadData = () => {
        setLoading(true);
        TeacherPaymentService.getTeacherPayment(params).then(page => {
            page.content = page.content.map(value => {
                value.key = value.id;
                return value;
            })
            setData(page);
        }).finally(() => setLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<TeacherPayment> = [
        {
            title: 'تاریخ درخواست',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{createdDate && toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'نام کاربر',
            dataIndex: 'userFullName',
            width: 150,
            render: (_, {user}) => (
                <>{user && user.firstName + " " + user.lastName}</>
            ),
        },
        {
            title: 'موبایل',
            dataIndex: ['user', 'mobile'],
            width: 150,
        },
        {
            title: 'مبلغ',
            dataIndex: 'amount',
            width: 200,
            render: (_, {amount}) => (
                <>{numberWithCommas(amount)}</>
            ),
        },
        {
            title: 'وضعیت پرداخت',
            dataIndex: 'paymentStatus',
            width: 150,
            render: (_, record) => (
                <a onClick={event => {
                    setSelectedTeacherPayment(record);
                    setShowUpdateModal(true);
                }}>{record.paymentStatus ? <span style={{color: "green"}}>پرداخت شد</span> :
                    <span style={{color: "red"}}>پرداخت نشده</span>}</a>
            ),
        },
        {
            title: 'شماره کارت',
            dataIndex: 'cardNumber',
            width: 200,
        },
        {
            title: 'کد رهگیری',
            dataIndex: 'trackId',
            width: 300,
        },
        {
            width: 70,
            render: (_, {id}) => (
                <>
                    <a style={{color: 'red'}} onClick={() => {
                        if (id) {
                            deleteModal.confirm({
                                title: 'آیا می خواهید این درخواست را حذف کنید؟',
                                icon: <ExclamationCircleOutlined/>,
                                okText: 'بله',
                                cancelText: 'انصراف',
                                okButtonProps: {danger: true},
                                onOk: () => {
                                    TeacherPaymentService.delete(id).then(value => {
                                        loadData();
                                    })
                                }
                            })
                        }
                    }}>حذف</a>
                </>
            )
        },
    ];

    return (
        <>
            <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
                <span style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>درخواست‌های دریافت پول</span>
                <Divider style={{margin: '0', marginTop: '15px'}}/>
                <Table className="admin-course-table" scroll={{x: 1400}} loading={loading} pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: params.pageSize,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"
                       expandable={{
                           expandedRowRender: (record) => <div>
                               {record.watchReports && record.watchReports.map(item => <p>
                                   <Tag color='geekblue'>
                                       {item.yearMonth}
                                   </Tag>
                                   <Tag color='geekblue'>
                                       {item.courseTitle}
                                   </Tag>
                                   <Tag color='green'>
                                       {numberWithCommas(item.teacherStock) + " تومان"}
                                   </Tag>
                               </p>)}
                           </div>
                       }}
                />
            </Wrapper1>
            {
                (() => {
                    return (showUpdateModal ? <TeacherPaymentUpdate show={showUpdateModal} setShow={setShowUpdateModal}
                                                                    data={selectedTeacherPayment}/> : null)
                })()
            }
            {deleteModalContextHolder}
        </>


    );
}