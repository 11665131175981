import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {numberWithCommas, Page} from "../../../../services/UtilFunctions";
import WatchReportService, {WatchReport} from "../../../../services/WatchReportService";
import {WatchChart} from "../../../common/WatchChart";

export const WatchReportContainer: React.FC = () => {

    const [data, setData] = useState<Page<WatchReport>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedYearMonth, setSelectedYearMonth] = useState<string | null>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sort:"DESC"
    });

    useEffect(() => {
        loadData();
    }, [params]);

    const loadData = () => {
        setLoading(true);
        WatchReportService.getTeacherWatchReport(params).then(page => {
            if (page.content.length !== 0 && page.content[0].courseStock === null) setSelectedYearMonth(page.content[0].yearMonth)
            setData(page);
        }).finally(() => setLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<WatchReport> = [
        {
            title: 'ماه',
            dataIndex: 'yearMonth',
            width: 100,
            onCell: (record, index) => ({
                rowSpan: index === 0 ? data?.content.filter(d => d.yearMonth === record.yearMonth).length : (index ? (data?.content[index - 1].yearMonth === record.yearMonth ? 0 : data?.content.filter(d => d.yearMonth === record.yearMonth).length) : 0),
            }),
        },
        {
            title: 'دوره',
            dataIndex: 'courseTitle',
            width: 200,
        },
        {
            title: 'درصد قرارداد دوره',
            dataIndex: 'courseParticipationPercentage',
            width: 150,
            render: (_,{courseParticipationPercentage}) => (
                <>
                    {courseParticipationPercentage || courseParticipationPercentage === 0 ? <span style={{fontSize:'10px'}}> % </span> : null}
                    {courseParticipationPercentage || courseParticipationPercentage === 0 ? courseParticipationPercentage : null}

                </>
            ),
        },
        {
            title: 'میزان مشاهده دوره',
            dataIndex: 'watchTime',
            width: 150,
            render: (_,{watchTime}) => (
                <>
                    {watchTime || watchTime === 0 ? watchTime : null}
                    {watchTime || watchTime === 0 ? <span style={{fontSize:'10px'}}> دقیقه</span> : null}

                </>
            ),
        },
        {
            title: 'فروش کل دوره‌',
            dataIndex: 'courseStock',
            width: 200,
            render: (_,{courseStock}) => (
                <>
                    {courseStock || courseStock === 0 ? numberWithCommas(courseStock) : null}
                    {courseStock || courseStock === 0 ? <span style={{fontSize:'10px'}}> تومان</span> : null}

                </>
            ),
        },
        {
            title:'سهم مدرس',
            dataIndex:'teacherStock',
            width: 200,
            render: (_,{teacherStock}) => (
                <>
                    {teacherStock || teacherStock === 0 ? numberWithCommas(teacherStock) : null}
                    {teacherStock || teacherStock === 0 ? <span style={{fontSize:'10px'}}> تومان</span> : null}

                </>
            ),
        },
        {
            title: 'وضعیت پرداخت',
            dataIndex: 'paymentStatus',
            width: 150,
            render: (_, record) => (
                <>{record.paymentStatus ? <span style={{color:"green"}}>پرداخت شد</span> : <span style={{color:"red"}}>پرداخت نشده</span>}</>
            ),
        },
        {
            title: 'نمودار',
            width: 150,
            render: (_,record) => (
                <a
                   onClick={() => {
                       setSelectedYearMonth(record.yearMonth)
                       const element = document.getElementById('chart');
                       if (element) {
                           element.scrollIntoView({behavior: 'smooth'});
                       }
                   }}
                >
                    مشاهده
                </a>
            ),
            onCell: (record, index) => ({
                rowSpan: index === 0 ? data?.content.filter(d => d.yearMonth === record.yearMonth).length : (index ? (data?.content[index - 1].yearMonth === record.yearMonth ? 0 : data?.content.filter(d => d.yearMonth === record.yearMonth).length) : 0),
            }),
        }
    ];

    return (
        <>
            {
                selectedYearMonth &&
                <Wrapper1 style={{margin: "15px", marginTop:"0px", padding: "20px", fontWeight:'bold'}}>
                    <div id="chart" style={{color: SECOND_COLOR, fontSize: "18px"}}>
                        <span style={{display:'inline-block', marginLeft:'5px'}}>نمودار</span>
                        <span style={{display:'inline-block'}}>({selectedYearMonth})</span>
                    </div>
                    <Divider style={{margin: '0', marginTop: '15px', marginBottom: '25px'}}/>
                    <WatchChart fetchData={async () => {
                        return (await WatchReportService.getTeacherWatchReport({
                            pageNumber: 0,
                            pageSize: 50,
                            yearMonth: selectedYearMonth
                        })).content;
                    }
                    } yearMonth={selectedYearMonth}/>
                </Wrapper1>
            }
            <Wrapper1 style={{margin: "15px", marginTop:"0px", padding: "20px"}}>
                <span style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>جزئیات</span>
                <Divider style={{margin: '0', marginTop: '15px'}}/>

                <Table scroll={{ x: 1500 }} loading={loading} pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: params.pageSize,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            </Wrapper1>

        </>


    );
}