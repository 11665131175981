import React, {useEffect, useMemo, useRef, useState} from "react";
import QuillEditor from "react-quill";
import ReactQuill, {Quill} from "react-quill";
import {Modal} from "antd";
import {EditorContent, TagType} from "./EditorContent";

interface Props {
    style ?: React.CSSProperties;
    onChange ?: any;
    initialValue?:string;
}

const Editor = ({style, onChange, initialValue} : Props) => {

    const [value, setValue] = useState<string | undefined>();
    const quill = useRef<ReactQuill | null>();
    const [showPreView, setShowPreView] = useState<boolean>(false);

    useEffect(() => {
        if (initialValue) setValue(initialValue);
    }, [])

    const icons = Quill.import("ui/icons");
    icons["preView"] = '<svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 9.005a4 4 0 1 1 0 8 4 4 0 0 1 0-8Zm0 1.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM12 5.5c4.613 0 8.596 3.15 9.701 7.564a.75.75 0 1 1-1.455.365 8.503 8.503 0 0 0-16.493.004.75.75 0 0 1-1.455-.363A10.003 10.003 0 0 1 12 5.5Z" fill="#444"/></svg>';

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{align: []}],
                    ["bold", "italic", "underline", "blockquote"],
                    [{color: []}],
                    [{'size': ['small', false, 'large', 'huge']}],
                    [{'script': 'sub'}, {'script': 'super'}],
                    [
                        {list: "ordered"},
                        {list: "bullet"},
                        {indent: "-1"},
                        {indent: "+1"},
                    ],
                    [{direction: 'rtl'}],
                    [{'color': []}, {'background': []}],
                    ["clean"],
                    ["preView"],
                    ["link"]
                ],
                handlers:{
                    'preView': ()=>(setShowPreView(true))
                }
            },
            clipboard: {
                matchVisual: true,
            },
        }),
        []
    );

    return (
        <>
            <div className="quill-container">
                <QuillEditor
                    ref={(el) => (quill.current = el)}
                    style={{...style}}
                    theme="snow"
                    value={value}
                    modules={modules}
                    onChange={(value) => {
                        setValue(value);
                        onChange(value);
                    }}
                />
            </div>
            <Modal
                title="پیش نمایش"
                centered
                open={showPreView}
                onOk={() => {}}
                onCancel={() => {
                    setShowPreView(false);
                }}
                footer={[]}
                bodyStyle={{maxHeight:"700px", overflow: 'auto'}}
                width={720}
            >
                <EditorContent tagType={TagType.DIV} content={value}/>
            </Modal>
        </>
    );
};

export default Editor;