import React from "react";
import {Footer} from "../../components/new-footer";
import {HeaderWrapper} from "../../components/header-wrapper";
import {TeachersContainer} from "../../components/teacher/TeachersContainer";

export const TeachersPage =() => {

    return(
        <>
            <HeaderWrapper/>
            <TeachersContainer/>
            <Footer/>
        </>
    );
}