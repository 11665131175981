import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {Chapter, Course} from "./CourseService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

class ChapterService {

    private static instance: ChapterService;

    private constructor() {
    }

    public static getInstance(): ChapterService {
        if (!ChapterService.instance) {
            ChapterService.instance = new ChapterService();
        }
        return ChapterService.instance;
    }

    async delete(id : number | undefined): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/chapters/" + id, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async update(id : number | undefined, input: Chapter): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/chapters/" + id, input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default ChapterService.getInstance();