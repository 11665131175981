import React, {useState} from "react";
import {Avatar, Card} from "antd";
import {ELEVENTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import {User} from "../../services/UserService";
import {DegreeTitle, DegreeWeight} from "../../services/EducationService";
import {TeacherResumeModal} from "./TeacherResumeModal";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

interface Props {
    teacher: User;
}

export const TeacherCard = ({teacher}: Props) => {

    const [showModal, setShowModal] = useState<boolean>(false);

    const getTeacherEducation = (teacher : User) => {
        const list = teacher?.educations.sort((a, b) => DegreeWeight[b.degree] - DegreeWeight[a.degree]);
        if (list && list.length > 0){
            const education = list[0];
            return (
                <div>
                    <div style={{marginLeft:"10px"}}>
                        {DegreeTitle[education.degree]}
                    </div>
                    <div style={{marginLeft:"10px", color:ELEVENTH_COLOR}}>
                        {education.university}
                    </div>

                </div>
            )
        }
    }

    return (
        <div>
            <a onClick={event => setShowModal(true)}>
            <Card
                style={{width: 350, marginRight: "auto", marginLeft: "auto", fontWeight: '100', maxWidth:"100%"}}
                bordered={false}
                hoverable
            >
                <Card.Grid hoverable={false} style={{width: '100%', height: '140px', fontSize: '14px'}}>
                    <div style={{textAlign:"right"}}>
                        <Avatar size={90} src={baseNonVideoUrl + teacher.photoUrl} style={{marginLeft:"30px"}} />
                        <span style={{fontSize:"15px"}}>{teacher.firstName + " " + teacher.lastName}</span>
                    </div>

                </Card.Grid>
                <Card.Grid hoverable={false} style={{
                    width: '100%',
                    height: '100px',
                    padding: '8px 25px',
                    textAlign: 'left',
                    color: THIRD_COLOR,
                    fontSize: '14px'
                }}>
                    <div style={{textAlign:"right", marginTop:"20px"}}>
                        {
                            getTeacherEducation(teacher)
                        }
                    </div>
                </Card.Grid>
                {/*<Card.Grid hoverable={false} style={{*/}
                {/*    width: '100%',*/}
                {/*    height: '50px',*/}
                {/*    padding: '15px 35px',*/}
                {/*    fontSize: '15px'*/}
                {/*}}>*/}
                {/*    <div style={{color: SECOND_COLOR, fontWeight: 'bold', textAlign:"center"}}>*/}
                {/*        این دوره به صورت تکی به فروش نمی رسد*/}
                {/*</div>*/}
                {/*</Card.Grid>*/}
            </Card>

        </a>
    {
        (() => {
            return (showModal ?
                <TeacherResumeModal show={showModal} setShow={setShowModal} teacher={teacher}/> : null)
        })()
    }
        </div>
    );

}