import axios from "axios";
import {genericApiCall, Page, PageQueryParams} from "./UtilFunctions";
import TokenService from "./TokenService";
import {Degree, Education} from "./EducationService";
import {
    BrowserType,
    Gender,
    IntroductionMethod,
    OSType,
    ScientificAssociationRole,
    SessionStatus
} from "../constans/enums";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface User {
    id ?: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    mobile: string;
    description: string;
    photoUrl:string;
    photo:any;
    roles: Role[];
    locked:boolean;
    educations:Education[];
    gender:Gender;
    university:string;
    degree:Degree;
    introductionMethod:IntroductionMethod;
    scientificAssociationRole:ScientificAssociationRole;
    completeProfileProgress:number;
    fieldOfStudy:string;
    cardNumber:string;
    lastActivityStartDateTime: Date;
    lastActivityEndDateTime: Date;
    online: boolean;
    activeDeviceCount:number;
}

export interface BankAccount {
    cardNumber:string;
}

export enum Role{
    ROLE_USER = 'ROLE_USER',
    ROLE_TEACHER = 'ROLE_TEACHER',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_TEACHER_REPORT = 'ROLE_TEACHER_REPORT',
    ROLE_TEACHER_PAYMENT = 'ROLE_TEACHER_PAYMENT'
}

export enum RoleTitle{
    ROLE_USER = 'کاربر',
    ROLE_TEACHER = 'مدرس',
    ROLE_ADMIN = 'ادمین',
    ROLE_TEACHER_REPORT = 'گزارش مدرس',
    ROLE_TEACHER_PAYMENT = 'مالی مدرس'
}

export interface Session {
    id : string;
    ip: string;
    proxyIp: string;
    host: string;
    os: OSType;
    browserType: BrowserType;
    accessTokenExp: Date;
    refreshTokenExp:Date;
    status:SessionStatus;
    online: boolean;
}

class UserService {

    private static instance: UserService;

    private constructor() {
    }

    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    async getUsers(params : any): Promise<Page<User>> {
        return await genericApiCall<Page<User>>(async () => {
            const response = await axios.get<Page<User>>(baseUrl + "/api/v1/users", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async addUser(input:User): Promise<User> {
        return await genericApiCall<User>(async () => {
            const response = await axios.post<User>(baseUrl + "/api/v1/users", input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async updateUser(id:number | undefined, input:any): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/users/" + id, input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async selfUpdateUser(input:any): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/users/self", input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getSelfProfile(): Promise<User> {
        return await genericApiCall<User>(async () => {
            const response = await axios.get<User>(baseUrl + "/api/v1/users/self", {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getPublicTeachers(params : any): Promise<Page<User>> {
        return await genericApiCall<Page<User>>(async () => {
            const response = await axios.get<Page<User>>(baseUrl + "/public/v1/users/teachers", {
                params
            });
            return response.data;
        });
    }

    async completeProfile(input:User): Promise<User> {
        return await genericApiCall<User>(async () => {
            const response = await axios.patch<User>(baseUrl + "/api/v1/users/complete-profile", input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async editBankAccount(input:BankAccount): Promise<User> {
        return await genericApiCall<User>(async () => {
            const response = await axios.put<User>(baseUrl + "/api/v1/users/bank-account/self", input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getUserSessions(userId : number): Promise<Session[]> {
        return await genericApiCall<Session[]>(async () => {
            const response = await axios.get<Session[]>(baseUrl + "/api/v1/users/sessions/" + userId, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default UserService.getInstance();