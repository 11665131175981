export const FIRST_COLOR = '#5FC8D7';
export const SECOND_COLOR = '#FF9F00';
export const THIRD_COLOR = '#5d5d5d';
export const FORTH_COLOR = '#fff';
export const FIFTH_COLOR = '#010101';
export const SIXTH_COLOR = '#f9f9f9';
export const SEVENTH_COLOR = 'rgba(192,192,192,0.34)';
export const ELEVENTH_COLOR = 'rgba(93,93,93,0.73)';
export const THIRD_MAIN_COLOR = '#273A97';
export const COLORS = ['#0074D9', '#00C49F', '#FF8042', '#FF6B6B', '#118AB2', '#8338EC', '#3D9970', '#FFBB28', '#FF4136'];

export const CHART_COLORS = [
    {
        backgroundColor:'rgba(255, 99, 132, 0.2)',
        borderColor:'rgba(255, 99, 132, 1)'
    },
    {
        backgroundColor:'rgba(54, 162, 235, 0.2)',
        borderColor:'rgba(54, 162, 235, 1)',
    },
    {
        backgroundColor:'rgba(255, 206, 86, 0.2)',
        borderColor:'rgba(255, 206, 86, 1)',
    },
    {
        backgroundColor:'rgba(75, 192, 192, 0.2)',
        borderColor:'rgba(75, 192, 192, 1)',
    },
    {
        backgroundColor:'rgba(153, 102, 255, 0.2)',
        borderColor:'rgba(153, 102, 255, 1)',
    },
    {
        backgroundColor:'rgba(255, 159, 64, 0.2)',
        borderColor:'rgba(255, 159, 64, 1)',
    },
    {
        backgroundColor:'rgba(112,255,64,0.2)',
        borderColor:'rgba(112,255,64,1)',
    },
    {
        backgroundColor:'rgba(214,248,2,0.2)',
        borderColor:'rgba(214,248,2,1)',
    },
    {
        backgroundColor:'rgba(0,255,191,0.2)',
        borderColor:'rgba(0,255,191,1)',
    },
    {
        backgroundColor:'rgba(0,21,255,0.2)',
        borderColor:'rgba(0,21,255,1)',
    },
    {
        backgroundColor:'rgba(255,0,0,0.2)',
        borderColor:'rgba(255,0,0,1)',
    }
]
