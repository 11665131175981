import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {Category} from "./CategoryService";
import {User} from "./UserService";
import React from "react";
import {CourseTag, CourseStatus} from "../constans/enums";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface Course {
    key?: React.Key;
    id?: number;
    title: string;
    description: string;
    resources: string;
    coverUrl: string;
    cover: any;
    presentationFile: any;
    presentationUrl: string;
    categoryIds: number[];
    teacherId: number;
    categories: Category[];
    teacher: User;
    sessionsCounts: number;
    sessionsMinutes: number;
    chaptersCounts: number;
    type: CourseType;
    courseStatus: CourseStatus;
    courseTag: CourseTag;
    active:boolean;
    priorityNumber:number;
    participationPercentage:number;
}

export interface CategoryCoursesOutputModel {
    courses : Course[];
    existingCoursesCounts : number;
    recordingCoursesCounts : number;
}

export interface LessonInput {
    title: string;
    description: string;
    duration: number;
    number?: number;
    cover: any;
    video: any;
    videoFilePath: string;
    accessLevel: LessonAccessLevel;
    chapterId: number;
    chapterTitle: string;
    chapterNumber: number;
}

export enum LessonAccessLevel {
    FREE = 'FREE',
    USER = 'USER',
    BUYER = 'BUYER'
}

export enum LessonAccessLevelTitle {
    FREE = 'رایگان',
    USER = 'کاربران',
    BUYER = 'خریدار'
}

export enum CourseType {
    CONTRACT = 'CONTRACT',
    OWNER = 'OWNER'
}

export enum CourseTypeTitle {
    CONTRACT = 'قرار دادی',
    OWNER = 'صاحب دوره'
}

export interface ChaptersResponse {

    uuid: string
    chapters: Chapter[];

}
export interface CoursePageUuidResponse {

    uuid: string
    page: Page<Course>;

}

export interface Chapter {

    id: number;
    title: string;
    number: number;
    lessons: Lesson[];

}

export interface Lesson {

    id: number;
    title: string;
    description: string;
    coverUrl: string;
    videoUrl: string;
    videoUrl1080?: string;
    videoUrl720?: string;
    videoUrl480?: string;
    duration: number;
    number?:number;
    accessLevel: LessonAccessLevel;
    chapterId?: number;

}

class CourseService {

    private static instance: CourseService;

    private constructor() {
    }

    public static getInstance(): CourseService {
        if (!CourseService.instance) {
            CourseService.instance = new CourseService();
        }
        return CourseService.instance;
    }

    async getCoursesForAdmin(params: any): Promise<CoursePageUuidResponse> {
        let uuid: string = "";
        const page = await genericApiCall<Page<Course>>(async () => {
            const response = await axios.get<Page<Course>>(baseUrl + "/api/v1/courses", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            uuid = response.headers['uuid'];
            return response.data;
        });
        return {uuid, page}
    }

    async addCourse(input: Course): Promise<Course> {
        return await genericApiCall<Course>(async () => {
            const response = await axios.post<Course>(baseUrl + "/api/v1/courses", input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async updateCourse(id : number | undefined, input: Course): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/courses/" + id, input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async deleteCourse(id : number | undefined): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<Course>(baseUrl + "/api/v1/courses/" + id, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async addNewLesson(courseId: number, input: LessonInput): Promise<void> {
        return await genericApiCall<void>(async () => {
            await axios.post<LessonInput>(baseUrl + '/api/v1/courses/' + courseId + "/lessons", input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
        });
    }

    async updateLesson(lessonId: number | undefined, input: LessonInput): Promise<void> {
        return await genericApiCall<void>(async () => {
            await axios.put<LessonInput>(baseUrl + '/api/v1/courses/lessons/' + lessonId, input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
        });
    }

    async deleteLesson(lessonId: number | undefined): Promise<void> {
        return await genericApiCall<void>(async () => {
            await axios.delete<LessonInput>(baseUrl + '/api/v1/courses/lessons/' + lessonId, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
        });
    }

    async getLessonsForAdmin(courseId: number): Promise<Chapter[]> {
        return await genericApiCall<Chapter[]>(async () => {
            const response = await axios.get<Chapter[]>(baseUrl + '/api/v1/courses/' + courseId + "/lessons", {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async searchCourses(params: any): Promise<Page<Course>> {
        return await genericApiCall<Page<Course>>(async () => {
            const response = await axios.get<Page<Course>>(baseUrl + "/public/v1/courses", {
                params
            });
            return response.data;
        });
    }

    async getById(id ?: number): Promise<Course> {
        return await genericApiCall<Course>(async () => {
            const response = await axios.get<Course>(baseUrl + "/public/v1/courses/" + id);
            return response.data;
        });
    }

    async getLessonsById(id ?: number): Promise<ChaptersResponse> {
        let uuid: string = "";
        const chapters = await genericApiCall<Chapter[]>(async () => {
            const token = await TokenService.getOptionalToken();
            const response = await axios.get<Chapter[]>(
                baseUrl + "/public/v1/courses/" + id + "/lessons",
                token ?
                    {
                        headers: {
                            'Authorization': "Bearer " + token
                        }
                    } : {}
            );
            uuid = response.headers['uuid'];
            return response.data;
        });
        return {uuid: uuid, chapters: chapters}
    }

    async watchLesson(lessonId: number, watchedMinute: number): Promise<void> {
        return await genericApiCall<void>(async () => {
            const token = await TokenService.getOptionalToken();
            await axios.post(baseUrl + "/public/v1/courses/lessons/watch", {lessonId, watchedMinute},
                token ?
                    {
                        headers: {
                            'Authorization': "Bearer " + token
                        }
                    } : {});
        });
    }


    async getCategoryCourses(categoryId: number): Promise<CategoryCoursesOutputModel> {
        return await genericApiCall<CategoryCoursesOutputModel>(async () => {
            const response = await axios.get<CategoryCoursesOutputModel>(baseUrl + "/public/v1/courses/categories/" + categoryId);
            return response.data;
        });
    }

    async getTopCategoryCourses(categoryId ?: number, size?:number): Promise<Course[]> {
        return await genericApiCall<Course[]>(async () => {
            const response = await axios.get<Course[]>(baseUrl + "/public/v1/courses/categories/top/" + categoryId, {
                params:{size}
            });
            return response.data;
        });
    }

}

export default CourseService.getInstance();