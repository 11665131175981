import React from "react";
import {Category} from "../../../services/CategoryService";
import {Course} from "../../../services/CourseService";
import {FIRST_COLOR} from "../../../constans/colors";
import {AutoLoadCoursesPreviewCarouselWrapper} from "../../course/preview/AutoLoadCoursesPreviewCarouselWrapper";
import {SEARCH_ROUTE} from "../../../routes/route-path";

interface Data {
    category : Category;
    courses : Course[];
}
const title = "جدیدترین ها";

export const NewCoursesPreview = () => {

    const defaultParams = {
        pageNumber: 0,
        pageSize: 5,
        ignoreEmptyCourses:true,
        sortKey:"lastLessonAddedDate",
        sort:"DESC"
    }

    return (
        <div className="container">
            <AutoLoadCoursesPreviewCarouselWrapper
                moreUrl={SEARCH_ROUTE + "?ignoreEmptyCourses=" + defaultParams.ignoreEmptyCourses + "&sortKey=" + defaultParams.sortKey + "&sort=" + defaultParams.sort + "&path=" + title}
                color={FIRST_COLOR} title={title}
                defaultParams={defaultParams}
                style={{marginTop: "35px"}}
            />
        </div>
    );

}