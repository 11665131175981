import React, {useEffect, useState} from "react";
import {Input} from "antd";

interface Props {
    sendRequest : any
}

export const SearchFilterInput = ({sendRequest} : Props) => {

    const [value, setValue] = useState<string>();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (value !== undefined) sendRequest(value === "" ? null : value);
        }, 800);

        return () => clearTimeout(timer);
    }, [value])

    return (
        <Input placeholder="جستجو..." onChange={event => setValue(event.target.value)} value={value}/>
    );

}