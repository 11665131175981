import React from "react";
import {Avatar, Divider, Modal, Select} from "antd";
import {User} from "../../services/UserService";
import {DegreeTitle, DegreeWeight} from "../../services/EducationService";
import {ELEVENTH_COLOR} from "../../constans/colors";
import {TEACHER_COURSES_ROUTE} from "../../routes/route-path";
import {EditorContent, TagType} from "../common/EditorContent";
const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

interface Props {
    show: boolean;
    teacher : User;
    setShow(b: boolean): void;
}

export const TeacherResumeModal = ({show, setShow, teacher}: Props) => {

    return (
        <Modal
            title="درباره مدرس"
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                setShow(false);
            }}
            footer={[]}
            width={720}
        >
            <span>
                <Avatar size={55} src={baseNonVideoUrl + teacher.photoUrl} style={{marginLeft:"20px"}} />
                <span style={{fontSize:"15px", marginLeft:"20px"}}>{teacher.firstName + " " + teacher.lastName}</span>
                <a href={TEACHER_COURSES_ROUTE.replace(":id", teacher.id + "")} style={{fontSize:"16px"}}> دوره های مدرس</a>
                <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
                 <h3>تحصیلات</h3>
                {teacher?.educations.sort((a, b) => DegreeWeight[b.degree] - DegreeWeight[a.degree]).map(education => {
                    return (
                        <div>
                            <span style={{float:"right", marginLeft:"10px"}}>
                                {DegreeTitle[education.degree]}
                            </span>
                            <span style={{float:"right", marginLeft:"10px", color:ELEVENTH_COLOR}}>
                                {education.university}
                            </span>
                            <span style={{color:ELEVENTH_COLOR}}>
                                {(education.startYear ? education.startYear : "")  + (education.startYear && education.endYear ? " - " : "") + (education.endYear ? education.endYear : "")}
                            </span>
                            <div style={{paddingTop: "5px", paddingRight: "15px", paddingLeft: "15px", paddingBottom: "25px", fontSize: "12px"}}>
                                <p style={{whiteSpace: 'pre-line', textAlign: 'justify'}}>{education.description}</p>
                            </div>
                        </div>
                    )
                })}
            </span>
            <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
            <h3>اطلاعات بیشتر</h3>
            <EditorContent tagType={TagType.DIV} content={teacher.description}/>
        </Modal>
    );

}