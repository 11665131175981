import React, {useState} from "react";
import {faqQuestions} from "../../constans/faq-questions"
import {Collapse, Divider, Typography} from 'antd';
import {SearchInput} from "./search-input";
import {SECOND_COLOR} from "../../constans/colors";
import {Wrapper1} from "../wrapper/Wrapper1";

const {Panel} = Collapse;
const {Text} = Typography;
export const FAQ = () => {

    const [searchTerm, setSearchTerm] = useState('');

    // Filter FAQ data based on search term
    const filteredFaqs = faqQuestions.filter((item) =>
        item.question.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="container">
            <Wrapper1 style={{marginTop: "55px", padding: "15px 15px"}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>سوالات متداول</div>
                <Divider style={{margin: '15px 0px'}}/>
                <SearchInput onSearch={value => {
                    setSearchTerm(value);
                }}/>
                <Collapse
                    bordered={false}
                    expandIconPosition="right"
                    style={{backgroundColor: 'inherit'}}
                >
                    {filteredFaqs.length > 0 ? (
                        filteredFaqs.map((item, index) => (
                            <Panel
                                header={<Text strong style={{fontSize: '16px'}}>{item.question}</Text>}
                                key={index}
                                style={{
                                    marginBottom: '10px',
                                    background: '#ffffff',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '8px',
                                    textAlign:'justify',
                                    textJustify:'inter-word'
                                }}
                            >
                                <Text style={{fontSize: '16px'}}>{item.answer}</Text>
                            </Panel>
                        ))
                    ) : null}
                </Collapse>
            </Wrapper1>
        </div>
    );

}