import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxStore";
import {Category} from "../../services/CategoryService";
import {Menu, MenuItem, Sidebar} from "react-pro-sidebar";
import {Col, Row} from "antd";
import {BUY_PLAN_ROUTE, CATEGORY_COURSES_ROUTE, HOME_PAGE_ROUTE} from "../../routes/route-path";
import logo from "../../assets/images/horizontal-logo.png";
import {SearchInput} from "../new-search-input";
import {Login} from "./Login";
import {useNavigate} from "react-router-dom";
import {User} from "../../services/UserService";
import {ShoppingCart} from "./ShoppingCart";

const colStyle: any = {};

interface Props {
    profile:User | undefined;
}

export const MobileHeader = ({profile} : Props) => {

    const categories: Category[] = useSelector((state: RootState) => state.categories);
    const shoppingCart: number[] = useSelector((state: RootState) => state.cart);
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const navigate = useNavigate();

    const getCartCount = () => {
        return shoppingCart.length;
    }

    return (
        <div style={{
            borderBottom: '1px solid #e0e0e0',
            boxShadow: '0px 1px 8px 0px #00000038'
        }}>
            <div className="container" style={{paddingTop: "15px"}}>
                <Row gutter={[35, 16]} align='middle'>
                    <Col flex="2" style={colStyle}>
                        <a onClick={event => setCollapsed(false)}>
                            <svg style={{verticalAlign:"middle"}} width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.752999 13H19.253C19.443 13 19.6259 13.0722 19.7648 13.202C19.9036 13.3317 19.9881 13.5093 20.001 13.6989C20.014 13.8884 19.9545 14.0759 19.8346 14.2233C19.7147 14.3707 19.5432 14.4671 19.355 14.493L19.253 14.5H0.752999C0.562976 14.4999 0.380058 14.4277 0.241208 14.298C0.102357 14.1683 0.0179253 13.9907 0.00497333 13.8011C-0.00797866 13.6115 0.0515145 13.4241 0.171432 13.2767C0.291349 13.1293 0.462749 13.0329 0.650999 13.007L0.752999 13ZM0.752999 6.50299H19.253C19.443 6.50305 19.6259 6.57523 19.7648 6.70496C19.9036 6.83469 19.9881 7.01229 20.001 7.20187C20.014 7.39145 19.9545 7.57888 19.8346 7.72629C19.7147 7.8737 19.5432 7.97009 19.355 7.99599L19.253 8.00299H0.752999C0.562976 8.00293 0.380058 7.93075 0.241208 7.80102C0.102357 7.67129 0.0179253 7.49369 0.00497333 7.30411C-0.00797866 7.11453 0.0515145 6.9271 0.171432 6.77969C0.291349 6.63228 0.462749 6.53589 0.650999 6.50999L0.752999 6.50299ZM0.751999 0.00299072H19.252C19.442 0.00304897 19.6249 0.0752355 19.7638 0.204964C19.9026 0.334692 19.9871 0.512289 20 0.701871C20.013 0.891452 19.9535 1.07888 19.8336 1.22629C19.7136 1.3737 19.5422 1.47009 19.354 1.49599L19.252 1.50299H0.751999C0.561976 1.50293 0.379058 1.43075 0.240208 1.30102C0.101357 1.17129 0.0169252 0.993692 0.00397316 0.804111C-0.00897883 0.614529 0.0505145 0.427099 0.170432 0.279692C0.290349 0.132285 0.461749 0.0358926 0.649999 0.00999117L0.751999 0.00299072Z" fill="#262626"/>
                            </svg>
                        </a>
                    </Col>
                    <Col flex="120px" style={colStyle}>
                        <a href={HOME_PAGE_ROUTE} style={{width: "120px", height: "64px"}}><img src={logo} alt="logo"/></a>
                    </Col>

                    <Col flex="20px" style={{...colStyle}}>
                        <Login profile={profile}/>
                    </Col>
                    <Col flex="20px" style={{...colStyle}}>
                        <a href={BUY_PLAN_ROUTE}>
                            <ShoppingCart/>
                        </a>
                    </Col>
                    <Col span={24} style={{marginBottom:"15px"}} >
                        <SearchInput/>
                    </Col>
                </Row>

                <Sidebar style={{color:"#262626", position:"fixed", zIndex:"100", top:0, right:0, height:"100vh"}} collapsed={collapsed} collapsedWidth="0px" backgroundColor="#FFFFFF">
                    <Menu>
                        <a style={{color:"#262626", fontSize:"30px", margin:"20px", cursor:"pointer", height:"100vh"}} onClick={event => setCollapsed(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                <path d="M0 0h24v24H0z" fill="none"/>
                            </svg>
                        </a>
                        {
                            categories && categories.map(category => {
                                return (
                                    <MenuItem onClick={event => window.location.replace(CATEGORY_COURSES_ROUTE.replace(":id", category.id + ""))} style={{fontSize:"16px", lineHeight:"24px", color:"#262626"}}>{category.title} </MenuItem>
                                )
                                }
                            )
                        }
                    </Menu>
                </Sidebar>
            </div>
        </div>
    );
}