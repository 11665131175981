import React, {useState} from "react";
import {Button, Form, Input, Modal} from "antd";
import TeacherPaymentService, {TeacherPayment} from "../../../../services/TeacherPaymentService";
import {openSuccessNotification} from "../../../../services/UtilFunctions";

interface Props {
    show:boolean
    setShow(b : boolean):void
    data:TeacherPayment | undefined
}

export const TeacherPaymentUpdate =({show, setShow, data} : Props) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        if (data){
            data = {...data, trackId:values.trackId, paymentStatus:true}
            if (data?.id){
                TeacherPaymentService.update(data.id, data).then(response => {
                    form.resetFields();
                    setShow(false);
                    setLoading(false);
                    openSuccessNotification();
                }).catch(reason => {
                    setLoading(false);
                });
            }
        }
    }

    return(
        <Modal
            title="پرداخت"
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                layout='vertical'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="trackId"
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                    initialValue={data?.trackId}
                    label="کد رهگیری"
                >
                    <Input placeholder="کد رهگیری"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        پرداخت
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}