import axios from "axios";
import {genericApiCall} from "./UtilFunctions";
import TokenService from "./TokenService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface Category {
    id ?: number;
    title: string;
    cover: any;
    coverUrl: string;
}

class CategoryService {

    private static instance: CategoryService;

    private constructor() {
    }

    public static getInstance(): CategoryService {
        if (!CategoryService.instance) {
            CategoryService.instance = new CategoryService();
        }
        return CategoryService.instance;
    }

    async getCategoriesForAdmin(): Promise<Category[]> {
        return await genericApiCall<Category[]>(async () => {
            const response = await axios.get<Category[]>(baseUrl + "/api/v1/categories", {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async deleteCategory(id : number): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<Category>(baseUrl + "/api/v1/categories/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async updateCategory(id:number | undefined, input:any): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<Category>(baseUrl + "/api/v1/categories/" + id, input,  {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }


    async getCategories(): Promise<Category[]> {
        return await genericApiCall<Category[]>(async () => {
            const response = await axios.get<Category[]>(baseUrl + "/public/v1/categories");
            return response.data;
        });
    }

    async addCategory(input:Category): Promise<Category> {
        return await genericApiCall<Category>(async () => {
            const response = await axios.post<Category>(baseUrl + "/api/v1/categories", input, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default CategoryService.getInstance();