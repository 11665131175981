import React from "react";
import {Category} from "../../../services/CategoryService";
import {Course} from "../../../services/CourseService";
import {THIRD_MAIN_COLOR} from "../../../constans/colors";
import {AutoLoadCoursesPreviewCarouselWrapper} from "../../course/preview/AutoLoadCoursesPreviewCarouselWrapper";
import {SEARCH_ROUTE} from "../../../routes/route-path";

interface Data {
    category: Category;
    courses: Course[];
}

const title = "پربازدیدترین ‌ها";

export const MostViewCoursesPreview = () => {

    const defaultParams = {
        pageNumber: 0,
        pageSize: 5,
        ignoreEmptyCourses: true,
        sortKey: "periodWatchTimeDividedByTotalDuration",
        sort: "DESC"
    }

    return (
        <div className="container">
            <AutoLoadCoursesPreviewCarouselWrapper
                moreUrl={SEARCH_ROUTE + "?ignoreEmptyCourses=" + defaultParams.ignoreEmptyCourses + "&sortKey=" + defaultParams.sortKey + "&sort=" + defaultParams.sort + "&path=" + title}
                color={THIRD_MAIN_COLOR} title={title}
                defaultParams={defaultParams}
                style={{marginTop: "35px"}}
            />

        </div>
    );

}