import React from "react";
import {Footer} from "../../../components/new-footer";
import {Login} from "../../../components/auth/login";
import {HeaderWrapper} from "../../../components/header-wrapper";
import TokenService from "../../../services/TokenService";
import {HOME_PAGE_ROUTE} from "../../../routes/route-path";

export const LoginPage = () => {

    if (TokenService.isLogin()){
        document.location.href = HOME_PAGE_ROUTE;
        return null;
    }

    return (
        <>
            <HeaderWrapper/>
            <Login/>
            <Footer/>
        </>
    );
}