import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {User} from "./UserService";
import {WatchReport} from "./WatchReportService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface TeacherPayment {
    key?:number;
    id ?: number;
    createdDate ?: Date;
    amount: number;
    paymentStatus: boolean;
    cardNumber: string;
    trackId: string;
    user:User
    watchReports: WatchReport[];
}

class TeacherPaymentService {

    private static instance: TeacherPaymentService;

    private constructor() {
    }

    public static getInstance(): TeacherPaymentService {
        if (!TeacherPaymentService.instance) {
            TeacherPaymentService.instance = new TeacherPaymentService();
        }
        return TeacherPaymentService.instance;
    }

    async getTeacherStockForAdmin(id:number): Promise<number> {
        return await genericApiCall<number>(async () => {
            const response = await axios.get<number>(baseUrl + "/api/v1/watch-report/teacher/stock/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getTotalTeacherStock(): Promise<number> {
        return await genericApiCall<number>(async () => {
            const response = await axios.get<number>(baseUrl + "/api/v1/watch-report/teacher/total-stocks", {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getTeacherStockForTeacher(): Promise<number> {
        return await genericApiCall<number>(async () => {
            const response = await axios.get<number>(baseUrl + "/api/v1/watch-report/teacher/stock", {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async teacherRequest(): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/teacher-payment/request",{}, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async update(id:number, data:TeacherPayment): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/teacher-payment/" + id,data, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async delete(id:number): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/teacher-payment/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getTeacherPaymentBySelf(params:any): Promise<Page<TeacherPayment>> {
        return await genericApiCall<Page<TeacherPayment>>(async () => {
            const response = await axios.get<Page<TeacherPayment>>(baseUrl + "/api/v1/teacher-payment/self", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getTeacherPayment(params:any): Promise<Page<TeacherPayment>> {
        return await genericApiCall<Page<TeacherPayment>>(async () => {
            const response = await axios.get<Page<TeacherPayment>>(baseUrl + "/api/v1/teacher-payment", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }


}

export default TeacherPaymentService.getInstance();