import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {Course} from "./CourseService";
import {Category} from "./CategoryService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface TopCourses {
    id?: number;
    categoryId?: number;
    courseId?: number;
    course?: Course;
    category?: Category;
    priorityNumber?:number;
}

class TopCoursesService {

    private static instance: TopCoursesService;

    private constructor() {
    }

    public static getInstance(): TopCoursesService {
        if (!TopCoursesService.instance) {
            TopCoursesService.instance = new TopCoursesService();
        }
        return TopCoursesService.instance;
    }

    async add(input: TopCourses): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/top-courses", input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async delete(id: number): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/top-courses/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getAdminTopCoursesPage(params: any): Promise<Page<TopCourses>> {
        return await genericApiCall<Page<TopCourses>>(async () => {
            const response = await axios.get<Page<TopCourses>>(baseUrl + "/api/v1/top-courses", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getTopCategoryCourses(params: any): Promise<Page<Course>> {
        return await genericApiCall<Page<Course>>(async () => {
            const response = await axios.get<Page<Course>>(baseUrl + "/public/v1/top-courses", {
                    params
                }
            );
            return response.data;
        });
    }

}

export default TopCoursesService.getInstance();