import StorageService from "./StorageService";

class ShoppingCartService {

    private static instance: ShoppingCartService;

    private constructor() {}

    public static getInstance(): ShoppingCartService {
        if (!ShoppingCartService.instance) {
            ShoppingCartService.instance = new ShoppingCartService();
        }
        return ShoppingCartService.instance;
    }

    addToCart(id:number) {
        const cart = StorageService.getItem("cart");
        if (cart){
            if (!cart.includes(id)) {
                cart.push(id);
            }
            StorageService.setItem("cart", cart)
        }
        else StorageService.setItem("cart", [id]);
    }

    get() : number[] {
        const cart = StorageService.getItem("cart");
        if (cart) return cart;
        else return [];
    }

    count() : number {
        const cart = StorageService.getItem("cart");
        if (cart){
            return cart.length;
        }
        else return 0;
    }

    remove(id:number) {
        const cart = StorageService.getItem("cart");
        if (cart){
            StorageService.setItem("cart", cart.filter((item : number) => item !== id))
        }
    }

    removeAll() {
        const cart = StorageService.getItem("cart");
        if (cart){
            StorageService.setItem("cart", []);
        }
    }

}

export default ShoppingCartService.getInstance();