import React, {useEffect, useState} from "react";
import './topLine.css'
import {Col, Row} from 'antd';
import {useCountdown} from "../../hooks/useCountdown";
import DateService from "../../services/DateService";
import yalda1 from '../../assets/images/yalda-1.jpg';
import {BUY_PLAN_ROUTE} from "../../routes/route-path";

export const YaldaTopLine = () => {

    const [correctTime, setCorrectTime] = useState<boolean>(true);

    const [days, hours, minutes, seconds] = useCountdown('2024-12-19T00:00:00.000+03:30');
    // #d30f07 red
    // #3cb043 green
    const countDownBackgroundColor = "rgba(255,255,255, 0.9)";
    const countDownBackgroundStyle = {
        color: "#009a7e",
        backgroundColor: countDownBackgroundColor,
        width: "2.1rem",
        display: "inline-block",
        borderRadius: "4px",
        padding: "10px 5px",
        lineHeight: "0.7rem"
    };
    const countDownDelimiterStyle = {
        color: "#009a7e",
        display: "inline-block",
        paddingLeft: "4px",
        paddingRight: "4px"
    };

    useEffect(() => {
        const localDate = new Date();
        DateService.getDate().then(value => {
            const serverDate = new Date(value + "+03:30");
            const timeDifference = Math.abs(localDate.getTime() - serverDate.getTime());
            setCorrectTime(timeDifference < 900000)
        })
    })


    return (
        <div
            // onClick={event => document.location.href = BUY_PLAN_ROUTE}
            style={{
                // backgroundColor: "#222222",
                textAlign: "center",
                // height: "60px",
                backgroundImage: `url(${yalda1})`,
                backgroundSize: "contain",
                // cursor: 'pointer'
            }}
        >
            <div
                className="container"
                style={{
                    padding: "2px",
                    paddingTop: "7px",
                    paddingBottom: "7px"
                }}
            >
                <Row align="middle" justify='space-between' gutter={[8, 8]}>
                    <Col sm={14} xs={24}>
                        <span
                            style={{
                                backgroundColor: 'rgba(255,255,255, 0.9)',
                                color: '#ff4948',
                                padding: '5px 10px',
                                borderRadius: "4px",
                                fontSize: "1.2rem",
                                fontWeight: "bold"
                            }}
                        >
                            تخفیفات تکرار نشدنی شب یلدا!
                        </span>
                    </Col>
                    <Col sm={10} xs={24}>
<span
    style={{
        display: correctTime ? 'inline' : 'none',
        fontSize: "1.2rem",
        fontWeight: "bold"
    }}>
<span style={countDownBackgroundStyle}>{seconds} <span style={{fontSize: "0.7rem"}}>ثانیه</span></span>
<span style={countDownDelimiterStyle}>:</span>
<span style={countDownBackgroundStyle}>{minutes} <span style={{fontSize: "0.7rem"}}>دقیقه</span></span>
<span style={countDownDelimiterStyle}>:</span>
    <span style={countDownBackgroundStyle}>{hours} <span style={{fontSize: "0.7rem"}}>ساعت</span></span>
    <span style={countDownDelimiterStyle}>:</span>
        <span style={countDownBackgroundStyle}>{days} <span style={{fontSize: "0.7rem"}}>روز</span></span>


</span>
                    </Col>
                </Row>
            </div>
        </div>
    );

}