import React from "react";

import {ContactUs} from "../../components/contact-us";
import {Footer} from "../../components/new-footer";
import {HeaderWrapper} from "../../components/header-wrapper";

export const ContactUsPage =() => {
    return(
        <>
            <HeaderWrapper/>
            <ContactUs/>
            <Footer/>
        </>
    );
}