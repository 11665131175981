import React, {useEffect, useState} from "react";
import {Wrapper1} from "../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../constans/colors";
import {Alert, Badge, Button, Col, Collapse, Divider, Form, Input, Row, Space, Typography} from "antd";
import PlanService, {Plan} from "../../services/PlanService";
import TokenService from "../../services/TokenService";
import {useNavigate} from "react-router-dom";
import {HOME_PAGE_ROUTE} from "../../routes/route-path";
import PaymentService from "../../services/PaymentService";
import {numberWithCommas, openErrorNotification, openSuccessNotificationByMessage} from "../../services/UtilFunctions";
import DiscountCodeService from "../../services/DiscountCodeService";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import './buy-plan.css'
import Plyr from "plyr-react";
import UserPlanService from "../../services/UserPlanService";
import {paymentFaqQuestions} from "../../constans/faq-questions";
const {Panel} = Collapse;
const {Text} = Typography;
const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";
const baseVideoUrl = process.env.REACT_APP_BASE_VIDEO_URL || "";


export const BuyPlan: React.FC = () => {

    const [plans, setPlans] = useState<Plan[]>([]);
    const navigate = useNavigate();
    const [activePlan, setActivePlan] = useState<boolean>(false);

    useEffect(() => {
        UserPlanService.checkActivePlan().then(value => {
            setActivePlan(value);
        })
        loadPlans();
    }, [])

    const loadPlans = () => {
        PlanService.getPlans({
            pageNumber: 0,
            pageSize: 100,
            sort: "ASC",
            sortKey: "days"
        }).then(value => setPlans(value.content))
    }

    const submitOrder = (planId: number, discountCode: string, setLoading: any) => {
        setLoading(true);
        PaymentService.paymentRequest({plans: [{planId, discountCode}]}).then(redirectUrl => {
            setLoading(false);
            if (redirectUrl !== "OK") {
                window.location.replace(redirectUrl);
            } else {
                navigate(HOME_PAGE_ROUTE);
                openSuccessNotificationByMessage("سفارش شما با موفقیت ثبت شد.");
            }
        }).catch(reason => {
            setLoading(false);
        });
    }

    return (
        <div className="container">
            <Wrapper1 style={{marginTop: "55px", padding: "15px 15px"}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>خرید اشتراک دانشگاهینو</div>
                <Divider style={{margin: '0', marginTop: '15px'}}/>
                <Row gutter={[16, 16]} align="top">

                    {/*<Col span={24} style={{marginTop:"20px"}}>*/}
                    {/*    <Alert style={{textAlign:'center'}} message={*/}
                    {/*        <>*/}
                    {/*            <p style={{fontSize:'1.2rem'}}><span style={{fontWeight:'bold'}}> توجه:</span> به علت درخواست های مکرر شما دانشجویان عزیز، جشنواره پایان ترم دانشگاهینو با تخفیف ۹۰ درصدی تمدید شد.</p>*/}
                    {/*            <p style={{fontSize:'1.1rem'}}>کاربرانی که اشتراک فعال دارند، می توانند با خرید اشتراک شش ماهه آن را رزرو نمایند.</p>*/}
                    {/*        </>*/}
                    {/*    } type="info" />*/}
                    {/*</Col>*/}

                    <Col xs={24} sm={24} md={24} lg={13} xl={14}>
                        <div className="custom-player" style={{marginTop:"20px"}}>
                            <Plyr
                                source={{
                                    type: "video",
                                    sources: [
                                        {
                                            src: baseVideoUrl + "/73a881a8-876c-41ec-b116-35d337ab5340/CH03-c.mp4",
                                            type: "video/mp4",
                                            size:1080
                                        }
                                    ]
                                }}
                                options={{
                                    autoplay: false
                                }}
                            >
                            </Plyr>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={11} xl={10}>
                        {
                            activePlan && <div style={{
                                width: "400px",
                                maxWidth: "90%",
                                margin: "20px auto",
                            }}>
                                <Alert style={{fontSize:'1rem', textAlign:'justify'}} message="شما هم اکنون اشتراک فعال دارید. با رزرو اشتراک جدید، پس از اتمام اشتراک فعلی، اشتراک رزرو شما به صورت اتوماتیک فعال خواهد شد." type="success" />
                            </div>
                        }
                        <div>
                            {plans.map(plan => <PlanCard plan={plan} setPlans={setPlans} plans={plans}
                                                         submitOrder={submitOrder}/>)}
                        </div>
                    </Col>
                    <Col span={24}>
                        <Divider>سوالات متداول</Divider>
                        <Collapse
                            bordered={false}
                            expandIconPosition="right"
                            style={{backgroundColor: 'inherit'}}
                        >
                            {paymentFaqQuestions.length > 0 ? (
                                paymentFaqQuestions.map((item, index) => (
                                    <Panel
                                        header={<Text strong style={{fontSize:'16px'}}>{item.question}</Text>}
                                        key={index}
                                        style={{
                                            marginBottom: '10px',
                                            background: '#ffffff',
                                            border: '1px solid #d9d9d9',
                                            borderRadius: '8px',
                                            textAlign:'justify',
                                            textJustify:'inter-word'
                                        }}
                                    >
                                        <Text style={{fontSize:'16px'}}>{item.answer}</Text>
                                    </Panel>
                                ))
                            ) : null}
                        </Collapse>
                    </Col>
                </Row>
            </Wrapper1>
        </div>
    );

}

interface PlanCardProps {
    plan: Plan,
    setPlans: any,
    plans: Plan[],
    submitOrder: any
}

const PlanCard = ({plan, setPlans, plans, submitOrder}: PlanCardProps) => {

    const [loading, setLoading] = useState<boolean>(false);

    return (
        <div
            className="payment-plan-card"
            style={{
                width: "400px",
                maxWidth: "90%",
                margin: "20px auto",
                boxShadow: "0px 0px 2px 0px rgb(48 48 48 / 38%)",
                borderRadius: "10px",
                padding: "15px"
            }}>
            <Row gutter={[16, 16]} align="middle" justify="space-between">
                <Col style={{textAlign: "right", fontWeight:'bold'}} sm={12} xs={24}><span style={{fontSize: "14px"}}>
                    <img
                        src={baseNonVideoUrl + plan.coverUrl}
                        style={{width: "40px", borderRadius: "10px", verticalAlign: 'middle', marginLeft: '10px'}}
                    />
                    {plan.title}
                </span></Col>
                <Col style={{textAlign: "left"}} sm={12} xs={24}>
                    <Space>
                        <Badge offset={[-20, -5]} style={{textDecoration: 'line-through'}}
                               count={
                                   plan.price === plan.finalPrice ? null : numberWithCommas(plan.price) + ' تومان'
                               }
                        >
                            <Button type="default">{numberWithCommas(plan.finalPrice) + ' تومان'}</Button>
                        </Badge>
                        <Button type="primary" loading={loading} onClick={event => {
                            if (!TokenService.isLogin()) {
                                openErrorNotification("برای ثبت سفارش ثبت‌نام کنید یا وارد حساب کاربری خود شوید.");
                                return;
                            }
                            submitOrder(plan.id, plan.discountCode, setLoading);
                        }}>خرید</Button>
                    </Space>
                </Col>
            </Row>
            <Collapse style={{marginTop: "20px"}} className="discount-code" size="small"
                      expandIcon={panelProps => <PlusOutlined style={{marginBottom: "5px"}}/>} ghost items={
                [
                    {
                        key: '1',
                        label: 'اعمال کد تخفیف',
                        children: <div>
                            <Form
                                // style={{minWidth:"180px"}}
                                layout="inline"
                                size="small"
                                onReset={event => {
                                    setPlans(plans.map(item => {
                                        if (item.id === plan.id && item.discountCode) {
                                            item.discountPercentage = item.discountPercentageWithoutCode;
                                            item.finalPrice = (item.price * (100 - item.discountPercentage)) / 100;
                                            item.discountCode = undefined;
                                            return item;
                                        } else return item;
                                    }))
                                }
                                }
                                onFinish={(values) => {
                                    if (!TokenService.isLogin()) {
                                        openErrorNotification("برای ثبت سفارش ثبت‌نام کنید یا وارد حساب کاربری خود شوید.");
                                        return;
                                    }
                                    DiscountCodeService.checkDiscountCode(values.discountCode, plan.id).then(discountCode => {
                                        setPlans(plans.map(item => {
                                            if (item.id === plan.id && discountCode.code !== item.discountCode) {
                                                if (item.discountPercentageWithoutCode) {
                                                    item.discountPercentage = item.discountPercentageWithoutCode;
                                                    item.finalPrice = (item.price * (100 - item.discountPercentage)) / 100;
                                                    item.discountCode = undefined;
                                                }

                                                item.discountPercentageWithoutCode = item.discountPercentage;
                                                item.discountPercentage = Math.min((discountCode.discountPercentage), 100);
                                                item.finalPrice = (item.price * (100 - item.discountPercentage)) / 100;
                                                item.discountCode = discountCode.code;
                                                openSuccessNotificationByMessage("کد تخفبف با موفقیت اعمال شد.")
                                                return item;
                                            } else return item;
                                        }))
                                    })
                                }}
                            >
                                <Form.Item
                                    style={{width: "350px", maxWidth: "50%"}}
                                    name="discountCode"
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item>
                                    <Space>
                                        <Button shape="circle" icon={<DeleteOutlined/>} size="small" type="link"
                                                danger htmlType="reset"/>
                                        <Button size="small" type="primary" htmlType="submit">
                                            تایید
                                        </Button>

                                    </Space>
                                </Form.Item>
                            </Form>
                        </div>,
                    }
                ]
            }/>
        </div>
    );
}