// Please note that this gist follows the repo available at: https://github.com/delasign/react-redux-tutorial
import { configureStore } from "@reduxjs/toolkit";
// This is how you import a reducer, based on the prior export.
import categoryReducer from "./categorySlice";
import cartReducer from "./shoppingCartSlice";
import selectedCategoryReducer from "./selectedCategorySlice";

const store = configureStore({
    reducer: {
        // You are free to call the LHS what you like, but it must have a reducer on the RHS.
        categories: categoryReducer,
        cart: cartReducer,
        selectedCategory: selectedCategoryReducer,
    },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;