import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Button, Progress, Space, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import FileService, {ChunkUploadResponse} from "../../services/FileService";
import {openErrorNotification, openSuccessNotificationByMessage} from "../../services/UtilFunctions";

interface FileUploaderProps {
    onUploadComplete: (filePath: string) => void;
    onRemove: () => void;
    accept?: string;
    title?: string;
    maxSizeMb?: number;
}

const chunkSize = 20 * 1024 * 1024; // 20MB per chunk

const ChunkFileUploader = forwardRef((props: FileUploaderProps, ref) => {
    const {onUploadComplete, maxSizeMb, title, accept, onRemove} = props;
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<boolean>(false);
    const cancelUploadRef = useRef(false); // Ref to track cancel status


    // Expose the cancel function to the parent via ref
    useImperativeHandle(ref, () => ({
        handleCancelUpload: handleCancelUpload,
        reset: reset
    }));


    const handleCancelUpload = () => {
        cancelUploadRef.current = true;
        setUploading(false);
    }

    const reset = () => {
        setUploadProgress(0);
        setUploading(false);
        setUploadedFile(false);
        cancelUploadRef.current = false;
    }

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const tryToUploadChunk = async (retryCount: number, chunk: Blob, chunkIndex: number, totalChunks: number, fileName: string, fileId: string): Promise<ChunkUploadResponse> => {
        let response: ChunkUploadResponse;
        try {
            response = await FileService.chunkUpload(
                {
                    file: chunk,
                    chunkIndex: chunkIndex,
                    totalChunks: totalChunks,
                    fileName: fileName,
                    fileId: fileId
                });
        } catch (error) {
            openErrorNotification(`اخطار در آپلود فایل در حال تلاش برای آپلود. تعداد تلاش (${retryCount})`);
            if (retryCount < 5) {
                await sleep(10000);
                retryCount++;
                return await tryToUploadChunk(retryCount, chunk, chunkIndex, totalChunks, fileName, fileId);
            } else {
                throw error;
            }
        }
        return response;
    }

    // Function to handle chunked upload
    const uploadFileInChunks = async (file: File) => {
        const totalChunks = Math.ceil(file.size / chunkSize);
        let uploadedChunks = 0;
        const uuid = crypto.randomUUID();

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            if (cancelUploadRef.current) break;
            const start = chunkIndex * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);

            try {
                const response = await tryToUploadChunk(
                    1,
                    chunk,
                    chunkIndex,
                    totalChunks,
                    file.name,
                    uuid
                );

                if (response.completed) {
                    setUploading(false);
                    setUploadProgress(100);
                    setUploadedFile(true);

                    // Pass the filePath to the parent component
                    onUploadComplete(response.filePath);
                    return; // Exit loop since upload is complete
                }

                uploadedChunks++;
                setUploadProgress(Math.round((uploadedChunks / totalChunks) * 100));
            } catch (error) {
                openErrorNotification("اخطار در آپلود فایل");
                break;
            }
        }

        setUploading(false);
        if (!cancelUploadRef.current && uploadedChunks < totalChunks) {
            openErrorNotification("اخطار در آپلود فایل");
        }
    };
    const handleUpload = (options: any) => {
        const {file} = options;
        if (!(file instanceof File)) {
            openErrorNotification("اخطار در آپلود فایل");
            return;
        }
        setUploading(true);
        setUploadProgress(0);
        // Now you can safely pass the `file` to the upload function
        cancelUploadRef.current = false;
        uploadFileInChunks(file);
    };

    return (
        <div>
            <Upload
                accept={accept}
                customRequest={handleUpload} // Custom upload handler
                showUploadList={false} // Hide default upload list
                beforeUpload={(file) => {
                    if (maxSizeMb && file.size > maxSizeMb * 1024 * 1024) {
                        openErrorNotification("حداکثر سایز مجاز برای آپلود: " + `${maxSizeMb} mb`)
                        return Upload.LIST_IGNORE;
                    }
                    return true;
                }}
            >
                <Button icon={<UploadOutlined/>} loading={uploading} disabled={uploading}>
                    {uploading ? 'در حال آپلود ...' : (title ? title : "آپلود")}
                </Button>
            </Upload>
            {(uploadedFile || uploading) && (
                <div>
                    {
                        uploadedFile && (
                            <div style={{width: "25%", display: "inline-block", marginLeft: "10px"}}>
                                    <Button danger type={"link"} onClick={event => {
                                        onRemove();
                                        setUploadedFile(false);
                                    }}>حذف</Button>
                            </div>
                        )
                    }
                    {
                        uploading && (
                            <div style={{width: "25%", display: "inline-block", marginLeft: "10px"}}>
                                <Button danger type={"link"} onClick={event => {
                                    handleCancelUpload()
                                }}>لغو</Button>
                            </div>
                        )
                    }
                    <div style={{width: "70%", display: "inline-block"}}>
                        <Progress
                            percent={uploadProgress}
                            status={uploadProgress === 100 ? 'success' : 'active'}
                            style={{marginTop: '20px'}}
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

export default ChunkFileUploader;