import axios from "axios";
import {genericApiCall, Page, PageQueryParams} from "./UtilFunctions";
import TokenService from "./TokenService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface Education {
    id ?: number;
    university:string;
    degree:Degree;
    startYear:number;
    endYear:number;
    description:string;
}

export enum Degree {
    ASSOCIATE = 'ASSOCIATE',
    BACHELOR = 'BACHELOR',
    MASTER = 'MASTER',
    MASTER_STUDENT='MASTER_STUDENT',
    DOCTORAL= 'DOCTORAL',
    DOCTORAL_STUDENT= 'DOCTORAL_STUDENT'
}

export enum DegreeTitle{
    ASSOCIATE = 'کاردانی',
    BACHELOR = 'کارشناسی',
    MASTER = 'فارغ التحصیل کارشناسی ارشد',
    MASTER_STUDENT = 'دانشجوی کارشناسی ارشد',
    DOCTORAL = 'فارغ التحصیل دکتری',
    DOCTORAL_STUDENT = 'دانشجوی دکتری'
}

export enum DegreeWeight {
    ASSOCIATE = 1,
    BACHELOR = 2,
    MASTER_STUDENT = 3,
    MASTER = 4,
    DOCTORAL_STUDENT = 5,
    DOCTORAL= 6
}

class EducationService {

    private static instance: EducationService;

    private constructor() {
    }

    public static getInstance(): EducationService {
        if (!EducationService.instance) {
            EducationService.instance = new EducationService();
        }
        return EducationService.instance;
    }



    async add(userId:number, input:Education): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/educations/users/" + userId, input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async selfAdd(input:Education): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/educations/self", input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getPage(userId:number, params : any): Promise<Page<Education>> {
        return await genericApiCall<Page<Education>>(async () => {
            const response = await axios.get<Page<Education>>(baseUrl + "/api/v1/educations/users/" + userId, {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async delete(id:number | undefined): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/educations/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async selfDelete(id:number | undefined): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/educations/self/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async selfUpdate(id:number | undefined, input:Education): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/educations/self/" + id, input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    // async getSelfProfile(): Promise<User> {
    //     return await genericApiCall<User>(async () => {
    //         const response = await axios.get<User>(baseUrl + "/api/v1/users/self", {
    //             headers: {
    //                 'Authorization': "Bearer " + await TokenService.getToken()
    //             }
    //         });
    //         return response.data;
    //     });
    // }
    //


}

export default EducationService.getInstance();