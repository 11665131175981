import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {Divider, Table, Tag} from "antd";
import {SECOND_COLOR, THIRD_COLOR} from "../../../../constans/colors";
import {ColumnsType} from "antd/es/table";
import UserPlanService, {UserPlan} from "../../../../services/UserPlanService";
import {numberWithCommas, Page, toPersianDate, toPersianDateTime} from "../../../../services/UtilFunctions";
import {Role, RoleTitle} from "../../../../services/UserService";
import {UserPlanStatusColor, UserPlanStatusTitle} from "../../../../constans/enums";

const columns: ColumnsType<UserPlan> = [
    {
        title: 'اشتراک',
        dataIndex: 'plan',
        ellipsis:true,
        render: (_, { plan }) => (
            <a>{plan && plan.title}</a>
        ),
    },
    {
        title: 'هزینه پرداخت شده',
        dataIndex: 'finalPrice',
        ellipsis:true,
        render: (_, {finalPrice}) => <>{numberWithCommas(finalPrice)}</>
    },
    // {
    //     title: 'کد رهگیری',
    //     dataIndex: 'shaparakRefId',
    //     ellipsis:true,
    // },
    {
        title: 'تاریخ شروع',
        dataIndex: 'startDate',
        ellipsis:true,
        render: (_, { startDate }) => (
            <>{toPersianDate(startDate)}</>
        ),
    },
    {
        title: 'تاریخ اتمام',
        dataIndex: 'startDate',
        ellipsis:true,
        render: (_, { endDate }) => (
            <>{toPersianDate(endDate)}</>
        ),
    },
    {
        title: 'وضعیت',
        dataIndex: 'status',
        ellipsis:true,
        render: (_, { status }) => {
            return (
                <Tag color={UserPlanStatusColor[status]}>
                    {UserPlanStatusTitle[status]}
                </Tag>
            );
        },
    },
];

export const UserPlans: React.FC = () => {

    const [data, setData] = useState<Page<UserPlan>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });

    useEffect(() => {
        UserPlanService.getPlansSelfPage(params).then(page => {
            setData(page)
        });
    }, [params]);

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    return (
        <Wrapper1 style={{margin: "2rem", marginTop: "0px", padding: "20px", color: THIRD_COLOR}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>اشتراک های من</div>
                <Divider style={{margin: '0', marginTop: '15px'}}/>
                <Table scroll={{x: true}} pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: 10,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
        </Wrapper1>
    );
}