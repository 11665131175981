import axios from "axios";
import {genericApiCall} from "./UtilFunctions";
import TokenService from "./TokenService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

class PaymentService {

    private static instance: PaymentService;

    private constructor() {
    }

    public static getInstance(): PaymentService {
        if (!PaymentService.instance) {
            PaymentService.instance = new PaymentService();
        }
        return PaymentService.instance;
    }

    async paymentRequest(input: any): Promise<string> {
        return await genericApiCall<string>(async () => {
            const response = await axios.post<string>(baseUrl + "/api/v1/payment/request", input,
                {
                    headers: {
                        'Authorization': "Bearer " + await TokenService.getToken()
                    }
                });
            return response.data;
        });
    }

    async paymentVerify(authority: string): Promise<string> {
        return await genericApiCall<string>(async () => {
            const response = await axios.post<string>(baseUrl + "/api/v1/payment/verify/" + authority, null,
                {
                    headers: {
                        'Authorization': "Bearer " + await TokenService.getToken()
                    }
                });
            return response.data;
        });
    }

}

export default PaymentService.getInstance();