import React from "react";
import backgroundImage1 from '../../assets/images/about-us-background-1.png';
import backgroundImage2 from '../../assets/images/about-us-background-2.png';
import {Col, Row} from "antd";
import {SECOND_COLOR} from "../../constans/colors";

export const AboutUs = () => {

    return (
        <div>
            <div style={{
                height: "696px",
                backgroundImage: `url(${backgroundImage1})`,
                backgroundSize: "cover"
            }}>
                <div style={{padding:"0px 25px"}}>
                    <div className="container">
                        <Row gutter={[16, 16]} style={{height: "696px", textAlign: "center"}} align="middle">
                            <Col lg={0} md={24} sm={24} xs={24}>
                                <h1 style={{color: "#ffffff",
                                    fontSize: "50px",
                                    fontWeight: 900,}}>
                                    درباره ما
                                </h1>
                            </Col>
                            <Col lg={14} md={0} sm={0} xs={0}>
                                <div style={{position: "relative", bottom: "-130px"}}>
                                    <h1 style={{
                                        position: "absolute",
                                        bottom: "0px",
                                        right: "68px",
                                        color: "#ffffff",
                                        fontSize: "128px",
                                        fontWeight: 900,
                                        zIndex: 4
                                    }}>درباره ما
                                    </h1>
                                    <div style={{
                                        position: "absolute",
                                        bottom: "25px",
                                        width: "545px",
                                        height: "80px",
                                        backgroundColor: "#2F80ED",
                                        zIndex: 1
                                    }}/>
                                    <div style={{
                                        position: "absolute",
                                        bottom: "100px",
                                        right: "-50px",
                                        width: "131px",
                                        height: "131px",
                                        backgroundColor: "#2D9CDB",
                                        zIndex: 2
                                    }}/>
                                    <div style={{
                                        position: "absolute",
                                        bottom: "85px",
                                        right: "-25px",
                                        width: "50px",
                                        height: "50px",
                                        borderTopRightRadius: "100%",
                                        backgroundColor: SECOND_COLOR,
                                        zIndex: 3
                                    }}/>
                                </div>
                            </Col>
                            <Col lg={10} md={24} sm={24} xs={24}>
                                <p style={{
                                    color: "#ffffff",
                                    fontSize: "1.5rem",
                                    backgroundColor: "rgba(0,0,0,0.5)",
                                    padding: "48px 32px",
                                    textAlign: "right",
                                    margin:0
                                }}>
                                    دانشگاهینو، نخستین پلتفرم جامع VOD آموزشی ایران، با تلاش و پشتکار جمعی از دانشجویان دکتری دانشگاه صنعتی امیرکبیر (پلی تکنیک تهران) پا به عرصه وجود گذاشت.
<br/>
                                    ما در دانشگاهینو بر آن شدیم تا با ارائه دسترسی نامحدود به تمامی ویدئوهای آموزشی در قالب یک اشتراک واحد، انقلابی در دنیای آموزش مجازی رقم بزنیم.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div style={{
                backgroundColor: "rgba(0, 0, 0, 0.70)",

            }}>
                <div style={{padding:"0px 20px"}}>
                    <div className="container" style={{position:"relative"}}>
                        <div style={{
                            textAlign: "right",
                            color: "#ffffff",
                            padding:"40px 0px",
                            fontSize:"1.5rem"
                        }}>
                            <p style={{margin:0}}>
                                در حال حاضر، تمرکز اصلی ما بر ارائه دروس جامع و تخصصی در رشته‌های علوم پایه است. با این حال، با اتکا به عنایت پروردگار قادر و تعهد و یاری شما دانشجویان عزیز، امیدواریم در آینده‌ای نزدیک افق‌های تازه‌ای در آموزش تمامی رشته‌های دانشگاهی بگشاییم.
                            </p>
                        </div>
                        <div style={{
                            position: "absolute",
                            bottom: "-15px",
                            right: "-15px",
                            width: "50px",
                            height: "50px",
                            borderBottomRightRadius: "100%",
                            backgroundColor: SECOND_COLOR,
                            zIndex: 3
                        }}/>
                        <div style={{
                            position: "absolute",
                            top: "-15px",
                            left: "-20px",
                            width: "50px",
                            height: "50px",
                            borderTopLeftRadius: "100%",
                            backgroundColor: "#56CCF2",
                            zIndex: 3
                        }}/>
                    </div>
                </div>
            </div>
            <div className="container">
                <p style={{color:"#333333", fontSize:"1.5rem", marginTop:"70px"}}>
                    بر خلاف بسیاری از پلتفرم‌های مشابه، دانشگاهینو به هیچ نهاد یا سازمانی وابسته نیست و تنها با اتکا به حمایت و همراهی شما دانشجویان عزیز اداره می‌شود.
                </p>
                <p style={{color:"#333", fontWeight:"bold", fontSize:"1.9rem", textAlign:"center", marginTop:"80px"}}>قصد همکاری داریم</p>
                <p style={{color:"#828282", fontSize:"1.9rem", textAlign:"center"}}>با فارغ التحصیلان  و دانشجویان مقطع دکتری از دانشگاه‌های سراسر ایران</p>
            </div>
            <div style={{
                backgroundImage: `url(${backgroundImage2})`,
                backgroundBlendMode:"multiply",
                backgroundColor:"rgba(0, 0, 0, 0.50)",
                backgroundSize: "cover"
            }}>
                <div className="container">
                    <p style={{
                        textAlign: "right",
                        color: "#ffffff",
                        padding:"40px 0px",
                        fontSize:"1.5rem",
                        margin:0
                    }}>
                        ما در دانشگاهینو عمیقاً به قدرت دانش و آموزش ایمان داریم و می‌دانیم که با هم می‌توانیم مسیر پیشرفت و تعالی را در پیش بگیریم.
<br/>
                        <br/>
                        به دانشگاهینو بپیوندید و در دنیای بی‌پایان علم و دانش غرق شوید!

                    </p>
                </div>
            </div>
        </div>
    );

}