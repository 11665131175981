import React from "react";
import {SECOND_COLOR} from "../../constans/colors";
import './cooperationFormSticky.css';
import {DoubleRightOutlined} from "@ant-design/icons";
import {COOPERATION_FORM_ROUTE} from "../../routes/route-path";

export const CooperationFormSticky = () => {

    return (
        <span onClick={event => document.location.href = COOPERATION_FORM_ROUTE} className="cooperation-form-sticky" style={{fontSize:"20px", position:'fixed', bottom:35, left:28, zIndex:100, backgroundColor:SECOND_COLOR, color:'white', fontWeight:'bold', padding:'0px 20px', borderRadius:'30px'}}>
            <DoubleRightOutlined style={{verticalAlign:'middle', marginLeft:'5px'}} />
            <span>مدرس شو</span>
        </span>
    );

}