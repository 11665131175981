import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Divider, Modal, Table} from "antd";
import type {ColumnsType} from 'antd/es/table';
import {Category} from "../../../../services/CategoryService";
import {TopCoursesCreate} from "./TopCoursesCreate";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import TopCoursesService, {TopCourses} from "../../../../services/TopCoursesService";
import {Page} from "../../../../services/UtilFunctions";

interface DataType extends Category {
    key: React.Key;
    parent?: string;
}

export const TopCoursesContainer: React.FC = () => {

    const [data, setData] = useState<Page<TopCourses>>();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [deleteModal, deleteModalContextHolder] = Modal.useModal();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });

    const columns: ColumnsType<TopCourses> = [
        {
            title: 'دوره',
            dataIndex: ['course', 'title'],
            width:240
        },
        {
            title: 'مدرس',
            dataIndex: 'course',
            width:150,
            render: (_, {course}) => (
                <>
                    {course && course.teacher && course.teacher.firstName + " " + course.teacher.lastName}
                </>
            )
        },
        {
            title: 'دسته بندی',
            dataIndex: ['category', 'title'],
            width:150
        },
        {
            title: 'الویت',
            dataIndex: 'priorityNumber',
            width:70
        },
        {
            width: 70,
            render: (_, {id}) => (
                <>
                    <a onClick={() => {
                        if (id) {
                            deleteModal.confirm({
                                title: 'آیا می خواهید این دوره برتر را حذف کنید؟',
                                icon: <ExclamationCircleOutlined/>,
                                okText: 'بله',
                                cancelText: 'انصراف',
                                okButtonProps: {danger: true},
                                onOk: () => {
                                    TopCoursesService.delete(id).then(value => {
                                        loadTopCourses();
                                    })
                                }
                            })
                        }
                    }}>حذف</a>
                </>
            )
        }
    ];

    useEffect(() => {
        loadTopCourses();
    }, [showCreateModal, params]);

    const loadTopCourses = () => {
        TopCoursesService.getAdminTopCoursesPage(params).then(topCourses => {
            setData(topCourses)
        });
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>مدیریت دوره‌های برتر</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Button style={{marginTop: "20px"}} type="primary" onClick={() => setShowCreateModal(true)}>
                <span style={{marginLeft: "5px"}}><FontAwesomeIcon icon={faPlus}/></span>
                <span>افزودن دوره برتر</span>
            </Button>
            <Table
                className="admin-course-table"
                pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: params.pageSize,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }}
                style={{marginTop: "20px"}}
                columns={columns}
                dataSource={data?.content}
                size="middle"
                scroll={{x:680}}
            />
            <div>
                {
                    (() => {
                        return (showCreateModal ?
                            <TopCoursesCreate show={showCreateModal} setShow={setShowCreateModal}/> : null)
                    })()
                }
            </div>
            {deleteModalContextHolder}
        </Wrapper1>
    );

}