import React, {useEffect, useState} from "react";
import {Col, Pagination, Row} from "antd";
import {Wrapper1} from "../wrapper/Wrapper1";
import {CourseCard} from "../course/CourseCard";
import {Page} from "../../services/UtilFunctions";
import CourseService, {Course} from "../../services/CourseService";
import {useParams, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setSelectedCategory} from "../../redux/selectedCategorySlice";

export const TeacherCoursesContainer = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState<Page<Course>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
    });
    const {id} = useParams();

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    useEffect(() => {
        dispatch(setSelectedCategory(0));
        if (id) {
            CourseService.searchCourses({...params, teacherId : id}).then(page => {
                setData(page);
            });
        }
    }, [params])
    return (
        <div className="container">
            <Wrapper1 style={{marginTop: "55px", padding: "15px 15px"}}>
                <Row gutter={[16, 16]} align="middle" justify="start"
                     style={{paddingBottom: "16px", paddingTop: "16px"}}>
                    {
                        data?.content.map(course => {
                            return (
                                <Col xl={8} md={12} sm={24} xs={24} style={{textAlign: 'center'}}>
                                    <CourseCard cardProperty={{
                                        id: course.id,
                                        title: course.title,
                                        sessionCount: course.sessionsCounts,
                                        sessionHour:course.sessionsMinutes,
                                        img: course.coverUrl,
                                        status:course.courseStatus,
                                        tag:course.courseTag
                                    }}/>
                                </Col>
                            );
                        })
                    }

                </Row>
                <Row gutter={[16, 16]} align="middle" justify="space-evenly">
                    <Pagination current={params.pageNumber + 1} pageSize={params.pageSize} total={data?.totalElements}
                                onChange={handleSelectPagination}/>
                </Row>
            </Wrapper1>

        </div>
    );

}