import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {User} from "./UserService";
import {Degree} from "./EducationService";
import {FieldOfStudy} from "../constans/enums";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface CooperationForm {
    firstName: string;
    lastName: string;
    mobile: string;
    email: string;
    university: string;
    degree: Degree;
    fieldOfStudy: FieldOfStudy;
    description: string;
    fileUrl: string;
    file: any;
    teachingExperience: boolean;
}

class CooperationFormService {

    private static instance: CooperationFormService;

    private constructor() {}

    public static getInstance(): CooperationFormService {
        if (!CooperationFormService.instance) {
            CooperationFormService.instance = new CooperationFormService();
        }
        return CooperationFormService.instance;
    }

    async getPage(params : any): Promise<Page<CooperationForm>> {
        return await genericApiCall<Page<CooperationForm>>(async () => {
            const response = await axios.get<Page<CooperationForm>>(baseUrl + "/api/v1/cooperation-form", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async add(input:CooperationForm): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/public/v1/cooperation-form", input, {
                headers:{
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        });
    }

}

export default CooperationFormService.getInstance();