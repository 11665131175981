import axios from "axios";
import {genericApiCall} from "./UtilFunctions";
import TokenService from "./TokenService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

class DateService {

    private static instance: DateService;

    private constructor() {
    }

    public static getInstance(): DateService {
        if (!DateService.instance) {
            DateService.instance = new DateService();
        }
        return DateService.instance;
    }

    async getDate(): Promise<Date> {
        return await genericApiCall<Date>(async () => {
            const response = await axios.get<Date>(baseUrl + "/public/v1/date");
            return response.data;
        });
    }

}

export default DateService.getInstance();