import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CategoryService, {Category} from "../services/CategoryService";

const initialState: Category[] = [];

export const categorySlice = createSlice({
    name: "categoriesStateAction",
    initialState: initialState,
    reducers: {
        setCategory: (state, action: PayloadAction<Category[]>) => {
            return  action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCategory } = categorySlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default categorySlice.reducer;
