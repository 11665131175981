import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Modal, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {openSuccessNotificationByMessage, Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import CourseCommentService, {CourseComment} from "../../../../services/CourseCommentService";
import {CourseCommentSubmit} from "./CourseCommentSubmit";
import {COURSE_ROUTE} from "../../../../routes/route-path";
import {ExclamationCircleOutlined} from "@ant-design/icons";

export const CourseCommentContainer: React.FC = () => {

    const [showSubmitComment, setShowSubmitComment] = useState<boolean>(false)
    const [replyCommentId, setReplyCommentId] = useState<number | null>(null);
    const [courseId, setCourseId] = useState<number | null>(null);
    const [data, setData] = useState<Page<CourseComment>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey:"createdDate",
        sort:"DESC"
    });
    const [show, setShow] = useState<boolean>(false);
    const [selectedDescription, setSelectedDescription] = useState<string>();
    const [deleteModal, deleteModalContextHolder] = Modal.useModal();

    useEffect(() => {
        loadCourseComments();
    }, [params]);

    const loadCourseComments = () => {
        CourseCommentService.getCommentsForAdmin(params).then(page => {
            setData(page)
        });
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<CourseComment> = [
        {
            title: 'عنوان دوره',
            dataIndex: 'courseTitle',
            width: 200,
            render: (_, { courseTitle , courseId}) => (
                <a target="_blank" href={COURSE_ROUTE.replace(":id", courseId + "")}>{courseTitle}</a>
            ),
        },
        {
            title: 'تاریخ',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, { createdDate }) => (
                <>{toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'پاسخ نظر',
            dataIndex: ['replyComment', 'description'],
            ellipsis:true
        },
        {
            title: '',
            width: 100,
            render: (_, { replyComment }) => (
                <>{replyComment && 'پاسخ'}</>
            ),
        },
        {
            title: 'توضیحات',
            dataIndex: 'description',
            width: 100,
            render: (_, {description}) => (
                <a onClick={event => {
                   setSelectedDescription(description);
                   setShow(true);
                }}>مشاهده</a>
            ),
        },
        {
            title: 'کاربر',
            width: 150,
            render: (_, course) => (
                <>
                    {course.firstName + " " + course.lastName}
                </>
            )
        },
        {
            title: 'وضعیت تایید',
            dataIndex: 'approved',
            width: 150,
            render: (_, {approved}) => (
                <>
                    {approved ? "تایید" : "رد"}
                </>
            ),
        },
        {
            dataIndex: 'id',
            width: 250,
            render: (_, courseComment) => (
                <Space size="large">
                    <a
                        style={{color:"red"}}
                        onClick={() => {
                            deleteModal.confirm({
                                title: 'آیا می خواهید این نظر را حذف کنید؟',
                                icon: <ExclamationCircleOutlined />,
                                okText: 'بله',
                                cancelText: 'انصراف',
                                okButtonProps:{danger:true},
                                onOk: () => {
                                    CourseCommentService.deleteComment(courseComment.id).then(() => {
                                        loadCourseComments();
                                        openSuccessNotificationByMessage("نظر با موفقیت حذف شد.");
                                    });
                                }
                            })
                        }}> حذف </a>
                    <a onClick={() => {
                        CourseCommentService.approveComment(courseComment.id, !courseComment.approved).then(value => loadCourseComments())
                    }}>تایید یا رد</a>
                    {
                        !courseComment.replyComment && <a onClick={() => {
                            setShowSubmitComment(true);
                            setCourseId(courseComment.courseId);
                            setReplyCommentId(courseComment.id);
                        }}>پاسخ دادن</a>
                    }
                </Space>
            )
        }
    ];

    return (
        <Wrapper1 style={{margin: "15px", marginTop:"0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>مدیریت نظرات دوره‌ها</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Table className="admin-course-table" scroll={{ x: 1200 }} pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: params.pageSize,
                total: data?.totalElements,
                onChange: handleSelectPagination
            }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            <Modal
                title="توضیحات"
                centered
                open={show}
                onOk={() => {}}
                onCancel={() => {
                    setShow(false);
                }}
                footer={[]}
                bodyStyle={{maxHeight:"700px", overflow: 'auto'}}
                width={720}
            >
                <p style={{textAlign:"justify", whiteSpace: 'pre-line'}}>{selectedDescription}</p>
            </Modal>
            <CourseCommentSubmit show={showSubmitComment} courseId={courseId} setCourseId={setCourseId} setShow={setShowSubmitComment} replyCommentId={replyCommentId} setReplyCommentId={setReplyCommentId}/>
            {deleteModalContextHolder}
        </Wrapper1>
    );
}