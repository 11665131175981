import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {DiscountCode} from "./DiscountCodeService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface Notification {
    id: number;
    title: string;
    description: string;
    createdDate: Date;
}

class NotificationService {

    private static instance: NotificationService;

    private constructor() {
    }

    public static getInstance(): NotificationService {
        if (!NotificationService.instance) {
            NotificationService.instance = new NotificationService();
        }
        return NotificationService.instance;
    }

    async add(input:Notification): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/notifications", input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async seen(id:number): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/notifications/seen/" + id, null, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async delete(id:number): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/notifications/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getPage(params : any): Promise<Page<Notification>> {
        return await genericApiCall<Page<Notification>>(async () => {
            const response = await axios.get<Page<Notification>>(baseUrl + "/api/v1/notifications", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getUnreadMessages(): Promise<Notification[]> {
        return await genericApiCall<Notification[]>(async () => {
            const response = await axios.get<Notification[]>(baseUrl + "/api/v1/notifications/unread/self", {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default NotificationService.getInstance();