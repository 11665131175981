import React, {useState} from "react";
import {Footer} from "../../components/new-footer";
import {SearchResult} from "../../components/search-result";
import {HeaderWrapper} from "../../components/header-wrapper";
import {CustomBreadcrumb} from "../../components/common/CustomBreadcrumb";

export const SearchResultPage =() => {

    const [pageName, setPageName] = useState<string>("");

    return(
        <>
            <HeaderWrapper/>
            <CustomBreadcrumb items={
                [
                    {
                        title: <a href="/">دانشگاهینو</a>,
                    },
                    {
                        title: pageName,
                    }
                ]
            }/>
            <SearchResult setPageName={setPageName}/>
            <Footer/>
        </>
    );
}