import React, {useEffect, useState} from "react";
import {InputNumber} from "antd";

interface Props {
    sendRequest : any;
    defaultValue:number;
}

export const CustomNumberInput = ({sendRequest, defaultValue} : Props) => {

    const [value, setValue] = useState<number | null>();

    useEffect(() => {
        const timer = setTimeout(() => {
            if (value !== undefined) sendRequest(value);
        }, 1100);

        return () => clearTimeout(timer);
    }, [value])

    return (
        <InputNumber defaultValue={defaultValue} style={{width:"100%"}} min={1} max={50} onChange={val => setValue(val)} value={value} />
    );

}