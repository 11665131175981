import React, {useState} from "react";
import {Button, Form, Input, InputNumber, Modal, Select, Upload} from "antd";
import UserService, {Role, RoleTitle} from "../../../../services/UserService";
import TextArea from "antd/es/input/TextArea";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import {openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";
import Editor from "../../../common/Editor";

const { Option } = Select;
interface Props {
    show:boolean
    setShow(b : boolean):void
}

export const UserCreate =({show, setShow} : Props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [ file, setFile ] = useState<RcFile | null>();

    const onFinish = (values: any) => {
        setLoading(true);
        values = {...values, photo:file}
        UserService.addUser(values).then(response => {
            form.resetFields();
            setFile(null);
            setShow(false);
            setLoading(false);
            openSuccessNotificationByMessage("کاربر با موفقیت اضافه شد.", 5);
        }).catch(reason => {
            setLoading(false);
        });
    }

    return(
        <Modal
            title="افزودن کاربر جدید"
            centered
            width={720}
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setFile(null);
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                style={{ maxWidth:"100%" }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    label="نام کاربری"
                    name="username"
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="نام کاربری"/>
                </Form.Item>
                <Form.Item
                    label="رمز عبور"
                    name="password"
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="رمز عبور"/>
                </Form.Item>
                <Form.Item
                    label="نام"
                    name="firstName"
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="نام"/>
                </Form.Item>
                <Form.Item
                    label="نام خانوادگی"
                    name="lastName"
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="نام خانوادگی"/>
                </Form.Item>
                <Form.Item
                    label="شماره همراه"
                    name="mobile"
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="شماره همراه"/>
                </Form.Item>

                <Form.Item label="نقش کاربر" name="roles" rules={[{ required: true, message: 'این فیلد الزامی است.'}]}>
                    <Select
                        placeholder="نقش کاربر"
                        mode="multiple"
                    >
                        <Option value={Role.ROLE_USER}>{RoleTitle[Role.ROLE_USER]}</Option>
                        <Option value={Role.ROLE_TEACHER}>{RoleTitle[Role.ROLE_TEACHER]}</Option>
                        <Option value={Role.ROLE_ADMIN}>{RoleTitle[Role.ROLE_ADMIN]}</Option>
                        <Option value={Role.ROLE_TEACHER_REPORT}>{RoleTitle[Role.ROLE_TEACHER_REPORT]}</Option>
                        <Option value={Role.ROLE_TEACHER_PAYMENT}>{RoleTitle[Role.ROLE_TEACHER_PAYMENT]}</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="توضیحات" name="description">
                    <Editor onChange={(value : string)=>{
                        form.setFieldValue("description", value);
                    }} style={{height:"400px", marginBottom:"70px"}}/>
                </Form.Item>
                <Form.Item label="پروفایل">
                    <Upload
                        accept=".jpeg, .jpg, .png"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={(file) => {
                            setFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined />}>انتخاب عکس پروفایل</Button>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        افزودن
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}