import React from "react";
import {Property} from "csstype";

interface Props {
    title?: string;
    content?: string;
    unit?: string;
    backgroundColor: Property.BackgroundColor;
    style ?: React.CSSProperties;
}

export const InLineReportCard = ({title, content, backgroundColor, unit, style={}}: Props) => {

    return (
        <div style={{
            borderRadius: "10px",
            padding: "4px 10px",
            backgroundColor: backgroundColor,
            textAlign: "center",
            display:'inline-block',
            ...style
        }}>
            <span style={{color: "white", fontSize: "20px", fontWeight: "bold"}}>
                {title + ": "}
            </span>
            <span style={{color: "white", fontSize: "20px", fontWeight: "bold"}}>
                {content} <span style={{fontSize: "16px"}}>{unit}</span>
            </span>
        </div>
    );

}