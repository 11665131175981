import React, {useEffect, useState} from "react";
import {Button, Form, Input, InputNumber, Modal, Select, Switch, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import {
    openErrorNotification,
    openSuccessNotification,
    openSuccessNotificationByMessage
} from "../../../../services/UtilFunctions";
import CategoryService, {Category} from "../../../../services/CategoryService";
import PlanService, {Plan} from "../../../../services/PlanService";
import DiscountCodeService from "../../../../services/DiscountCodeService";
import UserService, {User} from "../../../../services/UserService";
import NotificationService from "../../../../services/NotificationService";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;
interface Props {
    show:boolean
    setShow(b : boolean):void
}

export const NotificationCreate =({show, setShow} : Props) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        setLoading(true);
        NotificationService.add(values).then(response => {
            form.resetFields();
            setShow(false);
            setLoading(false);
            openSuccessNotification();
        }).catch(reason => {setLoading(false);});
    }

    useEffect(() => {
    }, [ ]);

    return(
        <Modal
            title="افزودن اعلان جدید"
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
            width={800}
        >
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 800 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="title"
                    label="عنوان"
                >
                    <Input />
                </Form.Item>
                <Form.Item name="description" label="توضیحات"><TextArea rows={4} /></Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        افزودن
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}