import React from "react";
import {Card, Col, Divider, Row} from "antd";
import {Wrapper1} from "../../wrapper/Wrapper1";
import {Course} from "../../../services/CourseService";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper/modules';
import {CourseCard} from "../CourseCard";
import {Category} from "../../../services/CategoryService";
import {useNavigate} from "react-router-dom";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

const colStyle: any = {textAlign: 'center'};

interface Props {
    title ?:string;
    courses ?: Course[];
    categoryId ?: number;
    moreUrl:string;
    color:string;
    category:Category;
}

export const CoursesPreviewCategoryBasedWrapper = ({category, title, courses, categoryId, moreUrl, color}: Props) => {

    const navigate = useNavigate();

    return (
        <Wrapper1 style={{marginTop: "35px", fontWeight:'bold'}} topBorderColor={color}>
            <Row align="middle" justify="space-between"
                 style={{color: color, fontSize: "1.2rem", padding: "10px 15px"}}>
                <Col span={12} style={{textAlign: "right", fontSize: "inherit"}}>
                    <span>{title}</span>
                </Col>
                <Col span={12} style={{textAlign: "left", fontSize: "inherit"}}>
                    <span><a href={moreUrl} style={{color: "inherit"}}>مشاهده بیشتر</a></span>
                </Col>
                <Divider style={{margin: '0', marginTop: '15px', borderColor:color, opacity:'0.3'}}/>
            </Row>
            <div style={{padding: "0px 15px", paddingBottom: "5px"}}>
                <Swiper
                    breakpoints={{
                        200: {
                            slidesPerView: 1.4,
                            spaceBetween: 10,
                        },
                        640: {
                            slidesPerView: 2.5,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 3.5,
                            spaceBetween: 10,
                        },
                        1024: {
                            slidesPerView: 3.5,
                            spaceBetween: 10,
                        },
                    }}
                    modules={[Navigation]}
                    className="mySwiper"
                    navigation={true}
                >
                    {
                        courses && courses.map(course => {
                            return (
                                <SwiperSlide>
                                    <CourseCard cardProperty={{
                                        id: course.id,
                                        title: course.title,
                                        sessionCount: course.sessionsCounts,
                                        sessionHour: course.sessionsMinutes,
                                        img: course.coverUrl,
                                        fontSize:'0.95rem',
                                        status:course.courseStatus,
                                        tag:course.courseTag
                                    }}/>
                                </SwiperSlide>
                            );
                        })
                    }
                </Swiper>
            </div>
        </Wrapper1>

    );


}