import React, {useEffect, useState} from "react";
import {Col, Divider, Row, Skeleton} from "antd";
import {Wrapper1} from "../../wrapper/Wrapper1";
import CourseService, {Course} from "../../../services/CourseService";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper/modules';
import {CourseCard} from "../CourseCard";
import CSS from "csstype";

const colStyle: any = {textAlign: 'center'};

interface Props {
    title?: string;
    color: string;
    defaultParams: any;
    moreUrl?: string;
    style ?: CSS.Properties;
    headerStyle ?: CSS.Properties;
    dividerStyle ?: CSS.Properties;
}

export const AutoLoadCoursesPreviewCarouselWrapper = ({title, defaultParams, color, headerStyle, dividerStyle = {margin: '0', marginTop: '15px', borderColor: color, opacity: '0.3'}, moreUrl, style = {}}: Props) => {

    const [params, setParams] = useState(defaultParams);
    const [data, setData] = useState<Course[]>([]);
    const [swiperRef, setSwiperRef] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        CourseService.searchCourses(params).then(page => {
            setData(page.content);
            setLoading(false);
        }).catch(reason => setLoading(false));
    }, [])

    return (

        (data && data.length !== 0) ?
            <Wrapper1 style={style} topBorderColor={color}>
                <Row align="middle" justify="space-between"
                     style={{color: color, fontSize: "1.2rem", padding: "10px 15px", fontWeight:'bold', ...headerStyle}}>
                    <Col span={12} style={{textAlign: "right", fontSize: "inherit"}}>
                        <span>{title}</span>
                    </Col>
                    {moreUrl && <Col span={12} style={{textAlign: "left", fontSize: "inherit"}}>
                        <span><a href={moreUrl} style={{color: "inherit"}}>مشاهده بیشتر</a></span>
                    </Col>}
                    <Divider style={dividerStyle}/>
                </Row>
                <div style={{padding: "0px 15px", paddingBottom: "5px"}}>
                    <Skeleton loading={loading} style={{marginTop: "35px"}} title={false} active={true}
                              paragraph={{rows: 7, width: '100%'}}/>
                    {
                        !loading &&
                        <Swiper
                            breakpoints={{
                                200: {
                                    slidesPerView: 1.4,
                                    spaceBetween: 10,
                                },
                                640: {
                                    slidesPerView: 2.5,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 3.5,
                                    spaceBetween: 10,
                                },
                                1024: {
                                    slidesPerView: 3.5,
                                    spaceBetween: 10,
                                },
                            }}
                            modules={[Navigation]}
                            className="mySwiper"
                            onSwiper={setSwiperRef}
                            navigation={true}
                        >
                            {
                                data && data.map(course => {
                                    return (
                                        <SwiperSlide>
                                            <CourseCard cardProperty={{
                                                id: course.id,
                                                title: course.title,
                                                sessionCount: course.sessionsCounts,
                                                sessionHour: course.sessionsMinutes,
                                                img: course.coverUrl,
                                                fontSize:'0.95rem',
                                                status:course.courseStatus,
                                                tag:course.courseTag
                                            }}/>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    }
                </div>
            </Wrapper1>
            :<span/>
    );

}