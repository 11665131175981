import React from "react";
import {Modal} from "antd";
import Plyr from "plyr-react";

interface Props {
    show:boolean
    setShow(b : boolean):void
    videoUrl:string
}

export const LoginOrBuyPlanModal =({show, setShow, videoUrl} : Props) => {


    return(
        <Modal
            title={false}
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                setShow(false);
            }}
            width={720}
            footer={[]}
            closeIcon={null}
            className="login-or-by-plan-modal"
        >
            <div className="custom-player">
                <Plyr
                    source={{
                        type: "video",
                        sources: [
                            {
                                src: videoUrl,
                                type: "video/mp4"
                            }
                        ]
                    }}
                    options={{
                        autoplay: true
                    }}
                >
                </Plyr>
            </div>
        </Modal>
    );

}