import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Routes} from "./routes";
import {ConfigProvider} from "antd";
import {FIFTH_COLOR, FIRST_COLOR, THIRD_COLOR} from "./constans/colors";
import 'video-react/dist/video-react.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Provider} from "react-redux";
import store from "./redux/reduxStore";
import axios from "axios";
import './index.css';
import "react-quill/dist/quill.snow.css";
axios.interceptors.request.use(
    config => {
        config.headers['Accept-Language'] = 'fa'
        return config
    }
)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: FIRST_COLOR,
                    colorText: THIRD_COLOR,
                    colorTextHeading: FIFTH_COLOR
                },
            }}
        >
            <Routes/>
        </ConfigProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
