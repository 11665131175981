import React, {useEffect, useRef, useState} from "react";
import {Button, Form, Modal, Space, Table} from "antd";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {
    openErrorNotification,
    openSuccessNotificationByMessage,
    Page,
    toPersianDateTime
} from "../../../../services/UtilFunctions";
import TextArea from "antd/es/input/TextArea";
import FileService, {SavedFile} from "../../../../services/FileService";
import {ColumnsType} from "antd/es/table";
import ChunkFileUploader from "../../../common/ChunkFileUploader";

const baseUrl = process.env.REACT_APP_BASE_URL || "";
const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

export const SendFileContainer: React.FC = () => {

    const [data, setData] = useState<Page<SavedFile>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [show, setShow] = useState<boolean>(false);
    const [selectedDescription, setSelectedDescription] = useState<string>();
    const fileUploaderRef = useRef<any>(null);
    const [filePath, setFilePath] = useState<string | null>(null);
    const [submitFormLoading, setSubmitFormLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    useEffect(() => {
        loadData();
    }, [params]);

    const loadData = () => {
        FileService.getSavedTeacherFilesPageBySelf(params).then(page => {
            setData(page)
        });
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<SavedFile> = [
        {
            title: 'تاریخ ارسال',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'نام فایل',
            dataIndex: 'fileName',
            width: 200,
        },
        {
            title: 'توضیحات',
            dataIndex: 'description',
            width: 100,
            render: (_, {description}) => (
                description && <a onClick={event => {
                    setSelectedDescription(description);
                    setShow(true);
                }}>مشاهده</a>
            ),
        },
        {
            ellipsis: true,
            render: (_, {fileId, fileName}) => (
                fileId && fileName ?
                    <Space size="large">
                        <a href={baseNonVideoUrl + "/" + fileId + "/" + fileName}>دانلود</a>
                    </Space>
                    : null
            ),
        }

    ];

    const onFinish = (values: any) => {
        if (!filePath) {
            openErrorNotification("لطفا فایل را آپلود کنید.");
            return;
        }
        values.filePath = filePath;
        setSubmitFormLoading(true);
        FileService.uploadTeacherFiles(values).then(page => {
            openSuccessNotificationByMessage('فایل با موفقیت آپلود شد.');
            loadData();
            form.resetFields();
            if (fileUploaderRef.current) fileUploaderRef.current.reset();
            setFilePath(null);
        }).finally(() => setSubmitFormLoading(false));
    }

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <ChunkFileUploader ref={fileUploaderRef} title="انتخاب فایل" maxSizeMb={2000}
                               onUploadComplete={filePath => setFilePath(filePath)} onRemove={() => setFilePath(null)}/>
            <Form
                form={form}
                name="basic"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item name="description" rules={[{required: true, message: 'این فیلد الزامی است.'}]}
                           labelCol={{span: 24}} label="توضیحات"><TextArea name="description"
                    rows={4}/></Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit"
                            loading={submitFormLoading}>ارسال</Button>
                </Form.Item>
            </Form>
            <Table scroll={{x: 600}} pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: params.pageSize,
                total: data?.totalElements,
                onChange: handleSelectPagination
            }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            <Modal
                title="توضیحات"
                centered
                open={show}
                onOk={() => {
                }}
                onCancel={() => {
                    setShow(false);
                }}
                footer={[]}
                bodyStyle={{maxHeight: "700px", overflow: 'auto'}}
                width={720}
            >
                <p style={{textAlign: "justify", whiteSpace: 'pre-line'}}>{selectedDescription}</p>
            </Modal>
        </Wrapper1>
    );
}

