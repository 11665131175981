import React from "react";
import {Footer} from "../../components/new-footer";
import {HeaderWrapper} from "../../components/header-wrapper";
import {FAQ} from "../../components/faq";

export const FAQPage: React.FC =() => {

    return(
        <>
            <HeaderWrapper/>
            <FAQ/>
            <Footer/>
        </>
    );
}