import React, {useState} from "react";
import {Layout, MenuProps} from 'antd';
import {HOME_PAGE_ROUTE} from "../../routes/route-path";
import logo from "../../assets/images/logo1.png";
import {DashboardSideMenu} from "../../components/dashboard/DashboardSideMenu";
import {SEVENTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import {UserPlans} from "../../components/dashboard/user/plans/UserPlans";
import {UserProfileContainer} from "../../components/dashboard/user/profile/UserProfileContainer";
import {NotificationContainer} from "../../components/notification";

type MenuItem = Required<MenuProps>['items'][number];

const {Header, Footer, Sider, Content} = Layout;

const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 60,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: SEVENTH_COLOR,
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export const UserDashboardPage = () => {

    const [activeComponent, setActiveComponent] = useState<React.ReactNode>(<UserProfileContainer/>)

    const items: MenuItem[] = [
        getItem((<a onClick={() => setActiveComponent(<UserProfileContainer/>)}>پروفایل</a>), "key1", null),
        getItem((<a onClick={() => setActiveComponent(<UserPlans/>)}>اشتراک های من</a>), "key2", null),

    ]

    return (
        <Layout>
            <Layout style={{minHeight:"100vh"}}>
                <Header style={headerStyle}>
                    <a href={HOME_PAGE_ROUTE}><img src={logo} alt="logo" style={{
                        height: "100%",
                        marginRight: "-50px",
                        padding: "10px 16px",
                        float: "right"
                    }}/></a>
                    <span style={{color: THIRD_COLOR, float: "right", fontSize:"1rem"}}>دانشگاهینو / پنل کاربر</span>
                    <span style={{color: THIRD_COLOR, float: "left", marginLeft: "-30px", fontSize:"1rem"}}>
                        <a style={{color: THIRD_COLOR}} href={HOME_PAGE_ROUTE}>بازگشت</a>
                    </span>
                </Header>
                <div>
                    <DashboardSideMenu defaultSelectedKey="key1" items={items} style={{fontSize:"1rem",borderInlineEnd:"none"}} mode="horizontal"/>
                </div>
                <Content style={{marginTop: "2rem"}}>
                    {activeComponent}
                </Content>
            </Layout>
            <NotificationContainer/>
        </Layout>
    );
}