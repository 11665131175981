import React, {useEffect, useState} from "react";
import {Col, Layout, MenuProps} from 'antd';
import {HOME_PAGE_ROUTE} from "../../routes/route-path";
import logo from "../../assets/images/logo1.png";
import {DashboardSideMenu} from "../../components/dashboard/DashboardSideMenu";
import {FORTH_COLOR, SEVENTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import {toPersianDate} from "../../services/UtilFunctions";
import {TeacherProfileContainer} from "../../components/dashboard/teacher/profile/TeacherProfileContainer";
import {WatchReportContainer} from "../../components/dashboard/teacher/reports/WatchReportContainer";
import {TeacherPaymentContainer} from "../../components/dashboard/teacher/teacher-payment/TeacherPaymentContainer";
import {SendFileContainer} from "../../components/dashboard/teacher/send-file/SendFileContainer";
import UserService, {Role, User} from "../../services/UserService";
import {DollarOutlined, PieChartOutlined, UploadOutlined, UserOutlined} from "@ant-design/icons";

type MenuItem = Required<MenuProps>['items'][number];

const {Header, Footer, Sider, Content} = Layout;

const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 60,
    paddingInline: 50,
    marginBottom: '20px',
    lineHeight: '64px',
    backgroundColor: SEVENTH_COLOR,
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export const TeacherDashboardPage = () => {

    const [activeComponent, setActiveComponent] = useState<React.ReactNode>(<TeacherProfileContainer/>)
    const [profile, setProfile] = useState<User>();

    useEffect(() => {
        UserService.getSelfProfile().then(profile => {
            setProfile(profile);
        })
    }, [])

    const generateDashboardSideMenuByCheckingRoles = () => {
        let items: MenuItem[] = [
            getItem((<a onClick={() => setActiveComponent(<TeacherProfileContainer/>)}>پروفایل</a>), "key1",
                <UserOutlined/>),
            getItem((<a onClick={() => setActiveComponent(<SendFileContainer/>)}>ارسال فایل</a>), "key4",
                <UploadOutlined/>),
        ]
        if (profile && profile.roles && profile.roles.includes(Role.ROLE_TEACHER_REPORT)) {
            items.push(getItem((<a onClick={() => setActiveComponent(<WatchReportContainer/>)}>گزارشات</a>), "key2",
                <PieChartOutlined/>))
        }
        if (profile && profile.roles && profile.roles.includes(Role.ROLE_TEACHER_PAYMENT)) {
            items.push(getItem((<a onClick={() => setActiveComponent(<TeacherPaymentContainer/>)}>مالی</a>), "key3",
                <DollarOutlined/>))
        }
        return <DashboardSideMenu defaultSelectedKey="key1" items={items}
                                  style={{fontSize: "18px", borderInlineEnd: "none"}} mode="inline"/>;
    }

    return (
        <Layout>
            <Sider style={{
                textAlign: 'right',
                backgroundColor: FORTH_COLOR,
                padding: "35px 5px",
                fontSize: "30px",
                overflow: 'hidden',
            }} breakpoint={"md"} width={280} collapsedWidth={50}>
                {generateDashboardSideMenuByCheckingRoles()}
            </Sider>
            <Layout style={{minHeight: "100vh"}}>
                <Header style={headerStyle}>
                    <a href={HOME_PAGE_ROUTE}><img src={logo} alt="logo" style={{
                        height: "100%",
                        marginRight: "-50px",
                        padding: "10px 16px",
                        float: "right"
                    }}/></a>
                    <span style={{color: THIRD_COLOR, float: "right"}}>دانشگاهینو / پنل مدرس</span>
                    <Col xs={0} sm={24}>
                        <span style={{color: THIRD_COLOR, float: "left"}}>
                        {toPersianDate(new Date())}
                            <span style={{padding: "0px 5px"}}>|</span>
                        <a style={{color: THIRD_COLOR}} href={HOME_PAGE_ROUTE}>بازگشت</a>
                    </span>
                    </Col>
                </Header>
                <Content>
                    {activeComponent}
                </Content>
            </Layout>
        </Layout>
    );
}