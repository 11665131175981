import React, {useEffect, useState} from 'react';
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import WatchReportService, {WatchReport} from "../../services/WatchReportService";
import {COLORS} from "../../constans/colors";

interface Props {
    teacherId?:number;
    yearMonth?:string;
    fetchData():Promise<WatchReport[]>;
}

export const WatchChart = ({teacherId, yearMonth, fetchData} : Props) => {

    const [data, setData] = useState<WatchReport[]>();

    useEffect(() => {
        fetchData().then(value => {
            setData(value);
        })
    }, [teacherId, yearMonth])

    return (
            <ResponsiveContainer className="ltr" width="100%" height={400}>
                <BarChart
                    className="ltr"
                    width={150}
                    height={400}
                    data={data}
                    maxBarSize={70}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="courseTitle"/>
                    <YAxis domain={[0, (dateMax : any) => (Math.round(dateMax * 1.2))]}/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Bar dataKey="watchTime" fill="#8884d8" label={{ position: 'top' }}>
                        { data && data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % 20]} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

    );

}


const CustomTooltip = ({active, payload, label}: any) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                textAlign: "right",
                backgroundColor: "white",
                padding: "15px",
                boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.4)"
            }}>
                <p>{label}</p>
            </div>
        );
    }
    return null;
};