import React from "react";
import {Badge, Card, Tag} from "antd";
import {faChalkboardUser,} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {COURSE_ROUTE} from "../../routes/route-path";
import {useNavigate} from "react-router-dom";
import {
    CourseStatus,
    CourseStatusBadgeColor,
    CourseStatusTitle,
    CourseTag,
    CourseTagBadgeColor, CourseTagTitle
} from "../../constans/enums";
import {tab} from "@testing-library/user-event/dist/tab";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

interface Props {
    cardProperty: CardProperty;
}

export interface CardProperty {
    id ?: number;
    title: string;
    sessionHour?: number;
    sessionCount: number;
    categoryId?: number;
    img: any;
    fontSize ?: string;
    status ?: CourseStatus;
    tag ?: CourseTag;
}

export const CourseCard = ({cardProperty}: Props) => {

    return (

        <Badge.Ribbon text={cardProperty.status && CourseStatusTitle[cardProperty.status]}
                      color={cardProperty.status && CourseStatusBadgeColor[cardProperty.status]}>
            <Badge.Ribbon text={cardProperty.tag && CourseTagTitle[cardProperty.tag]}
                          color={cardProperty.tag && CourseTagBadgeColor[cardProperty.tag]}
                          placement={'end'}
                style={{top:'40px', display: cardProperty.tag ? 'block' : 'none'}}
            >
            <Card
                style={{borderRadius:"10px", marginRight: "auto", marginLeft: "auto", fontWeight: '100', maxWidth:"100%"}}
                bordered={false}
                cover={
                    <img
                        style={{borderTopLeftRadius:"10px", borderTopRightRadius:"10px"}}
                        alt={cardProperty.title}
                        src={baseNonVideoUrl + cardProperty.img}
                    />
                }
                hoverable
                onClick={() => {
                    if (cardProperty.sessionHour !== 0) document.location.href = COURSE_ROUTE.replace(":id", cardProperty.id + "")
                }}
            >
                <Card.Grid hoverable={false}
                           style={{borderBottomLeftRadius:"10px", borderBottomRightRadius:"10px", width: '100%', maxHeight:"200px", fontSize: cardProperty.fontSize ? cardProperty.fontSize : '15px', paddingRight:"30px", paddingLeft:"30px"}}>
                    <div style={{marginBottom:"12px"}}>
                        {cardProperty.title}
                    </div>
                    {cardProperty.sessionHour !== 0 ?
                        (<span>
                            <span style={{float:'right'}}><FontAwesomeIcon fontSize={cardProperty.fontSize ? cardProperty.fontSize : '15px'}  icon={faChalkboardUser} style={{marginLeft:'5px'}}/> {cardProperty.sessionCount} جلسه </span>
                        <span style={{float:'left'}}><FontAwesomeIcon fontSize={cardProperty.fontSize ? cardProperty.fontSize : '15px'} icon={faClock} style={{marginLeft:'5px'}} /> {cardProperty.sessionHour} دقیقه </span>
                        </span>)
                        :
                        <span>به زودی</span>
                    }
                </Card.Grid>
            </Card>
            </Badge.Ribbon>
        </Badge.Ribbon>

    );

}