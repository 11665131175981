import React from "react";
import {MainSlider} from "./MainSlider";

export const SliderWithWrapper = () => {

    return (
        <div className="container">
            <MainSlider/>
        </div>

    );

}