import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Divider, Modal, Table} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faPlus} from "@fortawesome/free-solid-svg-icons";
import {CourseCreate} from "./CourseCreate";
import {ColumnsType} from "antd/es/table";
import CourseService, {Course, CourseTypeTitle} from "../../../../services/CourseService";
import {encrypt, Page} from "../../../../services/UtilFunctions";
import {LessonCreate} from "./LessonCreate";
import {LessonView} from "./LessonView";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {CourseUpdate} from "./CourseUpdate";
import {CourseStatusTitle, CourseTagTitle} from "../../../../constans/enums";
import {COURSE_ROUTE} from "../../../../routes/route-path";


export const CourseContainer: React.FC = () => {

    const [showCreateCourseModal, setShowCreateCourseModal] = useState<boolean>(false);
    const [showCreateLessonModal, setShowCreateLessonModal] = useState<boolean>(false);
    const [showUpdateCourseModal, setShowUpdateCourseModal] = useState<boolean>(false);
    const [selectedCourseId, setSelectedCourseId] = useState<number>();
    const [selectedCourse, setSelectedCourse] = useState<Course>();
    const [param2, setParam2] = useState<string>();
    const [courseDeleteModal, courseDeleteModalContextHolder] = Modal.useModal();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
    });
    const [data, setData] = useState<Page<Course>>();

    useEffect(() => {
        loadCourses();
    }, [showCreateCourseModal, params, showCreateLessonModal, showUpdateCourseModal]);

    const loadCourses = () => {
        CourseService.getCoursesForAdmin(params).then(response => {
            setParam2(encrypt(response.uuid));
            const newPage: Page<Course> = {
                ...response.page,
                content: response.page.content.map(record => {
                    return {...record, key: record.id}
                })
            }
            setData(newPage);
        });
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<Course> = [
        {
            title: 'عنوان',
            dataIndex: 'title',
            width: 200,
            render: (_, {id, title}) => (
                <a href={COURSE_ROUTE.replace(":id", id + "")} target='_blank'>{title}</a>
            )
        },
        {
            title: 'دسته بندی',
            dataIndex: 'categories',
            width: 200,
            render: (_, {categories}) => (
                <>
                    {categories && categories.map(value => value.title + " | ")}
                </>
            )
        },
        {
            title: 'مدرس',
            dataIndex: 'teacher',
            width: 150,
            render: (_, {teacher}) => (
                <>
                    {teacher && teacher.firstName + " " + teacher.lastName}
                </>
            )
        },
        {
            title: 'وضغیت دوره',
            dataIndex: 'courseStatus',
            width: 100,
            render: (_, {courseStatus}) => (
                <>
                    {courseStatus && CourseStatusTitle[courseStatus]}
                </>
            ),
        },
        {
            title: 'برچسب دوره',
            dataIndex: 'courseTag',
            width: 100,
            render: (_, {courseTag}) => (
                <>
                    {courseTag && CourseTagTitle[courseTag]}
                </>
            ),
        },
        {
            title: 'نوع دوره',
            dataIndex: 'type',
            width: 100,
            render: (_, {type}) => (
                <>
                    {type && CourseTypeTitle[type]}
                </>
            ),
        },
        {
            title: 'درصد مشارکت',
            dataIndex: 'participationPercentage',
            width: 100
        },
        // {
        //     title: 'الویت نمایش دوره',
        //     dataIndex: 'priorityNumber',
        //     width: 200,
        // },
        {
            title: 'وضعیت',
            dataIndex: 'active',
            width: 70,
            render: (_, {active}) => (
                <>
                    {active ? "فعال" : "غیرفعال"}
                </>
            ),
        },
        {
            dataIndex: 'id',
            width: 300,
            render: (_, course) => (
                <>
                    <a onClick={() => {
                        setSelectedCourse(course);
                        setShowUpdateCourseModal(true);
                    }}>ویرایش دوره</a>
                    <span> | </span>
                    <a onClick={() => {
                        courseDeleteModal.confirm({
                            title: 'آیا می خواهید این دوره را حذف کنید؟',
                            icon: <ExclamationCircleOutlined/>,
                            okText: 'بله',
                            cancelText: 'انصراف',
                            okButtonProps: {danger: true},
                            onOk: () => {
                                CourseService.deleteCourse(course.id).then(() => loadCourses());
                            }
                        })
                    }}>حذف دوره</a>
                    <span> || </span>
                    <a onClick={() => {
                        setSelectedCourseId(course.id)
                        setShowCreateLessonModal(true);
                    }}>افزودن درس</a>
                </>
            )
        }
    ];

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>مدیریت دوره ها</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Button style={{marginTop: "20px"}} type="primary" onClick={() => setShowCreateCourseModal(true)}>
                <span style={{marginLeft: "5px"}}><FontAwesomeIcon icon={faPlus}/></span>
                <span>افزودن دوره جدید</span>
            </Button>
            <Table
                className="admin-course-table"
                style={{marginTop: "20px"}}
                columns={columns}
                dataSource={data?.content}
                size="middle"
                scroll={{x:1200}}
                pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: 10,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }}
                expandable={{
                    expandedRowRender: (record) => <LessonView courseId={record.id}
                                                               showCreateModal={showCreateLessonModal}/>,
                    rowExpandable: (record) => record.chaptersCounts !== 0,
                    fixed: "left",
                    expandIcon: ({expanded, onExpand, record}) =>
                        record.chaptersCounts !== 0 ? (
                            expanded ? (
                                <span className="hover-cursor-pointer"
                                      onClick={e => onExpand(record, e)}><FontAwesomeIcon
                                    icon={faAngleUp}/></span>
                            ) : (
                                <span className="hover-cursor-pointer"
                                      onClick={e => onExpand(record, e)}><FontAwesomeIcon
                                    icon={faAngleDown}/></span>
                            )
                        ) : ''

                }}
            />
            <div>
                {
                    (() => {
                        return (showCreateLessonModal ?
                            <LessonCreate show={showCreateLessonModal} setShow={setShowCreateLessonModal}
                                          courseId={selectedCourseId}/> : null)
                    })()
                }

                {
                    (() => {
                        return (showCreateCourseModal ?
                            <CourseCreate show={showCreateCourseModal} setShow={setShowCreateCourseModal}/> : null)
                    })()
                }

                {
                    (() => {
                        return (showUpdateCourseModal ? <CourseUpdate param2={param2} show={showUpdateCourseModal}
                                                                      setShow={setShowUpdateCourseModal}
                                                                      data={selectedCourse}/> : null)
                    })()
                }
            </div>
            {courseDeleteModalContextHolder}


        </Wrapper1>
    );


}