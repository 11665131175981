import React, {useState} from "react";
import type {MenuProps} from 'antd';
import {Menu} from 'antd';
import {THIRD_COLOR} from "../../../constans/colors";
import {CATEGORY_COURSES_ROUTE} from "../../../routes/route-path";
import {Category} from "../../../services/CategoryService";

interface Props {
    categories : Category[]
}

export const VerticalCategoryNavigation =({categories} : Props) => {

    const [current, setCurrent] = useState('mail');

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
    };

    const generateItems = () => {
        return categories.map(category => {
            return ({
                label: (
                    <a href={CATEGORY_COURSES_ROUTE.replace(":id", category.id + "")}>{category.title}</a>
                ),
                key: category.id ? category.id : "noKey",
            })
        })
    }

    return(
        <Menu style={{borderInlineEnd:"none", fontSize:'14px', borderBottom:'none', color:THIRD_COLOR}} onClick={onClick} selectedKeys={[current]} mode="vertical" items={generateItems()} />
    );

}