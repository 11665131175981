import React, {useState} from "react";
import {Button, Col, Divider, Form, Input, Row} from 'antd';
import {Wrapper1} from "../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../constans/colors";
import {useNavigate} from "react-router-dom";
import {FORGET_PASSWORD_ROUTE, HOME_PAGE_ROUTE, REGISTER_ROUTE} from "../../../routes/route-path";
import {toEnglishNumber} from "../../../services/UtilFunctions";
import TokenService from "../../../services/TokenService";


export const Login = () => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const onFinish = async (values: any) => {
        setLoading(true);
        TokenService.login(values.mobile, values.password).then(value => {
            setLoading(false);
            document.location.href = HOME_PAGE_ROUTE;
        }).catch(reason => {
            setLoading(false);
        })
    }

    const changeNumbersToEnglishNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, toEnglishNumber(e.target.value))
    };

    return(
        <div className="container">
            <Wrapper1 style={{marginTop:"55px", padding: "15px 15px"}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>ورود به سایت</div>
                <Divider style={{margin: '0', marginTop: '15px'}}/>
                <Row justify="center" style={{padding:"15px"}}>
                    <Col xl={7} md={10} sm={18} xs={24}>
                        <Form
                            form={form}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="mobile"
                                rules={[{ required: true, message: 'لطفا شماره همراه خود را وارد کنید!' }]}
                            >
                                <Input name="mobile" placeholder="شماره همراه" onChange={changeNumbersToEnglishNumbers}/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'لطفا رمز خود را وارد کنید!' }]}
                            >
                                <Input.Password
                                    type="password"
                                    placeholder="رمز"
                                />
                            </Form.Item>
                            <a onClick={() => navigate(FORGET_PASSWORD_ROUTE)}>فراموشی رمز عبور</a>
                            <a style={{float:"left"}} onClick={() => navigate(REGISTER_ROUTE)}>ثبت نام</a>
                            <Form.Item>
                                <Button style={{width:"100%", marginTop:"10px"}} type="primary" htmlType="submit" loading={loading}>
                                    ورود
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Wrapper1>
        </div>
    );

}