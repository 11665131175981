import React from "react";

interface Props {
    content?: string;
    tagType: TagType
}

export enum TagType {
    DIV = 'DIV'
}

export const EditorContent = ({content, tagType}: Props) => {

    const handleContent = () => {
        if (!content) return null;
        if (tagType === TagType.DIV) {
            return <div className="quill-container">
                <div className="ql-editor" style={{whiteSpace: 'pre-line'}}
                     dangerouslySetInnerHTML={{__html: content}}/>
            </div>;
        }else {
            return null;
        }
    }

    return (
        <>
            {
                handleContent()
            }
        </>
    );

}