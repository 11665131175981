import {FORTH_COLOR, SECOND_COLOR} from "../../constans/colors";
import React from "react";
import CSS from "csstype";

interface Props {
    style?: CSS.Properties;
    children?: string | JSX.Element | JSX.Element[];
    topBorderColor?: string
}

export const Wrapper1 = ({style = {}, children, topBorderColor = SECOND_COLOR}: Props) => {

    return (
        <div className="plans-preview" style={{
            backgroundColor:FORTH_COLOR,
            borderTop: "3px solid " + topBorderColor,
            boxShadow: "0px 0px 2px 0px rgb(48 48 48 / 38%)",
            borderRadius: "10px",
            ...style
        }}>
            {children}
        </div>
    );

}