import React from "react";
import {Breadcrumb} from "antd";
import {ItemType} from "antd/es/breadcrumb/Breadcrumb";

interface Props {
    items: ItemType[];
}

export const CustomBreadcrumb = ({items} : Props) => {

    return (
        <div className="container" style={{marginTop:"20px", marginBottom:"20px"}}>
            <Breadcrumb
                items={items}
            />
        </div>
    );

}