import axios from "axios";
import {genericApiCall} from "./UtilFunctions";
import TokenService from "./TokenService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface CourseRate {
    avg : number;
    total: number;
}

class CourseRateService {

    private static instance: CourseRateService;

    private constructor() {
    }

    public static getInstance(): CourseRateService {
        if (!CourseRateService.instance) {
            CourseRateService.instance = new CourseRateService();
        }
        return CourseRateService.instance;
    }

    async getRatesByCourseId(courseId: number): Promise<CourseRate> {
        return await genericApiCall<CourseRate>(async () => {
            const response = await axios.get<CourseRate>(baseUrl + "/public/v1/courses-rate/" + courseId);
            return response.data;
        });
    }

    async saveRate(courseId: number, rate : number): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/courses-rate/" + courseId, {rate}, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }


}

export default CourseRateService.getInstance();