import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface CourseCommentInput {
    title : string;
    description: string;
}

export interface CourseComment {
    id:number;
    courseId:number;
    title : string;
    description: string;
    firstName:string;
    lastName:string;
    courseTitle:string;
    approved:boolean;
    createdDate:Date;
    replyCount:number;
    replyComment:CourseComment;
    admin:boolean;
}

class CourseCommentService {

    private static instance: CourseCommentService;

    private constructor() {
    }

    public static getInstance(): CourseCommentService {
        if (!CourseCommentService.instance) {
            CourseCommentService.instance = new CourseCommentService();
        }
        return CourseCommentService.instance;
    }

    async addComment(courseId: number, data:CourseCommentInput): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/courses-comment/" + courseId, data, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getCommentsForAdmin(params:any): Promise<Page<CourseComment>> {
        return await genericApiCall<Page<CourseComment>>(async () => {
            const response = await axios.get<Page<CourseComment>>(baseUrl + "/api/v1/courses-comment", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getComments(courseId:number, params:any): Promise<Page<CourseComment>> {
        return await genericApiCall<Page<CourseComment>>(async () => {
            const response = await axios.get<Page<CourseComment>>(baseUrl + "/public/v1/courses-comment/" + courseId, {
                params
            });
            return response.data;
        });
    }

    async approveComment(id: number, approved:boolean): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/courses-comment/approve/" + id, {approved}, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async deleteComment(id: number): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/courses-comment/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }


}

export default CourseCommentService.getInstance();