import {ELEVENTH_COLOR, FIFTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Row, Tag} from "antd";
import {Comment} from '@ant-design/compatible';

import {openErrorNotification, toPersianDate} from "../../services/UtilFunctions";
import TokenService from "../../services/TokenService";
import CourseCommentService, {CourseComment} from "../../services/CourseCommentService";
import {Wrapper1} from "../wrapper/Wrapper1";
import {CourseCommentSubmit} from "./CourseCommentSubmit";
import {CourseCommentReplyLoader} from "./CourseCommentReplyLoader";

interface Props {
    id: number;
}

export const CourseCommentContainer = ({id}: Props) => {

    const [showSubmitComment, setShowSubmitComment] = useState<boolean>(false)
    const [replyCommentId, setReplyCommentId] = useState<number | null>(null);
    const [commentsParams, setCommentsParams] = useState({
        pageNumber: 0,
        pageSize: 5,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [activeShowMore, setActiveShowMore] = useState<boolean>(true);
    const [totalComment, setTotalComment] = useState<number>(0);
    const [comments, setComments] = useState<CourseComment[]>([])

    useEffect(() => {
        loadComments()
    }, [commentsParams])

    const loadComments = () => {
        if (id) {
            CourseCommentService.getComments(id, commentsParams).then(page => {
                setComments([...comments, ...page.content]);
                setActiveShowMore(!page.last);
                setTotalComment(page.totalElements);
            })
        }
    }

    const commentsHandleSelectPagination = (page: number, pageSize: number) => {
        setCommentsParams({
            ...commentsParams,
            pageNumber: page,
            pageSize: pageSize
        });
    };

    return (
        <Wrapper1 style={{padding: "15px", paddingTop: "0px", color: THIRD_COLOR}}>
            <h3>
                نظرات ({totalComment})
                <Button
                    size={"large"}
                    type="link"
                    htmlType="submit"
                    onClick={event => {
                        if (!TokenService.isLogin()) {
                            openErrorNotification("برای ثبت نظر باید وارد سایت شوید.", 5)
                            return;
                        }else {
                            setShowSubmitComment(true);
                        }
                    }}
                >ثبت
                    نظر</Button>
            </h3>
            <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
            <Row gutter={[16, 16]} align="middle" justify="start"
                 style={{paddingBottom: "16px", paddingTop: "16px"}}>
                {
                    comments?.map(comment => {
                        return (
                            <Col span={24}>
                                <Comment
                                    actions={
                                        [
                                            <Button
                                                type="link"
                                                htmlType="submit"
                                                onClick={event => {
                                                    if (!TokenService.isLogin()) {
                                                        openErrorNotification("برای ثبت نظر باید وارد سایت شوید.", 5)
                                                        return;
                                                    }else {
                                                        setShowSubmitComment(true);
                                                        setReplyCommentId(comment.id);
                                                    }
                                                }}
                                            >پاسخ به این نظر</Button>,
                                            <span style={{color:ELEVENTH_COLOR}}>
                                                پاسخ ها
                                                ({comment.replyCount})
                                            </span>
                                        ]
                                    }
                                    author={
                                        <span>
                                            {comment.firstName && comment.lastName ? comment.firstName + " " + comment.lastName : ""}
                                            {
                                                comment.admin &&  <Tag style={{marginRight:"7px"}} color="geekblue">
                                                    ادمین
                                                </Tag>
                                            }
                                        </span>}
                                    content={
                                        <>
                                            <p>
                                                {comment.description}
                                            </p>
                                        </>
                                    }
                                    datetime={toPersianDate(comment.createdDate)}
                                >
                                    {
                                        comment.replyCount !== 0 &&
                                        <CourseCommentReplyLoader courseId={id} commentId={comment.id}/>
                                    }
                                </Comment>
                                <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
                            </Col>
                        );
                    })
                }
            </Row>
            <Row gutter={[16, 16]} align="middle" justify="space-evenly">
                <Button type="text" htmlType="submit" disabled={!activeShowMore} onClick={event => {
                    commentsHandleSelectPagination(commentsParams.pageNumber + 1, commentsParams.pageSize);
                }}>نمایش بیشتر</Button>
            </Row>
            <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
            <CourseCommentSubmit show={showSubmitComment} courseId={id} setShow={setShowSubmitComment} replyCommentId={replyCommentId} setReplyCommentId={setReplyCommentId}/>
        </Wrapper1>
    );

}