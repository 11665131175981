import React, {useEffect, useState} from "react";
import {SliderWithWrapper} from "../../components/slider";
import {Footer} from "../../components/new-footer";
import {HeaderWrapper} from "../../components/header-wrapper";
import DateService from "../../services/DateService";
import {CoursesPreview} from "../../components/course/preview";
import {NewCoursesPreview} from "../../components/new-courses/preview";
import {MostViewCoursesPreview} from "../../components/most-virew-courses/preview";
import {CooperationFormSticky} from "../../components/common/CooperationFormSticky";

export const HomePage: React.FC = () => {

    const [correctTime, setCorrectTime] = useState<boolean>(true);

    useEffect(() => {
        const localDate = new Date();
        DateService.getDate().then(value => {
            const serverDate = new Date(value + "+03:30");
            const timeDifference = Math.abs(localDate.getTime() - serverDate.getTime());
            setCorrectTime(timeDifference < 900000)
        })
    })

    return (
        <>
            {/*<Modal*/}
            {/*    centered*/}
            {/*    open={true}*/}
            {/*    onOk={() => console.log()}*/}
            {/*    onCancel={() => console.log()}*/}
            {/*    footer={<div></div>}*/}
            {/*    closable={false}*/}
            {/*>*/}
            {/*    <p style={{textAlign:"center", fontSize:"20px", color:SECOND_COLOR}}><span style={{paddingLeft:"10px"}}><FontAwesomeIcon icon={faScrewdriverWrench} style={{fontSize:'30px'}}/></span>با عرض پوزش سایت در حال تعمیرات است.</p>*/}
            {/*</Modal>*/}
            {/*<BlackFridayTopLine correctTime={correctTime}/>*/}
            <CooperationFormSticky/>
            {/*<TopLine/>*/}
            <HeaderWrapper/>
            <SliderWithWrapper/>
            <MostViewCoursesPreview/>
            <NewCoursesPreview/>
            <CoursesPreview/>
            <Footer/>
        </>
    );
}