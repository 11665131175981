import {ELEVENTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import React, {useEffect} from "react";
import {Avatar, Divider} from "antd";
import {Wrapper1} from "../wrapper/Wrapper1";
import {User} from "../../services/UserService";
import {DegreeTitle, DegreeWeight} from "../../services/EducationService";
import {EditorContent, TagType} from "../common/EditorContent";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";
interface Props {
    teacher: User;
}

export const AboutTeacher = ({teacher}: Props) => {

    useEffect(() => {
    }, [])

    return (
        <Wrapper1 style={{padding: "15px", paddingTop: "0px", color: THIRD_COLOR}}>
            <h3>درباره مدرس</h3>
            <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
            <span>
                <Avatar size={55} src={baseNonVideoUrl + teacher.photoUrl} style={{marginLeft:"20px"}} />
                <span style={{fontSize:"15px"}}>{teacher.firstName + " " + teacher.lastName}</span>
                <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
                 <h3>تحصیلات</h3>
                {teacher?.educations.sort((a, b) => DegreeWeight[b.degree] - DegreeWeight[a.degree]).map(education => {
                    return (
                        <div>
                            <span style={{float:"right", marginLeft:"10px"}}>
                                {DegreeTitle[education.degree]}
                            </span>
                            <span style={{float:"right", marginLeft:"10px", color:ELEVENTH_COLOR}}>
                                {education.university}
                            </span>
                            <span style={{color:ELEVENTH_COLOR}}>
                                {(education.startYear ? education.startYear : "")  + (education.startYear && education.endYear ? " - " : "") + (education.endYear ? education.endYear : "")}
                            </span>
                            <div style={{paddingTop: "5px", paddingRight: "15px", paddingLeft: "15px", paddingBottom: "25px", fontSize: "12px"}}>
                                <p style={{whiteSpace: 'pre-line', textAlign: 'justify'}}>{education.description}</p>
                            </div>
                        </div>
                    )
                })}
            </span>
            <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
            <h3>اطلاعات بیشتر</h3>
            <EditorContent tagType={TagType.DIV} content={teacher.description}/>
        </Wrapper1>
    );

}