import React, {useEffect, useState} from "react";
import {Category} from "../../../services/CategoryService";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/reduxStore";
import {Skeleton} from "antd";
import CourseService, {Course} from "../../../services/CourseService";
import {CATEGORY_COURSES_ROUTE} from "../../../routes/route-path";
import {CoursesPreviewWrapper} from "./CoursesPreviewWrapper";
import {SECOND_COLOR} from "../../../constans/colors";
import {CoursesPreviewCategoryBasedWrapper} from "./CoursesPreviewCategoryBasedWrapper";
import TopCoursesService from "../../../services/TopCoursesService";
import {Page} from "../../../services/UtilFunctions";

interface Data {
    category : Category;
    courses : Course[];
}

export const CoursesPreview = () => {

    const categories: Category[] = useSelector((state: RootState) => state.categories);
    const [data, setData] = useState<Data[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const pageParams = {
        pageNumber: 0,
        pageSize: 5,
        sort:"ASC",
        sortKey:"priorityNumber"
    };

    useEffect(() => {
        if (categories && categories.length !== 0) {
            categories.forEach( async (category) => {
                const page : Page<Course> = await TopCoursesService.getTopCategoryCourses({...pageParams, categoryId:category.id});
                setData(prevState => [...prevState, {courses:page.content, category}])
            });
            setLoading(false);
        }
    }, [categories])

    return (
        <div className="container">
            <Skeleton loading={loading} style={{marginTop: "35px"}} title={false} active={true}
                      paragraph={{rows: 15, width: '100%'}}/>
            {
                data.map((d) => {
                    return d.courses && d.courses.length !== 0 ? <CoursesPreviewCategoryBasedWrapper category={d.category} color={SECOND_COLOR} courses={d.courses} categoryId={d.category.id} title={d.category.title} moreUrl={CATEGORY_COURSES_ROUTE.replace(":id", d.category.id + "")}/> : null;
                })
            }
        </div>
    );

}