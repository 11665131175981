import React, {useEffect, useState} from 'react';
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {Avatar, Divider, Modal, Skeleton, Space} from "antd";
import EducationService, {DegreeTitle, DegreeWeight, Education} from "../../../../services/EducationService";
import {ELEVENTH_COLOR, FIFTH_COLOR, THIRD_COLOR} from "../../../../constans/colors";
import UserService, {User} from "../../../../services/UserService";
import {TEACHER_COURSES_ROUTE} from "../../../../routes/route-path";
import {TeacherEducationUpdate} from "./TeacherEducationUpdate";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";
import {TeacherEducationCreate} from "./TeacherEducationCreate";
import {UserUpdate} from "./TeacherUpdate";
import {EditorContent, TagType} from "../../../common/EditorContent";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

export const TeacherProfileContainer = () => {

    const [user, setUser] = useState<User>();
    const [showEditEducationModal, setShowEditEducationModal] = useState<boolean>(false);
    const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false);
    const [showCreateEducationModal, setShowCreateEducationModal] = useState<boolean>(false);
    const [selectedEducation, setSelectedEducation] = useState<Education>();
    const [educationDeleteModal, educationDeleteModalContextHolder] = Modal.useModal();

    useEffect(() => {
        loadUser();
    }, [showEditEducationModal, showCreateEducationModal, showEditUserModal])

    const loadUser = () => {
        UserService.getSelfProfile().then(response => setUser(response));
    }

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px", color: THIRD_COLOR}}>
            <Skeleton loading={!user} style={{marginTop: "35px"}} title={false} active={true}
                      paragraph={{rows: 15, width: '100%'}}/>
            {user ? (
                <span>
            <span>
                <span style={{float: "right", marginLeft: "10px"}}>
                                <a onClick={event => {
                                    setShowEditUserModal(true);
                                }}>
                                    <svg width="20" height="20" fill="none" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg"><path
                                        d="M21.03 2.97a3.578 3.578 0 0 1 0 5.06L9.062 20a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L15.97 2.97a3.578 3.578 0 0 1 5.06 0ZM15 6.06 5.062 16a.75.75 0 0 0-.193.333l-1.05 3.85 3.85-1.05A.75.75 0 0 0 8 18.938L17.94 9 15 6.06Zm2.03-2.03-.97.97L19 7.94l.97-.97a2.079 2.079 0 0 0-2.94-2.94Z"
                                        fill={FIFTH_COLOR}/></svg>
                                </a>
                            </span>
                <Avatar size={55} src={baseNonVideoUrl + user.photoUrl} style={{marginLeft: "20px"}}/>
                <span style={{fontSize: "15px"}}>{user.firstName + " " + user.lastName}</span>
                <Space style={{float: "left"}} size="large">
                    <a href={TEACHER_COURSES_ROUTE.replace(":id", user.id + "")}>دوره های شما</a>
                </Space>
                <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>

                <Space>
                    <h3>تحصیلات</h3>
                <a onClick={event => {
                    setShowCreateEducationModal(true);
                }}>
                                    افزودن مقطع جدید
                                </a>
                </Space>



                {user?.educations.sort((a, b) => DegreeWeight[b.degree] - DegreeWeight[a.degree]).map(education => {
                    return (
                        <div>
                            <span style={{float: "right", marginLeft: "10px"}}>
                                <a onClick={event => {
                                    educationDeleteModal.confirm({
                                        title: 'آیا می خواهید این مدرک را حذف کنید؟',
                                        icon: <ExclamationCircleOutlined/>,
                                        okText: 'بله',
                                        cancelText: 'انصراف',
                                        okButtonProps: {danger: true},
                                        onOk: () => {
                                            EducationService.selfDelete(education.id).then(() => {
                                                loadUser();
                                                openSuccessNotificationByMessage("مدرک حذف شد.", 5);
                                            });
                                        }
                                    })
                                }}>
                                    <svg width="20" height="20" fill="none" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg"><path
                                        d="M12 1.75a3.25 3.25 0 0 1 3.245 3.066L15.25 5h5.25a.75.75 0 0 1 .102 1.493L20.5 6.5h-.796l-1.28 13.02a2.75 2.75 0 0 1-2.561 2.474l-.176.006H8.313a2.75 2.75 0 0 1-2.714-2.307l-.023-.174L4.295 6.5H3.5a.75.75 0 0 1-.743-.648L2.75 5.75a.75.75 0 0 1 .648-.743L3.5 5h5.25A3.25 3.25 0 0 1 12 1.75Zm6.197 4.75H5.802l1.267 12.872a1.25 1.25 0 0 0 1.117 1.122l.127.006h7.374c.6 0 1.109-.425 1.225-1.002l.02-.126L18.196 6.5ZM13.75 9.25a.75.75 0 0 1 .743.648L14.5 10v7a.75.75 0 0 1-1.493.102L13 17v-7a.75.75 0 0 1 .75-.75Zm-3.5 0a.75.75 0 0 1 .743.648L11 10v7a.75.75 0 0 1-1.493.102L9.5 17v-7a.75.75 0 0 1 .75-.75Zm1.75-6a1.75 1.75 0 0 0-1.744 1.606L10.25 5h3.5A1.75 1.75 0 0 0 12 3.25Z"
                                        fill={FIFTH_COLOR}/></svg>
                                </a>
                            </span>
                            <span style={{float: "right", marginLeft: "10px"}}>
                                <a onClick={event => {
                                    setSelectedEducation(education);
                                    setShowEditEducationModal(true);
                                }}>
                                    <svg width="20" height="20" fill="none" viewBox="0 0 24 24"
                                         xmlns="http://www.w3.org/2000/svg"><path
                                        d="M21.03 2.97a3.578 3.578 0 0 1 0 5.06L9.062 20a2.25 2.25 0 0 1-.999.58l-5.116 1.395a.75.75 0 0 1-.92-.921l1.395-5.116a2.25 2.25 0 0 1 .58-.999L15.97 2.97a3.578 3.578 0 0 1 5.06 0ZM15 6.06 5.062 16a.75.75 0 0 0-.193.333l-1.05 3.85 3.85-1.05A.75.75 0 0 0 8 18.938L17.94 9 15 6.06Zm2.03-2.03-.97.97L19 7.94l.97-.97a2.079 2.079 0 0 0-2.94-2.94Z"
                                        fill={FIFTH_COLOR}/></svg>
                                </a>
                            </span>
                            <span style={{float: "right", marginLeft: "10px"}}>
                                {DegreeTitle[education.degree]}
                            </span>
                            <span style={{float: "right", marginLeft: "10px", color: ELEVENTH_COLOR}}>
                                {education.university}
                            </span>
                            <span style={{color: ELEVENTH_COLOR}}>
                                {(education.startYear ? education.startYear : "") + (education.startYear && education.endYear ? " - " : "") + (education.endYear ? education.endYear : "")}
                            </span>
                            <div style={{
                                paddingTop: "5px",
                                paddingRight: "35px",
                                paddingLeft: "15px",
                                paddingBottom: "25px",
                                fontSize: "12px"
                            }}>
                                <p style={{whiteSpace: 'pre-line', textAlign: 'justify'}}>{education.description}</p>
                            </div>
                        </div>
                    )
                })}
            </span>
            <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
            <h3>اطلاعات بیشتر</h3>
                    <EditorContent tagType={TagType.DIV} content={user.description}/>
                </span>
            ) : <span/>}
            <span>
                {
                    (() => {
                        return (showEditEducationModal && selectedEducation ?
                            <TeacherEducationUpdate show={showEditEducationModal} setShow={setShowEditEducationModal}
                                                    education={selectedEducation}/> : null)
                    })()
                }
                {
                    (() => {
                        return (showCreateEducationModal ? <TeacherEducationCreate show={showCreateEducationModal}
                                                                                   setShow={setShowCreateEducationModal}/> : null)
                    })()
                }
                {
                    (() => {
                        return (showEditUserModal && user ? <UserUpdate show={showEditUserModal} setShow={setShowEditUserModal} user={user}/> : null)
                    })()
                }
                {educationDeleteModalContextHolder}
            </span>
        </Wrapper1>
    );
}
