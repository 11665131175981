import React from "react";
import {Footer} from "../../components/new-footer";
import {HeaderWrapper} from "../../components/header-wrapper";
import {BuyPlan} from "../../components/buy-plan";

export const BuyPlanPage =() => {
    return(
        <>
            <HeaderWrapper/>
            <BuyPlan/>
            <Footer/>
        </>
    );
}