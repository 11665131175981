import React, {useState} from "react";
import {Button, Form, Modal} from "antd";
import {openSuccessNotificationByMessage} from "../../services/UtilFunctions";
import CourseCommentService from "../../services/CourseCommentService";
import TextArea from "antd/es/input/TextArea";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

interface Props {
    show:boolean;
    courseId:number;
    replyCommentId:number | null;
    setReplyCommentId:any;
    setShow(b : boolean):void
}

export const CourseCommentSubmit =({show, setShow, courseId, replyCommentId, setReplyCommentId} : Props) => {

    const [sendCommentLoading, setSendCommentLoading] = useState<boolean>(false)
    const [sendCommentForm] = Form.useForm();

    return(
        <Modal
            centered
            title="نظر خود را درباره این دوره ثبت نمایید."
            open={show}
            onOk={() => {}}
            onCancel={() => {
                sendCommentForm.resetFields();
                setReplyCommentId(null);
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={sendCommentForm}
                name="basic"
                labelCol={{span: 0}}
                wrapperCol={{span: 24}}
                style={{width: '100%'}}
                initialValues={{remember: true}}
                onFinish={(values) => {
                    if (courseId) {
                        values = {...values, replyCommentId}
                        setSendCommentLoading(true);
                        CourseCommentService.addComment(courseId, values).then(value1 => {
                            openSuccessNotificationByMessage("نظر شما ثبت شد.", 5);
                            sendCommentForm.resetFields();
                            setReplyCommentId(null);
                            setSendCommentLoading(false);
                            setShow(false);
                        }).catch(reason => setSendCommentLoading(false));
                    }
                }}
                autoComplete="off"
            >
                <Form.Item name="description" rules={[{required: true, message: 'این فیلد الزامی است.'}, {
                    max: 1500,
                    message: "حداکثر کاراکتر 1500 می باشد."
                }]}><TextArea placeholder="توضیحات" rows={4}/></Form.Item>
                <Form.Item><Button type="primary" htmlType="submit" loading={sendCommentLoading}>ثبت
                    نظر</Button></Form.Item>
            </Form>
        </Modal>
    );

}