import React, {useState} from "react";
import {Col, Layout, MenuProps} from 'antd';
import {HOME_PAGE_ROUTE} from "../../routes/route-path";
import logo from "../../assets/images/logo1.png";
import {DashboardSideMenu} from "../../components/dashboard/DashboardSideMenu";
import {CategoryContainer} from "../../components/dashboard/admin/category/CategoryContainer";
import {CourseContainer} from "../../components/dashboard/admin/course/CourseContainer";
import {UserContainer} from "../../components/dashboard/admin/user/UserContainer";
import {FORTH_COLOR, SEVENTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import {PlanContainer} from "../../components/dashboard/admin/plan/PlanContainer";
import {UserPlanContainer} from "../../components/dashboard/admin/user-plan/UserPlanContainer";
import {toPersianDate} from "../../services/UtilFunctions";
import {DiscountCodeContainer} from "../../components/dashboard/admin/discount-code/DiscountCodeContainer";
import {CourseCommentContainer} from "../../components/dashboard/admin/course-comment/CourseCommentContainer";
import {ContactUsContainer} from "../../components/dashboard/admin/contact-us/ContactUsContainer";
import {NotificationContainer} from "../../components/dashboard/admin/notification/NotificationContainer";
import {CooperationFormContainer} from "../../components/dashboard/admin/cooperation-form/CooperationFormContainer";
import {TopCoursesContainer} from "../../components/dashboard/admin/top-courses/TopCoursesContainer";
import {WatchReportContainer} from "../../components/dashboard/admin/watch-report/WatchReportContainer";
import {TeacherPaymentContainer} from "../../components/dashboard/admin/teacher-payment/TeacherPaymentContainer";
import {FileManagementContainer} from "../../components/dashboard/admin/file-management/FileManagementContainer";
import {
    CommentOutlined,
    DollarOutlined,
    FileOutlined, MoneyCollectOutlined,
    NotificationOutlined, PercentageOutlined,
    PhoneOutlined,
    PieChartOutlined, ProfileOutlined, ReadOutlined, ShoppingOutlined, StarOutlined, TeamOutlined, UserOutlined
} from "@ant-design/icons";

type MenuItem = Required<MenuProps>['items'][number];

const {Header, Footer, Sider, Content} = Layout;

const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 60,
    paddingInline: 50,
    marginBottom:"20px",
    lineHeight: '64px',
    backgroundColor: SEVENTH_COLOR,
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}
export const AdminDashboardPage = () => {

    const [activeComponent, setActiveComponent] = useState<React.ReactNode>(<CategoryContainer/>)

    const items: MenuItem[] = [
        getItem((<a onClick={() => setActiveComponent(<CategoryContainer/>)}>دسته بندی</a>), "key1", <ProfileOutlined />),
        getItem((<a onClick={() => setActiveComponent(<CourseContainer/>)}>دوره ها</a>), "key2", <ReadOutlined />),
        getItem((<a onClick={() => setActiveComponent(<TopCoursesContainer/>)}>دوره‌های برتر</a>), "key11", <StarOutlined />),
        getItem((<a onClick={() => setActiveComponent(<UserContainer/>)}>کاربران</a>), "key3", <UserOutlined />),
        getItem((<a onClick={() => setActiveComponent(<PlanContainer/>)}>اشتراک</a>), "key4", <MoneyCollectOutlined />),
        getItem((<a onClick={() => setActiveComponent(<UserPlanContainer/>)}>اشتراک های خریداری شده</a>), "key5", <ShoppingOutlined />),
        getItem((<a onClick={() => setActiveComponent(<DiscountCodeContainer/>)}>کد های تخفیف</a>), "key6", <PercentageOutlined />),
        getItem((<a onClick={() => setActiveComponent(<CourseCommentContainer/>)}>نظرات دوره</a>), "key7", <CommentOutlined />),
        getItem((<a onClick={() => setActiveComponent(<ContactUsContainer/>)}>تماس با ما</a>), "key8", <PhoneOutlined />),
        getItem((<a onClick={() => setActiveComponent(<NotificationContainer/>)}>اعلان ها</a>), "key9", <NotificationOutlined />),
        getItem((<a onClick={() => setActiveComponent(<CooperationFormContainer/>)}>تدریس در دانشگاهینو</a>), "key10", <TeamOutlined />),
        getItem((<a onClick={() => setActiveComponent(<WatchReportContainer/>)}>گزارش مدرس</a>), "key12", <PieChartOutlined />),
        getItem((<a onClick={() => setActiveComponent(<TeacherPaymentContainer/>)}>مالی مدرسان</a>), "key13", <DollarOutlined />),
        getItem((<a onClick={() => setActiveComponent(<FileManagementContainer/>)}>مدیریت فایل ها</a>), "key14", <FileOutlined />),
    ]

    return (
        <Layout>
            <Sider style={{
                textAlign: 'right',
                backgroundColor: FORTH_COLOR,
                padding:"35px 5px",
                fontSize:"30px",
                overflow: 'hidden',
            }} breakpoint={"md"} width={280} collapsedWidth={50}>
                <DashboardSideMenu defaultSelectedKey="key1" items={items} style={{fontSize:"18px",borderInlineEnd:"none"}} mode="inline"/>
            </Sider>
            <Layout style={{minHeight:"100vh"}}>
                <Header style={headerStyle}>
                    <a href={HOME_PAGE_ROUTE}><img src={logo} alt="logo" style={{
                        height: "100%",
                        marginRight: "-50px",
                        padding: "10px 16px",
                        float: "right"
                    }}/></a>
                    <span style={{color: THIRD_COLOR, float: "right"}}>دانشگاهینو / پنل ادمین</span>
                    <Col xs={0} sm={24}>
                    <span style={{color: THIRD_COLOR, float: "left"}}>
                        {toPersianDate(new Date())}
                        <span style={{padding: "0px 5px"}}>|</span>
                        <a style={{color: THIRD_COLOR}} href={HOME_PAGE_ROUTE}>بازگشت</a>
                    </span>
                    </Col>
                </Header>
                <Content>
                    {activeComponent}
                </Content>
            </Layout>
        </Layout>
    );
}