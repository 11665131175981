import {ELEVENTH_COLOR, FIFTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Row, Tag} from "antd";
import {Comment} from '@ant-design/compatible';

import {openErrorNotification, toPersianDate} from "../../services/UtilFunctions";
import TokenService from "../../services/TokenService";
import CourseCommentService, {CourseComment} from "../../services/CourseCommentService";
import {Wrapper1} from "../wrapper/Wrapper1";
import {CourseCommentSubmit} from "./CourseCommentSubmit";

interface Props {
    courseId: number;
    commentId: number;
}

export const CourseCommentReplyLoader = ({courseId, commentId}: Props) => {

    const [commentsParams, setCommentsParams] = useState({
        pageNumber: 0,
        pageSize: 2,
        sortKey: "createdDate",
        sort: "ASC",
        replyCommentId:commentId
    });
    const [activeShowMore, setActiveShowMore] = useState<boolean>(true);
    const [comments, setComments] = useState<CourseComment[]>([])

    useEffect(() => {
        loadComments()
    }, [commentsParams])

    const loadComments = () => {
        if (courseId) {
            CourseCommentService.getComments(courseId, commentsParams).then(page => {
                setComments([...comments, ...page.content]);
                setActiveShowMore(!page.last);
            })
        }
    }

    const commentsHandleSelectPagination = (page: number, pageSize: number) => {
        setCommentsParams({
            ...commentsParams,
            pageNumber: page,
            pageSize: pageSize
        });
    };

    return (
        <>
            <Row gutter={[16, 16]} align="middle" justify="start"
                 style={{paddingBottom: "16px", paddingTop: "16px"}}>
                {
                    comments?.map(comment => {
                        return (
                            <Col span={24}>
                                <Comment
                                    author={
                                        <span>
                                            {comment.firstName && comment.lastName ? comment.firstName + " " + comment.lastName : ""}
                                            {
                                                comment.admin &&  <Tag style={{marginRight:"7px"}} color="geekblue">
                                                    ادمین
                                                </Tag>
                                            }
                                        </span>}
                                    content={
                                        <>
                                            <p>
                                                {comment.description}
                                            </p>
                                        </>
                                    }
                                    datetime={toPersianDate(comment.createdDate)}
                                />
                            </Col>
                        );
                    })
                }
            </Row>
            <Row gutter={[16, 16]} align="middle" justify="start">
                <Button type="text" htmlType="submit" disabled={!activeShowMore} onClick={event => {
                    commentsHandleSelectPagination(commentsParams.pageNumber + 1, commentsParams.pageSize);
                }}>نمایش بیشتر</Button>
            </Row>
        </>
    );

}