import React, {Key, useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Col, Divider, Form, Row, Select, Space, Table, TableProps, Tag} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {ColumnsType} from "antd/es/table";
import UserService, {Role, RoleTitle, User} from "../../../../services/UserService";
import {Page, toPersianDateTime2} from "../../../../services/UtilFunctions";
import {UserCreate} from "./UserCreate";
import {EducationContainer} from "./EducationContainer";
import {UserUpdate} from "./UserUpdate";
import {SearchFilterInput} from "../../../common/SearchFilterInput";
import {SorterResult, TableCurrentDataSource} from "antd/lib/table/interface";
import {SessionContainer} from "./SessionContainer";
const { Option } = Select;

export const UserContainer: React.FC = () => {

    const [data, setData] = useState<Page<User>>();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showEducationModal, setShowEducationModal] = useState<boolean>(false);
    const [showSessionModal, setShowSessionModal] = useState<boolean>(false);
    const [showUpdateUserModal, setShowUpdateUserModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "created_date",
        sort: "DESC"
    });
    const [selectedUser, setSelectedUser] = useState<User>();

    const columns: ColumnsType<User> = [
        {
            title: 'نام کاربری',
            dataIndex: 'username',
            width: 100,
        },
        {
            title: 'نام',
            dataIndex: 'firstName',
            width: 100,
        },
        {
            title: 'نام خانوادگی',
            dataIndex: 'lastName',
            width: 100,
        },
        {
            title: 'موبایل',
            dataIndex: 'mobile',
            width: 100,
        },
        {
            title: 'دستگاه‌های فعال',
            dataIndex: 'activeDeviceCount',
            width: 100,
        },
        {
            title: 'آخرین زمان فعالیت',
            dataIndex: 'lastActivityEndDateTime',
            width: 150,
            sorter:true,
            render: (_, record) => (
                <>
                    {
                        record.online ? <div>
                                <div className="online-status" style={{marginLeft: "10px"}}></div>
                                آنلاین
                            </div> :
                            (record.lastActivityEndDateTime && record.lastActivityStartDateTime ?
                                <div>
                                    <span> از </span>
                                    {
                                        toPersianDateTime2(record.lastActivityStartDateTime)
                                    }
                                    <span> تا </span>
                                    {
                                        toPersianDateTime2(record.lastActivityEndDateTime)
                                    }
                                </div> : "-")

                    }
                </>
            ),
        },
        {
            title: 'نقش ها',
            dataIndex: 'roles',
            width: 100,
            render: (_, {roles}) => (
                <>
                    {roles && roles.map((role) => {
                        let color = 'volcano';
                        if (role === Role.ROLE_ADMIN) color = 'geekblue';
                        if (role === Role.ROLE_TEACHER) color = 'green';
                        if (role === Role.ROLE_TEACHER_REPORT) color = '#9254de';
                        if (role === Role.ROLE_TEACHER_PAYMENT) color = '#f759ab';
                        return (
                            <Tag color={color} key={role}>
                                {RoleTitle[role]}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            dataIndex: 'id',
            width: 150,
            render: (_, user) => (
                <>
                    <a onClick={() => {
                        setSelectedUser(user);
                        setShowEducationModal(true);
                    }}>تحصیلات</a>
                    <span> | </span>
                    <a onClick={() => {
                        setSelectedUser(user);
                        setShowUpdateUserModal(true);
                    }}>ویرایش کاربر</a>
                    <span> | </span>
                    <a onClick={() => {
                        setSelectedUser(user);
                        setShowSessionModal(true);
                    }}>دستگاه‌های فعال</a>
                </>
            )
        }
    ];

    useEffect(() => {
        setLoading(true);
        UserService.getUsers(params).then(page => {
            setData(page);
        }).finally(() => setLoading(false));
    }, [showCreateModal, params, showUpdateUserModal]);

    const fieldNameMap: Record<string, string> = {
        lastActivityEndDateTime: 'last_activity_end_date_time'
    };

    const onChange: TableProps<User>['onChange'] = (pagination, filters, sorter, extra) => {

        // sortField: sorter.field ? fieldNameMap[sorter.field] : null, // Map the field name
        //     sortOrder: sorter.order === 'ascend' ? 'asc' : sorter.order === 'descend' ? 'desc' : null,

        console.log('pagination', pagination)
        console.log('filters', filters)
        console.log('sorter', sorter)
        console.log('extra', extra)

        const { name } = filters;
        const sortKey = (sorter as SorterResult<any>)?.field as Key;

        let sort: string | null = null;
        if ((sorter as SorterResult<User>).order) {
            if ((sorter as SorterResult<User>).order === 'ascend') {
                sort = 'ASC';
            } else {
                sort = 'DESC';
            }
        }

        setParams({
            ...params,
            pageNumber: (pagination.current ?? 1) - 1,
            pageSize: pagination.pageSize ?? 10,
            sortKey: (sort && sortKey) ? fieldNameMap[(sortKey + "")] : "created_date",
            sort: sort ? sort : "DESC"
        });
    };

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>مدیریت کاربران</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Space style={{flexWrap: "wrap", marginTop: '20px'}}>
                <Button type="primary" onClick={() => setShowCreateModal(true)}>
                    <span style={{marginLeft: "5px"}}><FontAwesomeIcon icon={faPlus}/></span>
                    <span>افزودن کاربر جدید</span>
                </Button>

                <Select
                    placeholder="نقش کاربر"
                    allowClear
                    onChange={value => setParams(prevState => {
                        return {...prevState, role: value, pageNumber: 0}
                    })}
                    style={{width: "150px"}}
                >
                    <Option value={Role.ROLE_USER}>{RoleTitle[Role.ROLE_USER]}</Option>
                    <Option value={Role.ROLE_TEACHER}>{RoleTitle[Role.ROLE_TEACHER]}</Option>
                    <Option value={Role.ROLE_ADMIN}>{RoleTitle[Role.ROLE_ADMIN]}</Option>
                    <Option value={Role.ROLE_TEACHER_REPORT}>{RoleTitle[Role.ROLE_TEACHER_REPORT]}</Option>
                    <Option value={Role.ROLE_TEACHER_PAYMENT}>{RoleTitle[Role.ROLE_TEACHER_PAYMENT]}</Option>
                </Select>

                <div style={{width: "150px"}}>
                    <SearchFilterInput sendRequest={(inputValue: string) => {
                        setParams(prevState => {
                            return {...prevState, searchParam: inputValue, pageNumber: 0}
                        })
                    }}/>
                </div>
            </Space>

            <Table
                className="admin-course-table"
                loading={loading}
                onChange={onChange}
                pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: params.pageSize,
                    total: data?.totalElements
                }}
                style={{marginTop: "20px"}}
                columns={columns}
                dataSource={data?.content}
                size="middle"
                scroll={{x: 1000}}
            />
            <div>
                {
                    (() => {
                        return (showCreateModal ?
                            <UserCreate show={showCreateModal} setShow={setShowCreateModal}/> : null)
                    })()
                }
                {
                    (() => {
                        return (showEducationModal && selectedUser?.id ?
                            <EducationContainer show={showEducationModal} setShow={setShowEducationModal}
                                                userId={selectedUser?.id}/> : null)
                    })()
                }
                {
                    (() => {
                        return (showUpdateUserModal && selectedUser ?
                            <UserUpdate show={showUpdateUserModal} setShow={setShowUpdateUserModal}
                                        user={selectedUser}/> : null)
                    })()
                }
                {
                    (() => {
                        return (showSessionModal && selectedUser?.id ?
                            <SessionContainer show={showSessionModal} setShow={setShowSessionModal}
                                                userId={selectedUser.id}/> : null)
                    })()
                }
            </div>
        </Wrapper1>
    );
}