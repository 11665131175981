import React, {useEffect, useState} from "react";
import {List, Modal} from "antd";
import NotificationService, {Notification} from "../../services/NotificationService";
import {toPersianDate, toPersianDateTime} from "../../services/UtilFunctions";

export const NotificationContainer = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState<Notification[]>();

    useEffect(() => {
        NotificationService.getUnreadMessages().then(result => {
            setData(result)
            if (result && result.length > 0) setIsModalOpen(true);
            result.forEach(item => {
                NotificationService.seen(item.id)
            })
        })
    }, [])

    return (
        <Modal width={820} title="آخرین تغییرات" onCancel={e => setIsModalOpen(false)} open={isModalOpen} footer={false} >
            <List
                itemLayout="horizontal"
                dataSource={data}
                style={{maxHeight:"500px", overflowY:"scroll"}}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            title={<div>
                                <span>
                                    {item.title + " "}
                                </span>
                                <span style={{marginRight:"30px", fontSize:"12px"}}>
                                    {toPersianDate(item.createdDate)}
                                </span>
                            </div>}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
        </Modal>
    );

}