import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: number = 0;

export const selectedCategorySlice = createSlice({
    name: "selectedCategoryStateAction",
    initialState: initialState,
    reducers: {
        setSelectedCategory: (state, action: PayloadAction<number>) => {
            return action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {setSelectedCategory} = selectedCategorySlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default selectedCategorySlice.reducer;
