import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Modal, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {Page} from "../../../../services/UtilFunctions";
import CooperationFormService, {CooperationForm} from "../../../../services/CooperationFormService";
import {DegreeTitle} from "../../../../services/EducationService";
import {FieldOfStudyTitle} from "../../../../constans/enums";

const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

export const CooperationFormContainer: React.FC = () => {

    const [data, setData] = useState<Page<CooperationForm>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey:"createdDate",
        sort:"DESC"
    });
    const [show, setShow] = useState<boolean>(false);
    const [selectedDescription, setSelectedDescription] = useState<string>();

    useEffect(() => {
        loadData();
    }, [params]);

    const loadData = () => {
        CooperationFormService.getPage(params).then(page => {
            setData(page)
        });
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<CooperationForm> = [
        {
            title: 'نام',
            dataIndex: 'firstName',
            width: 100,
        },
        {
            title: 'نام خانوادگی',
            dataIndex: 'lastName',
            width: 100,
        },
        {
            title: 'شماره همراه',
            dataIndex: 'mobile',
            width: 150,
        },
        {
            title: 'ایمیل',
            dataIndex: 'email',
            width: 200,
        },
        {
            title: 'دانشگاه',
            dataIndex: 'university',
            width: 200
        },
        {
            title:'مدرک تحصیلی',
            dataIndex:'degree',
            width: 200,
            render: (_, { degree }) => (
                <>
                    {degree && DegreeTitle[degree]}
                </>
            ),
        },
        {
            title: 'رشته تحصیلی',
            dataIndex: 'fieldOfStudy',
            width: 150,
            render: (_, { fieldOfStudy }) => (
                <>
                    {fieldOfStudy && FieldOfStudyTitle[fieldOfStudy]}
                </>
            ),
        },
        {
            title: 'توضیحات',
            dataIndex: 'description',
            width: 100,
            render: (_, {description}) => (
                <a onClick={event => {
                   setSelectedDescription(description);
                   setShow(true);
                }}>مشاهده</a>
            ),
        },
        {
            title: 'رزومه',
            dataIndex: 'fileUrl',
            width: 100,
            render: (_, {fileUrl}) => (
                fileUrl ?
                <a href={baseNonVideoUrl + fileUrl}>دانلود</a> : null
            ),
        },
        {
            title: 'سابقه تدریس',
            dataIndex: 'teachingExperience',
            width: 150,
            render: (_, {teachingExperience}) => (
                teachingExperience ?
                    'دارد' : 'ندارد'
            ),
        }

    ];

    return (
        <Wrapper1 style={{margin: "15px", marginTop:"0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>تدریس در دانشگاهینو</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Table className="admin-course-table" scroll={{ x: 1500 }} pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: params.pageSize,
                total: data?.totalElements,
                onChange: handleSelectPagination
            }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            <Modal
                title="توضیحات"
                centered
                open={show}
                onOk={() => {}}
                onCancel={() => {
                    setShow(false);
                }}
                footer={[]}
                bodyStyle={{maxHeight:"700px", overflow: 'auto'}}
                width={720}
            >
                <p style={{textAlign:"justify", whiteSpace: 'pre-line'}}>{selectedDescription}</p>
            </Modal>
        </Wrapper1>
    );
}