import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ShoppingCartService from "../services/ShoppingCartService";

const initialState: number[] = ShoppingCartService.get();

export const cartSlice = createSlice({
    name: "cartStateAction",
    initialState: initialState,
    reducers: {
        setCart: (state, action: PayloadAction<number[]>) => {
            return  action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCart } = cartSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default cartSlice.reducer;
