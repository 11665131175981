import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {Category} from "./CategoryService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface WatchReport {
    id ?: string;
    yearMonth : string | null;
    year: number;
    month: number;
    courseId: number;
    courseTitle: string;
    teacherId: number;
    teacherFirstName: string;
    teacherLastName: string;
    courseParticipationPercentage: number;
    watchTime: number;
    courseStock: number;
    teacherStock: number;
    preViewCourseStock: number;
    preViewTeacherStock: number;
    paymentStatus: boolean;
    totalWatchTime: number;
    totalSell: number;
    coefficient:number;
}

class WatchReportService {

    private static instance: WatchReportService;

    private constructor() {
    }

    public static getInstance(): WatchReportService {
        if (!WatchReportService.instance) {
            WatchReportService.instance = new WatchReportService();
        }
        return WatchReportService.instance;
    }

    async getAdminWatchReport(teacherId : number | null, params: any): Promise<Page<WatchReport>> {
        return await genericApiCall<Page<WatchReport>>(async () => {
            const response = await axios.get<Page<WatchReport>>(baseUrl + "/api/v1/watch-report/teacher/" + teacherId + "?sortKey=year&sortKey=month&sortKey=courseId", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getTeacherWatchReport(params: any): Promise<Page<WatchReport>> {
        return await genericApiCall<Page<WatchReport>>(async () => {
            const response = await axios.get<Page<WatchReport>>(baseUrl + "/api/v1/watch-report/teacher?sortKey=year&sortKey=month&sortKey=courseId", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async updateCoefficient(id : string, coefficient:number | null): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/watch-report/update-coefficient/" + id, {coefficient}, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async updatePaymentStatus(id : string, status:boolean): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/watch-report/update-payment-status/" + id, {status}, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default WatchReportService.getInstance();