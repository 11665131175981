import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Divider, Modal, Space, Table} from "antd";
import type {ColumnsType} from 'antd/es/table';
import CategoryService, {Category} from "../../../../services/CategoryService";
import {CategoryCreate} from "./CategoryCreate";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CategoryUpdate} from "./CategoryUpdate";
import {ExclamationCircleOutlined} from "@ant-design/icons";

interface DataType extends Category{
    key: React.Key;
    parent ?: string;
}

export const CategoryContainer: React.FC =() => {

    const [data, setData] = useState<DataType[]>();
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [selectedCategory, setSelectedCategory] = useState<Category>();
    const [deleteModal, deleteModalContextHolder] = Modal.useModal();

    const columns: ColumnsType<DataType> = [
        {
            title: 'ردیف',
            dataIndex: 'key',
            width: 30,
        },
        {
            title: 'عنوان',
            dataIndex: 'title',
            width: 100,
        },
        // {
        //     title: 'پدر',
        //     dataIndex: 'parent',
        //     width: 10,
        // },
        {
            width: 50,
            align:'center',
            render: (_, category) => (
                <Space>
                    <a onClick={() => {
                        if (category.id) {
                            deleteModal.confirm({
                                title: 'آیا می خواهید این دسته بندی را حذف کنید؟',
                                icon: <ExclamationCircleOutlined/>,
                                okText: 'بله',
                                cancelText: 'انصراف',
                                okButtonProps: {danger: true},
                                onOk: () => {
                                    if (category.id){
                                        CategoryService.deleteCategory(category.id).then(value => {
                                            loadCategories();
                                        })
                                    }
                                }
                            })
                        }
                    }}>حذف</a>

                    <a onClick={() => {
                        setSelectedCategory(category);
                        setShowUpdateModal(true);
                    }}>ویرایش</a>
                </Space>
            )
        }
    ];

    useEffect(() => {
        loadCategories();
    }, [ showCreateModal, showUpdateModal ]);

    const loadCategories = () => {
        CategoryService.getCategoriesForAdmin().then(categories => {
            setData(
                categories.map((category, index) => {
                    return {
                        ...category,
                        key : index+1,
                        parent : '-'
                    } as DataType
                })
            )
        });
    }

    return(
        <Wrapper1 style={{margin:"15px", marginTop:"0px", padding:"20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>مدیریت دسته بندی</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Button style={{marginTop:"20px"}} type="primary" onClick={()=>setShowCreateModal(true)}>
                <span style={{marginLeft:"5px"}}><FontAwesomeIcon icon={faPlus}/></span>
                <span>افزودن دسته بندی جدید</span>
            </Button>
            <Table
                className="admin-course-table"
                style={{marginTop:"20px"}}
                columns={columns}
                dataSource={data}
                size="middle"
                pagination={false}
                scroll={{ x: 500 }}
            />
            <div>
                {
                    (() => {
                        return( showCreateModal ?  <CategoryCreate show={showCreateModal} setShow={setShowCreateModal}/> : null)
                    })()
                }
                {
                    (() => {
                        return( showUpdateModal ?  <CategoryUpdate show={showUpdateModal} setShow={setShowUpdateModal} data={selectedCategory}/> : null)
                    })()
                }
            </div>
            {deleteModalContextHolder}
        </Wrapper1>
    );

}