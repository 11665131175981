import React, {useEffect, useState} from "react";
import {Col, Divider, Pagination, Row} from "antd";
import {Wrapper1} from "../wrapper/Wrapper1";
import {CourseCard} from "../course/CourseCard";
import {Page} from "../../services/UtilFunctions";
import CourseService, {Course} from "../../services/CourseService";
import {useParams, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setSelectedCategory} from "../../redux/selectedCategorySlice";
import UserService, {User} from "../../services/UserService";
import {TeacherCard} from "./TeacherCard";
import {SECOND_COLOR} from "../../constans/colors";
import {LessonCreate} from "../dashboard/admin/course/LessonCreate";
import {CourseCreate} from "../dashboard/admin/course/CourseCreate";
import {CourseUpdate} from "../dashboard/admin/course/CourseUpdate";
import {TeacherResumeModal} from "./TeacherResumeModal";

export const TeachersContainer = () => {

    const [data, setData] = useState<Page<User>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 24,
    });
    const {id} = useParams();

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    useEffect(() => {
        UserService.getPublicTeachers(params).then(page => {
            setData(page);
        });
    }, [params])

    return (
        <div className="container">
            <Wrapper1 style={{marginTop: "55px", padding: "15px 15px"}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>تیم آموزشی دانشگاهینو</div>
                <Divider style={{margin: '0', marginTop: '15px'}}/>
                <Row gutter={[16, 16]} align="middle" justify="start"
                     style={{paddingBottom: "16px", paddingTop: "16px"}}>
                    {
                        data?.content.map(teacher => {
                            return (
                                <Col xl={8} md={12} sm={24} xs={24} style={{textAlign: 'center'}}>
                                    <TeacherCard teacher={teacher}/>
                                </Col>
                            );
                        })
                    }

                </Row>
                <Row gutter={[16, 16]} align="middle" justify="space-evenly">
                    <Pagination current={params.pageNumber + 1} pageSize={params.pageSize} total={data?.totalElements}
                                onChange={handleSelectPagination}/>
                </Row>
            </Wrapper1>
        </div>
    );

}