import React, {useEffect, useState} from "react";
import {Button, Form, Image, Input, InputNumber, Modal, Select, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import PlanService, {Plan} from "../../../../services/PlanService";
const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

interface Props {
    show: boolean
    setShow(b: boolean): void
    data: Plan | undefined
}

export const PlanUpdate = ({show, setShow, data}: Props) => {

    const [form] = Form.useForm();
    const [ file, setFile ] = useState<RcFile | null>();
    const [ submitLoading, setSubmitLoading ] = useState<boolean>(false);

    const onFinish = (values: any) => {
        values = {...values, cover:file}
        setSubmitLoading(true);
        PlanService.update(data?.id, values).then(response => {
            form.resetFields();
            setFile(null);
            setShow(false);
            setSubmitLoading(false);
        }).catch(e => setSubmitLoading(false));
    }

    useEffect(() => {
    }, [ ]);

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g,''))
    };

    return (
        <Modal
            title="ویرایش اشتراک"
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setFile(null);
                setShow(false);
            }}
            footer={[]}
            width={720}
        >
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="title"
                    initialValue={data?.title}
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="عنوان"/>
                </Form.Item>
                <Form.Item rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                    <Upload
                        accept=".jpeg, .jpg, .png"
                        maxCount={1}
                        beforeUpload={(file) => {
                            setFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined />}>انتخاب عکس کاور</Button>
                    </Upload>
                    <Image
                        style={{marginTop:'15px'}}
                        width={100}
                        src={baseNonVideoUrl + data?.coverUrl}
                    />
                </Form.Item>
                <Form.Item
                    name="description"
                    initialValue={data?.description}
                >
                    <Input placeholder="توضیحات"/>
                </Form.Item>
                <Form.Item
                    name="price"
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                    initialValue={data?.price}
                >
                    <Input name="price" onChange={justAllowNumbers} placeholder="قیمت به تومان"/>
                </Form.Item>
                <Form.Item
                    name="discountPercentage"
                    initialValue={data?.discountPercentage}
                >
                    <InputNumber style={{width:"100%"}} min={0} max={100} placeholder="درصد تخفیف" />
                </Form.Item>
                <Form.Item
                    name="days"
                    initialValue={data?.days}
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input name="days" onChange={justAllowNumbers} placeholder="تعداد روز"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={submitLoading}>
                        ویرایش
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}