import axios from "axios";
import {genericApiCall, Page, PageQueryParams} from "./UtilFunctions";
import TokenService from "./TokenService";
import qs from 'qs';
const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface DiscountCode {
    key ?: React.Key;
    id ?: number;
    title : string;
    code : string;
    planIds : number[];
    plans : string;
    userId: number;
    discountPercentage: number;
    days: number;
    maxUsedCount:number;

    endDate: Date;
    usedUsers:number[];
}

class DiscountCodeService {

    private static instance: DiscountCodeService;

    private constructor() {
    }

    public static getInstance(): DiscountCodeService {
        if (!DiscountCodeService.instance) {
            DiscountCodeService.instance = new DiscountCodeService();
        }
        return DiscountCodeService.instance;
    }

    async create(input:DiscountCode): Promise<DiscountCode> {
        return await genericApiCall<DiscountCode>(async () => {
            const response = await axios.post<DiscountCode>(baseUrl + "/api/v1/discount-codes", input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async createExcel(input:DiscountCode): Promise<any> {
        return await genericApiCall<any>(async () => {
            const response = await axios.post<any>(baseUrl + "/api/v1/discount-codes/excel", input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken(),
                },
                responseType: 'blob'
            });
            return response.data;
        });
    }

    async getPage(params : any): Promise<Page<DiscountCode>> {
        return await genericApiCall<Page<DiscountCode>>(async () => {
            const response = await axios.get<Page<DiscountCode>>(baseUrl + "/api/v1/discount-codes", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async delete(id:number | undefined): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/discount-codes/" + id, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async deleteBatch(ids:number[] | undefined): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.delete<void>(baseUrl + "/api/v1/discount-codes/batch", {
                params: {
                    ids: ids
                },
                paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                },
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async checkDiscountCode(code:string, planId:number | undefined): Promise<DiscountCode> {
        return await genericApiCall<DiscountCode>(async () => {
            const response = await axios.get<DiscountCode>(baseUrl + "/api/v1/discount-codes/codes/" + code + "/" + planId, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default DiscountCodeService.getInstance();