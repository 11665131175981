import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Modal, Row, Select, Table} from "antd";
import CourseService, {Chapter, Lesson} from "../../../../services/CourseService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faLock, faPlay} from "@fortawesome/free-solid-svg-icons";
import {ColumnsType} from "antd/es/table";
import {LessonUpdate} from "./LessonUpdate";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import ChapterService from "../../../../services/ChapterService";
import {ChapterUpdate} from "./ChapterUpdate";

const {Option} = Select;

interface Props {
    courseId?: number;
    showCreateModal:boolean;
}

interface ChapterModel extends Chapter{
    key: React.Key;
}

export const LessonView = ({courseId, showCreateModal}: Props) => {

    const [chapters, setChapters] = useState<ChapterModel[]>([]);
    const [showUpdateLessonModal, setShowUpdateLessonModal] = useState<boolean>(false);
    const [showUpdateChapterModal, setShowUpdateChapterModal] = useState<boolean>(false);
    const [selectedLesson, setSelectedLesson] = useState<Lesson>();
    const [selectedChapter, setSelectedChapter] = useState<Chapter>();
    const [selectedCourseId, setSelectedCourseId] = useState<number>();
    const [chapterDeleteModal, chapterDeleteModalContextHolder] = Modal.useModal();

    useEffect(() => {
        loadLessons();
    }, [showUpdateLessonModal, showCreateModal, showUpdateChapterModal]);

    const loadLessons = () => {
        if (courseId){
            CourseService.getLessonsForAdmin(courseId).then(data => {
                setChapters(data.map(record => {return {...record, key:record.id}}))
            });
        }

    }

    const columns: ColumnsType<ChapterModel> = [
        {
            title: 'عنوان',
            render: (record) => record.title,
            width:200
        },
        {
            dataIndex: 'id',
            width: 200,
            render: (_, chapter) => (
                <>
                    <a onClick={()=>{
                        setSelectedChapter(chapter);
                        setShowUpdateChapterModal(true);
                    }}>ویرایش فصل</a>
                    <span> | </span>
                    <a onClick={() => {
                        chapterDeleteModal.confirm({
                            title: 'آیا می خواهید این فصل را حذف کنید؟',
                            icon: <ExclamationCircleOutlined />,
                            okText: 'بله',
                            cancelText: 'انصراف',
                            okButtonProps:{danger:true},
                            onOk: () => {
                                ChapterService.delete(chapter.id).then(() => loadLessons());
                            }
                        })
                    }}>حذف فصل</a>
                </>
            )
        }
    ];

    const generateLessons = (record: Chapter) => {
        return (
            record.lessons.map(lesson => {
                return (
                    <Button style={{width: "100%", marginBottom: "15px", height:"100%"}} size="large" onClick={() => {
                        lesson = {...lesson, chapterId : record.id}
                        setSelectedLesson(lesson);
                        setSelectedCourseId(courseId);
                        setShowUpdateLessonModal(true);
                    }}>
                        <Row align="middle" justify="center">
                            <Col className="overflow-ellipsis" sm={18} xs={24} style={{textAlign: "right"}}>{lesson.title}</Col>
                            <Col sm={3} xs={12}>{lesson.duration + " دقیقه"}</Col>
                            <Col sm={3} xs={12}><FontAwesomeIcon icon={faPlay}/></Col>
                        </Row>
                    </Button>
                );
            })
        )
    }

    return (
            <div>
                <h3>فصل ها</h3>
                <Divider style={{margin: '0', marginTop: '15px', marginBottom: '15px'}}/>
                <div>
                    <Table
                        className="admin-course-table"
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record) => generateLessons(record),
                            rowExpandable: (record) => record.lessons.length !== 0,
                            fixed: "left",
                            expandIcon: ({expanded, onExpand, record}) =>
                                expanded ? (
                                    <span className="hover-cursor-pointer" onClick={e => onExpand(record, e)}><FontAwesomeIcon
                                        icon={faAngleUp}/></span>
                                ) : (
                                    <span className="hover-cursor-pointer" onClick={e => onExpand(record, e)}><FontAwesomeIcon
                                        icon={faAngleDown}/></span>
                                )
                        }}
                        dataSource={chapters}
                        pagination={false}
                        showHeader={false}
                        // scroll={{x:1200}}
                    />
                </div>
                {
                    (() => {
                        return( showUpdateLessonModal ?  <LessonUpdate show={showUpdateLessonModal} setShow={setShowUpdateLessonModal} lesson={selectedLesson} courseId={selectedCourseId}/> : null)
                    })()
                }
                {
                    (() => {
                        return( showUpdateChapterModal ?  <ChapterUpdate show={showUpdateChapterModal} setShow={setShowUpdateChapterModal} chapter={selectedChapter}/> : null)
                    })()
                }
                {chapterDeleteModalContextHolder}
            </div>
    );

}