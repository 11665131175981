import React from "react";
import {Button, Input} from 'antd';
import './searchInput.css'
import {FORTH_COLOR, THIRD_COLOR} from "../../../constans/colors";

const {Search} = Input;

interface Props {
    onSearch: (value : string) => void
}

export const SearchInput = ({onSearch}: Props) => {

    return (
        <div className="faq-search-input" style={{margin:"15px 0px" }}>
            <Search
                placeholder="موضوع سوال ..."
                allowClear
                enterButton={
                    <Button type="primary" style={{
                        backgroundColor: "#616161",
                        color: FORTH_COLOR,
                        height: '50px',
                        borderBottomLeftRadius: '10px',
                        borderTopLeftRadius: '10px'
                    }}>
                        جستجو
                    </Button>

                }
                size="large"
                onSearch={onSearch}
                bordered={false}
            />
        </div>
    );

}