import React, {useState} from "react";
import {Button, Col, Divider, Form, Input, Row} from 'antd';
import {SECOND_COLOR} from "../../../constans/colors";
import {Wrapper1} from "../../wrapper/Wrapper1";
import RegistrationService from "../../../services/RegistrationService";
import {useNavigate} from "react-router-dom";
import {LOGIN_ROUTE} from "../../../routes/route-path";
import Countdown, {CountdownApi} from "react-countdown";
import {openErrorNotification, toEnglishNumber} from "../../../services/UtilFunctions";

export const Register = () => {

    const [activeFinalizeForm, setActiveFinalizeForm] = useState<boolean>(false);
    const [mobile, setMobile] = useState<string>();
    const navigate = useNavigate();
    const [finalizeUseForm] = Form.useForm();
    const [countdownApi, setCountdownApi] = useState<CountdownApi | null>(null);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinishInitForm = async (values: any) => {
        if (values.password !== values.repeatPassword){
            openErrorNotification("رمز عبور و تکرار آن یکسان نیست.");
            return;
        }
        setLoading(true);
        RegistrationService.init(values).then(value => {
            setMobile(values.mobile);
            setActiveFinalizeForm(true);
            countdownApi && countdownApi.start();
            setLoading(false);
        }).catch(reason => {
            setLoading(false);
        })
    };

    const onFinishFinalizeForm = (values: any) => {
        values = {...values, mobile: mobile}
        setLoading(true);
        RegistrationService.finalize(values).then(value => {
            setLoading(false);
            navigate(LOGIN_ROUTE);
        }).catch(reason => {
            setLoading(false);
        })
    };

    const justAllowNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(e.target.name, e.target.value.replace(/\D/g,''))
    };

    const justAllowNumbersFinalize = (e: React.ChangeEvent<HTMLInputElement>) => {
        finalizeUseForm.setFieldValue(e.target.name, e.target.value.replace(/\D/g,''))
    };

    const justAllowEnglishNumbers = (e: React.ChangeEvent<HTMLInputElement>) => {
        let str = toEnglishNumber(e.target.value);
        form.setFieldValue(e.target.name, str.replace(/\D/g,''))
    };

    const initForm = () => {
      return(
          <Form
              form={form}
              onFinish={onFinishInitForm}
              hidden={activeFinalizeForm}
          >
              <Form.Item
                  name="firstName"
                  rules={[{required: true, message: 'لطفا نام خود را وارد کنید!'}]}
              >
                  <Input placeholder="نام"/>
              </Form.Item>
              <Form.Item
                  name="lastName"
                  rules={[{required: true, message: 'لطقا نام خانوادگی خود را وارد کنید!'}]}
              >
                  <Input placeholder="نام خانوادگی"/>
              </Form.Item>
              <Form.Item
                  name="mobile"
                  rules={[{required: true, message: 'لطفا شماره همراه خود را وارد کنید!'}]}
              >
                  <Input name="mobile" onChange={justAllowEnglishNumbers} placeholder="شماره همراه"/>
              </Form.Item>
              <Form.Item
                  name="password"
                  rules={[{required: true, message: 'لطفا رمز خود را وارد کنید!'}]}
              >
                  <Input.Password
                      type="password"
                      placeholder="رمز"
                  />
              </Form.Item>
              <Form.Item
                  name="repeatPassword"
                  rules={[{required: true, message: 'لطفا رمز جدید خود را  دوباره وارد کنید!'}]}
              >
                  <Input.Password
                      type="password"
                      placeholder="تکرار رمز"
                  />
              </Form.Item>
              <Form.Item>
                  <Button style={{width: "100%"}} type="primary" htmlType="submit" loading={loading}>
                      ثبت نام
                  </Button>
              </Form.Item>
          </Form>
      );
    }

    const renderer = (props:any) => {
        if (props.completed) {
            // Render a completed state
            return <a onClick={()=>{
                finalizeUseForm.resetFields();
                setActiveFinalizeForm(false);
            }}>ارسال مجدد کد</a>;
        } else {
            // Render a countdown
            return <span>{props.minutes}:{props.seconds}</span>;
        }
    };

    const setRef = (countdown: Countdown | null): void => {
        if (countdown) {
            setCountdownApi(countdown.getApi());
        }
    };

    const finalizeForm = () => {
        return(
            <Form
                // initialValues={{remember: true}}
                onFinish={onFinishFinalizeForm}
                form={finalizeUseForm}
                hidden={!activeFinalizeForm}
            >
                <Form.Item
                    name="verificationCode"
                    rules={[{required: true, message: 'لطفا کد فعالسازی را وارد کنید!'}]}
                >
                    <Input name="verificationCode" onChange={justAllowNumbersFinalize} placeholder="کد فعالسازی"/>
                </Form.Item>
                <Countdown
                    ref={setRef}
                    date={Date.now() + 120000}
                    renderer={renderer}
                />
                <Form.Item>
                    <Button style={{width: "100%"}} type="primary" htmlType="submit" loading={loading}>
                        ارسال
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    return (
        <div className="container">
            <Wrapper1 style={{marginTop: "55px", padding: "15px 15px"}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>ثبت نام در سایت</div>
                <Divider style={{margin: '0', marginTop: '15px'}}/>
                <Row justify="center" style={{padding: "15px"}}>
                    <Col xl={7} md={10} sm={18} xs={24}>
                        {
                            finalizeForm()
                        }
                        {
                            initForm()
                        }
                    </Col>
                </Row>
            </Wrapper1>

        </div>
    );

}