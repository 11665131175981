import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Col, Divider, Row, Select, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {numberWithCommas, Page} from "../../../../services/UtilFunctions";
import WatchReportService, {WatchReport} from "../../../../services/WatchReportService";
import UserService, {Role, User} from "../../../../services/UserService";
import {CustomNumberInput} from "../../../common/CustomNumberInput";
import Popconfirm from "antd/lib/popconfirm";
import {WatchChart} from "../../../common/WatchChart";
import {InLineReportCard} from "../../../common/InLineReportCard";
import TeacherPaymentService from "../../../../services/TeacherPaymentService";
import UserPlanService from "../../../../services/UserPlanService";

export const WatchReportContainer: React.FC = () => {

    const [data, setData] = useState<Page<WatchReport>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedYearMonth, setSelectedYearMonth] = useState<string | null>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sort:"DESC",
        teacherId:null
    });
    const [ teachers, setTeachers ] = useState<User[]>([]);
    const [ teacherStock, setTeacherStock ] = useState<number>(0);
    const [ totalStock, setTotalStock ] = useState<number>(0);
    const [ noPaidPlansPrice, setNoPaidPlansPrice ] = useState<number>(0);

    useEffect(() => {
        loadData();
        UserService.getUsers({
            pageNumber: 0,
            pageSize: 500,
            role:Role.ROLE_TEACHER
        }).then(page => {
            setTeachers(page.content);
        })
        if (params.teacherId){
            TeacherPaymentService.getTeacherStockForAdmin(params.teacherId).then(value => setTeacherStock(value));
        }
        TeacherPaymentService.getTotalTeacherStock().then(value => setTotalStock(value));
        UserPlanService.getNoPaidPlansPrice().then(value => setNoPaidPlansPrice(value));
    }, [params]);

    const loadData = () => {
        if (params.teacherId){
            setLoading(true);
            WatchReportService.getAdminWatchReport(params.teacherId, params).then(page => {
                if (page.content.length !== 0 && page.content[0].courseStock === null) setSelectedYearMonth(page.content[0].yearMonth)
                setData(page);
            }).finally(() => setLoading(false));
        }
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<WatchReport> = [
        {
            title: 'ماه',
            dataIndex: 'yearMonth',
            width: 100,
            onCell: (record, index) => ({
                rowSpan: index === 0 ? data?.content.filter(d => d.yearMonth === record.yearMonth).length : (index ? (data?.content[index - 1].yearMonth === record.yearMonth ? 0 : data?.content.filter(d => d.yearMonth === record.yearMonth).length) : 0),
            }),
        },
        {
            title: 'دوره',
            dataIndex: 'courseTitle',
            width: 200,
        },
        {
            title: 'درصد قرارداد دوره',
            dataIndex: 'courseParticipationPercentage',
            width: 150,
            render: (_,{courseParticipationPercentage}) => (
                <>
                    {courseParticipationPercentage || courseParticipationPercentage === 0 ? <span style={{fontSize:'10px'}}> % </span> : null}
                    {courseParticipationPercentage || courseParticipationPercentage === 0 ? courseParticipationPercentage : null}

                </>
            ),
        },
        {
            title: 'میزان مشاهده دوره',
            dataIndex: 'watchTime',
            width: 150,
            render: (_,{watchTime}) => (
                <>
                    {watchTime || watchTime === 0 ? watchTime : null}
                    {watchTime || watchTime === 0 ? <span style={{fontSize:'10px'}}> دقیقه</span> : null}

                </>
            ),
        },
        {
            title: 'فروش کل دوره‌',
            dataIndex: 'courseStock',
            width: 200,
            render: (_,{courseStock, preViewCourseStock}) => (
                courseStock ?
                    (<span>
                    {courseStock || courseStock === 0 ? numberWithCommas(courseStock) : null}
                    {courseStock || courseStock === 0 ? <span style={{fontSize:'10px'}}> تومان</span> : null}
                </span>) :
                    (<span style={{color:"#00008B"}}>
                    {preViewCourseStock || preViewCourseStock === 0 ? numberWithCommas(preViewCourseStock) : null}
                        {preViewCourseStock || preViewCourseStock === 0 ? <span style={{fontSize:'10px'}}> تومان</span> : null}
                </span>)
            ),
        },
        {
            title:'سهم مدرس',
            dataIndex:'teacherStock',
            width: 200,
            render: (_,{teacherStock, preViewTeacherStock}) => (
                (
                    teacherStock ?
                        <span>
                    {teacherStock || teacherStock === 0 ? numberWithCommas(teacherStock) : null}
                            {teacherStock || teacherStock === 0 ? <span style={{fontSize:'10px'}}> تومان</span> : null}
                </span> :
                        <span style={{color:"#00008B"}}>
                    {preViewTeacherStock || preViewTeacherStock === 0 ? numberWithCommas(preViewTeacherStock) : null}
                            {preViewTeacherStock || preViewTeacherStock === 0 ? <span style={{fontSize:'10px'}}> تومان</span> : null}
                </span>
                )
            ),
        },
        {
            title:'ضریب',
            dataIndex:'coefficient',
            width: 150,
            render: (_,record) => (
                <Popconfirm
                    title="تغییر ضریب"
                    description={
                        <CustomNumberInput defaultValue={record.coefficient} sendRequest={
                            (value:number) => {
                                setLoading(true);
                                record.id && WatchReportService.updateCoefficient(record.id, value).then(value1 => loadData()).finally(() => {setLoading(false)})
                            }
                        }/>
                    }
                    okButtonProps={{style:{display:'none'}}}
                    cancelButtonProps={{style:{display:'none'}}}
                >
                    {record.coefficient && "(x"}
                    {record.coefficient}
                    {record.coefficient && ")"}
                    <a> تغییر ضریب</a>
                </Popconfirm>
            ),
        },
        {
            title: 'وضعیت پرداخت',
            dataIndex: 'paymentStatus',
            width: 150,
            render: (_, record) => (
                <Popconfirm
                    title="تغییر وضعیت پرداخت"
                    onConfirm={e => record.id && WatchReportService.updatePaymentStatus(record.id, true).then(value1 => loadData()).finally(() => {setLoading(false)})}
                    onCancel={e => record.id && WatchReportService.updatePaymentStatus(record.id, false).then(value1 => loadData()).finally(() => {setLoading(false)})}
                    okText={<span style={{color:"green"}}>پرداخت شد</span>}
                    okType="link"
                    cancelButtonProps={{type:"link"}}
                    cancelText={<span style={{color:"red"}}>پرداخت نشده</span>}
                >
                    <a>
                        {record.paymentStatus ? <span style={{color:"green"}}>پرداخت شد</span> : <span style={{color:"red"}}>پرداخت نشده</span>}
                    </a>
                </Popconfirm>



            ),
        },
        {
            title: 'نمودار',
            width: 100,
            render: (_,record) => (
                <a
                   onClick={() => {
                       setSelectedYearMonth(record.yearMonth)
                       const element = document.getElementById('chart');
                       if (element) {
                           element.scrollIntoView({behavior: 'smooth'});
                       }
                   }}
                >
                    مشاهده
                </a>
            ),
            onCell: (record, index) => ({
                rowSpan: index === 0 ? data?.content.filter(d => d.yearMonth === record.yearMonth).length : (index ? (data?.content[index - 1].yearMonth === record.yearMonth ? 0 : data?.content.filter(d => d.yearMonth === record.yearMonth).length) : 0),
            }),
        },
        {
            title: 'میزان مشاهده کل',
            dataIndex: 'totalWatchTime',
            width: 150,
            render: (_,{totalWatchTime}) => (
                <>
                    {totalWatchTime || totalWatchTime === 0 ? totalWatchTime : null}
                    {totalWatchTime || totalWatchTime === 0 ? <span style={{fontSize:'10px'}}> دقیقه</span> : null}

                </>
            ),
            onCell: (record, index) => ({
                rowSpan: index === 0 ? data?.content.filter(d => d.totalWatchTime === record.totalWatchTime).length : (index ? (data?.content[index - 1].totalWatchTime === record.totalWatchTime ? 0 : data?.content.filter(d => d.totalWatchTime === record.totalWatchTime).length) : 0),
            }),
        },
        {
            title:'مبلغ فروش کل',
            dataIndex:'totalSell',
            width: 200,
            render: (_,{totalSell}) => (
                <>
                    {totalSell || totalSell === 0 ? numberWithCommas(totalSell) : null}
                    {totalSell || totalSell === 0 ? <span style={{fontSize:'10px'}}> تومان</span> : null}

                </>
            ),
            onCell: (record, index) => ({
                rowSpan: index === 0 ? data?.content.filter(d => d.totalSell === record.totalSell).length : (index ? (data?.content[index - 1].totalSell === record.totalSell ? 0 : data?.content.filter(d => d.totalSell === record.totalSell).length) : 0),
            })
        },

    ];

    const teacherFilterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').includes(input);

    return (
        <>
            <Row gutter={[16,16]} style={{margin:"15px"}} justify="start">
                <Col xs={24} sm={24} xl={6}>
                    <Select
                        size="large"
                        showSearch
                        filterOption={teacherFilterOption}
                        placeholder="مدرس دوره"
                        style={{width:'100%'}}
                        onChange={value => setParams(prevState => {return {...prevState, teacherId: value, pageNumber: 0}})}
                        options={
                            teachers.map(teacher => {
                                return {
                                    value:teacher.id + "",
                                    label:teacher.firstName + " " + teacher.lastName
                                }
                            })
                        }
                    />
                </Col>

                <Col xs={24} sm={24} xl={6}>
                    <InLineReportCard
                        style={{width:"100%"}}
                        title="موجودی حساب مدرس"
                        unit="تومان"
                        content={numberWithCommas(teacherStock) + ""}
                        backgroundColor="#4589FF"/>
                </Col>
                <Col xs={24} sm={24} xl={6}>
                    <InLineReportCard
                        style={{width:"100%"}}
                        title="موجودی حساب کل مدرسان"
                        unit="تومان"
                        content={numberWithCommas(totalStock) + ""}
                        backgroundColor="#009D9A"/>
                </Col>
                <Col xs={24} sm={24} xl={6}>
                    <InLineReportCard
                        style={{width:"100%"}}
                        title="اکانت های فعال"
                        unit="تومان"
                        content={numberWithCommas(noPaidPlansPrice) + ""}
                        backgroundColor="#6bee53"/>
                </Col>
            </Row>
            {
                params.teacherId && selectedYearMonth &&
                <Wrapper1 style={{margin: "15px", marginTop:"0px", padding: "20px"}}>
                    <div id="chart" style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>
                        <span style={{display:'inline-block', marginLeft:'5px'}}>نمودار</span>
                        <span style={{display:'inline-block'}}>({selectedYearMonth})</span>
                    </div>
                    <Divider style={{margin: '0', marginTop: '15px', marginBottom: '25px'}}/>
                    <WatchChart fetchData={async () => {
                        return (await WatchReportService.getAdminWatchReport(params.teacherId, {
                            pageNumber: 0,
                            pageSize: 50,
                            yearMonth: selectedYearMonth
                        })).content;
                    }
                    } teacherId={params.teacherId} yearMonth={selectedYearMonth}/>
                </Wrapper1>
            }

            {
                params.teacherId &&
                <Wrapper1 style={{margin: "15px", marginTop:"0px", padding: "20px"}}>
                    <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight:'bold'}}>جزئیات</div>
                    <Divider style={{margin: '0', marginTop: '15px'}}/>

                    <Table className="admin-course-table" scroll={{ x: 1900 }} loading={loading} pagination={{
                        current: data?.number ? data?.number + 1 : 1,
                        pageSize: params.pageSize,
                        total: data?.totalElements,
                        onChange: handleSelectPagination
                    }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
                </Wrapper1>

            }

        </>


    );
}