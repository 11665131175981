export const HOME_PAGE_ROUTE = '/';
export const NOT_FOUND_ROUTE = '/404/';
export const REGISTER_ROUTE = '/auth/register/';
export const LOGIN_ROUTE = '/auth/login/';
export const FORGET_PASSWORD_ROUTE = '/auth/forget-password/';
export const COURSE_ROUTE = '/course/:id';
export const SEARCH_ROUTE = '/search/';
export const ADMIN_DASHBOARD_ROUTE = '/admin/dashboard/';
export const PAYMENT_VERIFY_ROUTE = '/payment/verify/';
export const USER_PLANS_ROUTE = '/user/plans/';
export const TEACHER_DASHBOARD_ROUTE = '/teacher/dashboard/';
export const TEACHER_COURSES_ROUTE = '/teacher/courses/:id';
export const ABOUT_US_ROUTE = '/about-us/';
export const CONTACT_US_ROUTE = '/contact-us/';
export const TEACHERS_ROUTE = '/teachers/';
export const USER_DASHBOARD_ROUTE = '/user/dashboard/';
export const COOPERATION_FORM_ROUTE = '/cooperation-form/';
export const BUY_PLAN_ROUTE = '/payment/';
export const CATEGORY_COURSES_ROUTE = '/category-courses/:id';
export const FAQ_ROUTE = '/faq/';

