import React from "react";

export const Enamad = () => {

    const attr: any = {
        width: "100",
        height: "120",
        referrerPolicy: 'origin',
        src: 'https://trustseal.enamad.ir/logo.aspx?id=360087&Code=QP8xEkMJuuHnOPtVYfco',
        alt: '',
        style: {cursor: 'pointer'},
        Code: 'QP8xEkMJuuHnOPtVYfco'
    }

    return (
        <a referrerPolicy='origin' target='_blank'
           href='https://trustseal.enamad.ir/?id=360087&Code=QP8xEkMJuuHnOPtVYfco'>
            <img {...attr} />
        </a>
    );

}