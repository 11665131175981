import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {User} from "./UserService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface ContactUs {
    title: string;
    description: string;
    user: User;
}

class ContactUsService {

    private static instance: ContactUsService;

    private constructor() {}

    public static getInstance(): ContactUsService {
        if (!ContactUsService.instance) {
            ContactUsService.instance = new ContactUsService();
        }
        return ContactUsService.instance;
    }

    async getPage(params : any): Promise<Page<ContactUs>> {
        return await genericApiCall<Page<ContactUs>>(async () => {
            const response = await axios.get<Page<ContactUs>>(baseUrl + "/api/v1/contact-us", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async add(input:ContactUs): Promise<ContactUs> {
        return await genericApiCall<ContactUs>(async () => {
            const response = await axios.post<ContactUs>(baseUrl + "/api/v1/contact-us", input, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default ContactUsService.getInstance();