import {FIFTH_COLOR, FORTH_COLOR, SECOND_COLOR, SIXTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import React from "react";
import {Col, Divider, Row, Space} from "antd";
import {faLink, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {VerticalCategoryNavigation} from "../category-navigation/vertical";
import {MostUsedLinks} from "./MostUsedLinks";
import {Category} from "../../services/CategoryService";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/reduxStore";
import youtube from '../../assets/images/youtube-logo.png';
import aparat from '../../assets/images/aparat-logo.png';
import insta from '../../assets/images/insta-logo.png';
import {Enamad} from "../common/Enamad";

export const Footer: React.FC = () => {

    const categories: Category[] = useSelector((state: RootState) => state.categories);

    return (
        <div>
            <div className="container">
                <div className="dynamic-font-16"
                     style={{textAlign: 'left', padding: "30px 5px", fontSize: '16px', whiteSpace: 'nowrap'}}>
                    <span style={{backgroundColor: SIXTH_COLOR, borderRadius: '50px', padding: "10px 15px"}}>
                        <span style={{paddingLeft: "7px"}}>
                        <FontAwesomeIcon flip="horizontal" icon={faPhone}
                                         style={{color: SECOND_COLOR, fontSize: "inherit"}}/>
                    </span>
                    <span>
پاسخگوی شما هستیم | شماره تماس :
                    </span>
                    <span style={{color: SECOND_COLOR}}>
                        09037681604
                    </span>
                    </span>
                </div>
                <Divider style={{margin: '0'}}/>
                <Row gutter={[16, 16]} style={{padding: "30px 0px"}} justify="space-evenly">
                    <Col sm={6} xs={12}>
                        <div style={{color: "#090A15", fontSize: "17px", fontWeight: "bold", padding: "0px 20px"}}><span
                            style={{paddingLeft: "5px"}}></span>لینک های کاربردی
                        </div>
                        <MostUsedLinks/>
                    </Col>
                    <Col sm={6} xs={0}>
                        <div style={{color: "#090A15", fontSize: "17px", fontWeight: "bold", padding: "0px 20px"}}><span
                            style={{paddingLeft: "5px"}}></span>دسته بندی دوره ها
                        </div>
                        <VerticalCategoryNavigation categories={categories}/>
                    </Col>
                    <Col sm={6} xs={12}>
                        <div style={{color: "#090A15", fontSize: "17px", fontWeight: "bold", marginBottom: "12px"}}>
                            <span style={{paddingLeft: "5px"}}></span>در شبکه های اجتماعی
                        </div>
                        <div>
                            <Space size="middle">
                                <a href="https://www.youtube.com/@daneshgahino-uv1xw" target="_blank"><img
                                    style={{width: "30px"}} src={youtube} alt="youtube"/></a>
                                <a href="https://www.aparat.com/daneshgahino" target="_blank"><img
                                    style={{width: "22px"}} src={aparat} alt="aparat"/></a>
                                <a href="https://www.instagram.com/math_daneshgahino" target="_blank"><img
                                    style={{width: "22px"}} src={insta} alt="instagram"/></a>
                            </Space>
                            <div style={{marginTop: "20px"}}>
                                <Enamad/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div style={{color: "#828282", fontSize: '16px', backgroundColor: "#F8F6F6", padding: "12px 0px"}}>
                <div className="container" style={{display: "flex", justifyContent: "center"}}>
                    کلیه حقوق این سایت متعلق به - دانشگاهینو می‌باشد
                </div>
            </div>
        </div>
    );

}