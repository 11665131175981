import React from "react";
import {Footer} from "../../components/new-footer";
import {HeaderWrapper} from "../../components/header-wrapper";
import {AboutUs} from "../../components/about-us";
import {CustomBreadcrumb} from "../../components/common/CustomBreadcrumb";

export const AboutUsPage: React.FC =() => {

    return(
        <>
            <HeaderWrapper/>
            <AboutUs/>
            <Footer/>
        </>
    );
}