import React, {useState} from "react";
import {Button, Divider, Form, Input, Typography} from 'antd';
import {Wrapper1} from "../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../constans/colors";
import TextArea from "antd/es/input/TextArea";
import TokenService from "../../services/TokenService";
import {FAQ_ROUTE, LOGIN_ROUTE} from "../../routes/route-path";
import {useNavigate} from "react-router-dom";
import {openSuccessNotificationByMessage} from "../../services/UtilFunctions";
import ContactUsService from "../../services/ContactUsService";

const {Paragraph, Link} = Typography
export const ContactUs = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const generateSubmitButton = () => {
        if (TokenService.isLogin())
            return (
                <span>
                  <Button loading={loading} style={{width: "55px"}} type="primary" htmlType="submit">ثبت</Button>
              </span>
            )
        else
            return (
                <span>
                  <Button style={{width: "175px"}} type="primary" htmlType="submit"
                          onClick={() => navigate(LOGIN_ROUTE)}>برای ثبت وارد سایت شوید</Button>
              </span>
            )
    }

    return (
        <div className="container">
            <Wrapper1 style={{marginTop: "55px", padding: "15px 15px"}}>
                <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>تماس با ما</div>
                <Divider style={{margin: '15px 0px'}}/>

                <Paragraph>
                    جهت دستیابی سریع به سوالات خود، به
                    <Link href={FAQ_ROUTE}> صفحه سوالات متداول </Link>
                    مراجعه کنید. اگر پاسخ سوال خود را نیافتید، لطفاً با ما تماس بگیرید .
                </Paragraph>

                <Form
                    form={form}
                    name="basic"
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    style={{width: '100%'}}
                    initialValues={{remember: true}}
                    autoComplete="off"
                    onFinish={(values) => {
                        setLoading(true);
                        ContactUsService.add(values).then(value1 => {
                            openSuccessNotificationByMessage("درخواست شما ثبت شد.", 5);
                            form.resetFields();
                            setLoading(false);
                        }).catch(reason => setLoading(false));
                    }}
                >
                    <Form.Item name="title" rules={[{required: true, message: 'این فیلد الزامی است.'}, {
                        max: 50,
                        message: "حداکثر کاراکتر 50 می باشد."
                    }]}>
                        <Input placeholder="عنوان"/>
                    </Form.Item>

                    <Form.Item name="description" rules={[{required: true, message: 'این فیلد الزامی است.'}, {
                        max: 1500,
                        message: "حداکثر کاراکتر 1500 می باشد."
                    }]}><TextArea placeholder="متن" rows={10}/></Form.Item>
                    <Form.Item>
                        {generateSubmitButton()}
                    </Form.Item>
                </Form>

            </Wrapper1>

        </div>
    );

}