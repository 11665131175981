import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import PaymentService from "../../../services/PaymentService";
import {HOME_PAGE_ROUTE} from "../../../routes/route-path";
import ShoppingCartService from "../../../services/ShoppingCartService";
import {setCart} from "../../../redux/shoppingCartSlice";
import {useDispatch} from "react-redux";
import {Button, Result, Spin} from "antd";
const activePaymentGateway = process.env.REACT_APP_ACTIVE_PAYMENT_GATEWAY || "";

export const VerifyPage: React.FC =() => {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [refId, setRefId] = useState<string>();
    const [successVerify, setSuccessVerify] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (activePaymentGateway === "ZARIN_PAL"){
            doZarinPalVerification();
        }else if (activePaymentGateway === "NEXT_PAY"){
            doNextPayVerification();
        }
    },[searchParams])

    const doNextPayVerification = () => {
        const nextPayTransId = searchParams.get("trans_id");
        const nextPayStatus = searchParams.get("np_status");
        if (nextPayTransId && nextPayStatus && nextPayStatus === "OK"){
            PaymentService.paymentVerify(nextPayTransId).then(refId => {
                setRefId(refId);
                setSuccessVerify(true);
                setLoading(false);
                ShoppingCartService.removeAll();
                dispatch(setCart(ShoppingCartService.get()));
            }).catch(reason => {
                setSuccessVerify(false);
                setLoading(false);
            })
        }else {
            navigate(HOME_PAGE_ROUTE);
        }
    }

    const doZarinPalVerification = () => {
        const authority = searchParams.get("Authority");
        const status = searchParams.get("Status");
        if (authority && status && status === "OK"){
            PaymentService.paymentVerify(authority).then(refId => {
                setRefId(refId);
                setSuccessVerify(true);
                setLoading(false);
                ShoppingCartService.removeAll();
                dispatch(setCart(ShoppingCartService.get()));
            }).catch(reason => {
                setSuccessVerify(false);
                setLoading(false);
            })
        }else {
            navigate(HOME_PAGE_ROUTE);
        }
    }

    const onClickReturn = () => {
        ShoppingCartService.removeAll();
        dispatch(setCart(ShoppingCartService.get()));
        navigate(HOME_PAGE_ROUTE);
    }

    const handleContent = () => {
        if (loading){
            return (
                <Spin size="large">
                    <div className="content" />
                </Spin>
            );
        }else if (successVerify){
          return(
              <Result
                  status="success"
                  title="تراکنش شما با موفقیت انجام شد."
                  subTitle={<p><span>کد رهگیری: </span> <span>{refId}</span></p>}
                  extra={[
                      <Button type="primary" onClick={onClickReturn}>
                          بازگشت به سایت
                      </Button>,
                      // <Button>بازگشت به صورت خودکار</Button>,
                  ]}
              />
          )
      }else {
          return (
              <Result
                  status="500"
                  title="500"
                  subTitle="متاسفانه مشکلی پیش آمده است لطفا در صورت کسر مبلغ با پشتیبانی تماس حاصل فرمایید."
                  extra={<Button type="primary" onClick={()=>navigate(HOME_PAGE_ROUTE)}>بازگشت به سایت</Button>}
              />
          );
      }
    }

    return(
        <div style={{position:"fixed", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
            {
             handleContent()
            }
        </div>
    );
}