import React, {useState} from "react";
import {Button, Form, Image, Input, InputNumber, Modal, Select, Switch, Upload} from "antd";
import UserService, {Role, RoleTitle, User} from "../../../../services/UserService";
import TextArea from "antd/es/input/TextArea";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
import {openSuccessNotificationByMessage} from "../../../../services/UtilFunctions";
import Editor from "../../../common/Editor";
const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";
const { Option } = Select;
interface Props {
    show:boolean;
    setShow(b : boolean):void;
    user:User;
}

export const UserUpdate =({show, setShow, user} : Props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [ file, setFile ] = useState<RcFile | null>();

    const onFinish = (values: any) => {
        setLoading(true);
        values = {...values, photo:file}
        UserService.updateUser(user.id, values).then(response => {
            form.resetFields();
            setFile(null);
            setShow(false);
            setLoading(false);
            openSuccessNotificationByMessage("کاربر با موفقیت ویرایش شد.", 5);
        }).catch(reason => {
            setLoading(false);
        });
    }

    return(
        <Modal
            title="ویرایش کاربر"
            centered
            open={show}
            width={720}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setFile(null);
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 0 }}
                wrapperCol={{ span: 24 }}
                style={{ maxWidth:"100%" }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    label="نام کاربری"
                    name="username"
                    initialValue={user.username}
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="نام کاربری"/>
                </Form.Item>
                <Form.Item
                    label="رمز عبور"
                    name="password"
                >
                    <Input placeholder="رمز عبور"/>
                </Form.Item>
                <Form.Item
                    label="نام"
                    name="firstName"
                    initialValue={user.firstName}
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="نام"/>
                </Form.Item>
                <Form.Item
                    label="نام خانوادگی"
                    name="lastName"
                    initialValue={user.lastName}
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="نام خانوادگی"/>
                </Form.Item>
                <Form.Item
                    label="شماره همراه"
                    name="mobile"
                    initialValue={user.mobile}
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                >
                    <Input placeholder="شماره همراه"/>
                </Form.Item>

                <Form.Item label="نقش کاربر" name="roles" initialValue={user.roles} rules={[{ required: true, message: 'این فیلد الزامی است.'}]}>
                    <Select
                        placeholder="نقش کاربر"
                        mode="multiple"
                    >
                        <Option value={Role.ROLE_USER}>{RoleTitle[Role.ROLE_USER]}</Option>
                        <Option value={Role.ROLE_TEACHER}>{RoleTitle[Role.ROLE_TEACHER]}</Option>
                        <Option value={Role.ROLE_ADMIN}>{RoleTitle[Role.ROLE_ADMIN]}</Option>
                        <Option value={Role.ROLE_TEACHER_REPORT}>{RoleTitle[Role.ROLE_TEACHER_REPORT]}</Option>
                        <Option value={Role.ROLE_TEACHER_PAYMENT}>{RoleTitle[Role.ROLE_TEACHER_PAYMENT]}</Option>
                    </Select>
                </Form.Item>
                <Form.Item label="توضیحات" name="description">
                    <Editor onChange={(value : string)=>{
                        form.setFieldValue("description", value);
                    }} initialValue={user.description} style={{height:"400px", marginBottom:"70px"}}/>
                </Form.Item>
                <Form.Item label="پروفایل">
                    <Upload
                        accept=".jpeg, .jpg, .png"
                        listType="picture"
                        maxCount={1}
                        beforeUpload={(file) => {
                            setFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined />}>انتخاب عکس پروفایل</Button>
                    </Upload>
                </Form.Item>
                <Image
                    style={{marginTop:'15px', marginBottom:'30px'}}
                    width={100}
                    src={baseNonVideoUrl + user?.photoUrl}
                />
                <Form.Item name="locked" label="قفل کاربر" initialValue={user.locked}>
                    <Switch defaultChecked={user.locked} checkedChildren={true} unCheckedChildren={false}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        ویرایش
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}