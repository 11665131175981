import React, {useEffect, useState} from "react";
import './headerWrapper.css'
import {Header} from "../new-header";
import {MobileHeader} from "../mobile-header";
import UserService, {User} from "../../services/UserService";
import StorageService from "../../services/StorageService";
import {YaldaTopLine} from "../top-line/YaldaTopLine";

export const HeaderWrapper: React.FC =() => {

    const [profile, setProfile] = useState<User>();

    useEffect(() => {
        if (StorageService.getItem("token")){
            UserService.getSelfProfile().then(profile => {
                setProfile(profile);
            })
        }
    }, [])

    return(
        <div>
            <YaldaTopLine/>
            <div className="header-wrapper">
                <Header profile={profile}/>
            </div>
            <div className="mobile-header-wrapper">
                <MobileHeader profile={profile}/>
            </div>

        </div>
    );

}