import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Col, Divider, Modal, Row, Space, Table, Tag} from "antd";
import {ColumnsType} from "antd/es/table";
import {numberWithCommas, openErrorNotification, Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import {CardNumberEdit} from "./CardNumberEdit";
import {InLineReportCard} from "../../../common/InLineReportCard";
import TeacherPaymentService, {TeacherPayment} from "../../../../services/TeacherPaymentService";
import UserService, {User} from "../../../../services/UserService";
import {ExclamationCircleOutlined} from "@ant-design/icons";

export const TeacherPaymentContainer: React.FC = () => {

    const [data, setData] = useState<Page<TeacherPayment>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });

    const [teacherStock, setTeacherStock] = useState<number>(0);
    const [user, setUser] = useState<User>();
    const [requestModal, requestModalContextHolder] = Modal.useModal();


    useEffect(() => {
        TeacherPaymentService.getTeacherStockForTeacher().then(value => setTeacherStock(value));
        UserService.getSelfProfile().then(value => setUser(value));
        loadData();
    }, [params]);

    const loadData = () => {
        setLoading(true);
        TeacherPaymentService.getTeacherPaymentBySelf(params).then(page => {
            page.content = page.content.map(value => {
                value.key = value.id;
                return value;
            })
            setData(page);
        }).finally(() => setLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<TeacherPayment> = [
        {
            title: 'تاریخ درخواست',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{createdDate && toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'مبلغ',
            dataIndex: 'amount',
            width: 200,
            render: (_, {amount}) => (
                <>{numberWithCommas(amount)}</>
            ),
        },
        {
            title: 'وضعیت پرداخت',
            dataIndex: 'paymentStatus',
            width: 150,
            render: (_, record) => (
                <>{record.paymentStatus ? <span style={{color: "green"}}>پرداخت شد</span> :
                    <span style={{color: "red"}}>پرداخت نشده</span>}</>
            ),
        },
        {
            title: 'شماره کارت',
            dataIndex: 'cardNumber',
            width: 200,
        },
        {
            title: 'کد رهگیری',
            dataIndex: 'trackId',
            width: 300,
        }
    ];

    return (
        <>
            <div style={{marginRight: "15px", marginBottom: "15px"}}>
                <Space>
                    <CardNumberEdit cardNumber={user?.cardNumber} callBack={() => {
                        UserService.getSelfProfile().then(value => setUser(value));
                    }}/>
                </Space>
            </div>


            <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
                <div style={{
                    color: SECOND_COLOR,
                    fontSize: "18px",
                    fontWeight: 'bold'
                }}>تاریحچه درخواست دریافت پول
                </div>
                <div>
                    <Row gutter={[16,16]} style={{marginTop:'20px'}}>
                        <Col xs={24} sm={12}>
                            <Button style={{float: 'right'}} size="large" type="primary" onClick={event => {
                                if (!user?.cardNumber) {
                                    openErrorNotification("برای درخواست دریافت موجودی، شماره کارت خود را وارد نمایید.")
                                    return;
                                }
                                requestModal.confirm({
                                    title: `مبلغ شما به شماره کارت زیر واریز خواهد شد آن را تایید می نمایید؟`,
                                    content: <span style={{fontWeight: 'bold'}}>{user.cardNumber}</span>,
                                    icon: <ExclamationCircleOutlined/>,
                                    okText: 'تایید',
                                    cancelText: 'انصراف',
                                    onOk: () => {
                                        TeacherPaymentService.teacherRequest().then(value => {
                                            loadData();
                                        })
                                    }
                                })
                            }}
                                    disabled={!teacherStock || teacherStock === 0}
                            >
                                درخواست دریافت موجودی
                            </Button>
                        </Col>

                        <Col xs={24} sm={12}>
                            <InLineReportCard
                                style={{float: 'left'}}
                                title="موجودی حساب"
                                unit="تومان"
                                content={numberWithCommas(teacherStock) + ""}
                                backgroundColor="#4589FF"
                            />
                        </Col>
                    </Row>
                </div>

                <Divider style={{margin: '0'}}/>

                <Table scroll={{x: 1200}} loading={loading} pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: params.pageSize,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"
                       expandable={{
                           expandedRowRender: (record) => <div>
                               {record.watchReports && record.watchReports.map(item => <p>
                                   <Tag color='geekblue'>
                                       {item.yearMonth}
                                   </Tag>
                                   <Tag color='geekblue'>
                                       {item.courseTitle}
                                   </Tag>
                                   <Tag color='green'>
                                       {numberWithCommas(item.teacherStock) + " تومان"}
                                   </Tag>
                               </p>)}
                           </div>
                       }}
                />
            </Wrapper1>
            {requestModalContextHolder}
        </>


    );
}