import React, {useState} from "react";
import {Button, Form, Image, Input, Modal, Upload} from "antd";
import CategoryService, {Category} from "../../../../services/CategoryService";
import {UploadOutlined} from "@ant-design/icons";
import {RcFile} from "antd/es/upload";
const baseNonVideoUrl = process.env.REACT_APP_BASE_NON_VIDEO_URL || "";

interface Props {
    show:boolean
    setShow(b : boolean):void
    data:Category | undefined
}

export const CategoryUpdate =({show, setShow, data} : Props) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(false);
    const [ file, setFile ] = useState<RcFile | null>();

    const onFinish = (values: any) => {
        values = {...values, cover:file}
        if (data){
            setLoading(true);
            CategoryService.updateCategory(data.id, values).then(response => {
                form.resetFields();
                setShow(false);
                setLoading(false);
            }).catch(reason => {
                setLoading(false);
            });
        }
    }

    return(
        <Modal
            title="ویرایش دسته بندی"
            centered
            open={show}
            onOk={() => {}}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    name="title"
                    rules={[{ required: true, message: 'این فیلد الزامی است.' }]}
                    initialValue={data?.title}
                >
                    <Input placeholder="عنوان"/>
                </Form.Item>
                <Form.Item rules={[{required: true, message: 'این فیلد الزامی است.'}]}>
                    <Upload
                        accept=".jpeg, .jpg, .png"
                        maxCount={1}
                        beforeUpload={(file) => {
                            setFile(file);
                            return false;
                        }}
                        onRemove={(file) => {
                            setFile(null);
                        }}
                    >
                        <Button icon={<UploadOutlined />}>انتخاب عکس کاور</Button>
                    </Upload>
                    <Image
                        style={{marginTop:'15px'}}
                        width={100}
                        src={baseNonVideoUrl + data?.coverUrl}
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        ویرایش
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}