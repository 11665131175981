
class StorageService {

    private static instance: StorageService;

    private constructor() {}

    public static getInstance(): StorageService {
        if (!StorageService.instance) {
            StorageService.instance = new StorageService();
        }
        return StorageService.instance;
    }

    setItem(key : string, value : any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    getItem(key : string) {
        const value = localStorage.getItem(key);
        return value && JSON.parse(value);
    }

    removeItem(key : string) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }

}

export default StorageService.getInstance();