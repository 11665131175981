import React, {useEffect, useState} from "react";
import {Modal, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import UserService, {Session} from "../../../../services/UserService";
import {BrowserTypeTitle, OSTypeTitle} from "../../../../constans/enums";

interface Props {
    userId:number;
    show:boolean
    setShow(b : boolean):void
}

export const SessionContainer = ({show, setShow, userId} : Props) => {

    const [data, setData] = useState<Session[]>();
    const [loading, setLoading] = useState<boolean>(true);

    const columns: ColumnsType<Session> = [
        {
            title: 'آی پی',
            dataIndex: 'ip',
            ellipsis:true,
        },
        {
            title: 'سیستم عامل',
            dataIndex: 'os',
            ellipsis:true,
            render: (_, { os }) => (
                <>
                    {OSTypeTitle[os]}
                </>
            ),
        },
        {
            title: 'مرورگر',
            dataIndex: 'browserType',
            ellipsis:true,
            render: (_, { browserType }) => (
                <>
                    {BrowserTypeTitle[browserType]}
                </>
            ),
        },
        {
            title: 'وضعیت',
            dataIndex: 'online',
            ellipsis:true,
            render: (_, { online }) => (
                <>
                    {
                        online ?
                            <div>
                                <div className="online-status" style={{marginLeft: "10px"}}></div>
                                آنلاین</div> :
                            <div>
                                <div className="offline-status" style={{marginLeft: "10px"}}></div>
                                آفلاین</div>
                    }
                </>
            ),
        },
    ];

    useEffect(() => {
        loadData();
    }, [show]);

    const loadData = () => {
        UserService.getUserSessions(userId).then(result => {
            setData(result)
        }).finally(() => setLoading(false));
    }

    return (
    <Modal
        title="دستگاه‌های فعال"
        centered
        open={show}
        onOk={() => {}}
        onCancel={() => {
            setShow(false);
        }}
        footer={[]}
        bodyStyle={{maxHeight:"700px", overflow: 'auto'}}
        width={900}
    >
        <Table loading={loading} pagination={false} className="admin-course-table" style={{marginTop: "20px"}} columns={columns} dataSource={data} size="middle"/>
    </Modal>
    );
}